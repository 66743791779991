import React, { useRef, useEffect, useState } from "react";

import clsx from "clsx";
import Swal from "sweetalert2";

import { useStore } from "../../store";

import {
  forwardGeocodingByPlaceId,
  googleAutocomplete,
  humanizeAddress,
} from "../../api-client/google-maps";
import useOnClickOutside from "../hooks/useOnClickOutside";
import PinIcon from "../../Icons/PinIcon";
import { countryCode } from "../../utils/countryascode";

function AddressSearch({ addressSelection, callback, paramsClient }) {
  const [addressResults, setAddressResults] = useState([]);
  const [addressSearch, setAddressSearch] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [addressIsSelected, setAddressIsSelected] = useState(false);
  const client = paramsClient || useStore((state) => state.client);
  const clientCountryCode = countryCode(client);

  const containerRef = useRef();

  const getAddressInfo = async () => {
    const res = await googleAutocomplete(addressSearch, clientCountryCode);
    if (res) {
      if (res.length > 0) {
        setAddressResults(res);
        setShowResults(true);
      }
    } else {
      setAddressResults([]);
    }
  };

  useEffect(() => {
    if (!addressIsSelected) {
      getAddressInfo();
    }
  }, [addressSearch]);

  useEffect(() => {
    if (addressSelection) {
      setAddressSearch(humanizeAddress(addressSelection, true, true));
    }
  }, []);

  const handleAddressSelection = (address) => {
    try {
      callback(address);
      setAddressSearch(humanizeAddress(address, true, true));
      setShowResults(false);
      setAddressIsSelected(true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddressClick = async (address) => {
    try {
      const res = await forwardGeocodingByPlaceId(
        address.place_id,
        addressSearch,
        clientCountryCode
      );
      if (res) {
        if (Array.isArray(res)) {
          handleAddressSelection(res[0]);
        } else {
          handleAddressSelection(res);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Fuera de zona de cobertura",
          text: "Por el momento, todavía no llegamos ahí",
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  useOnClickOutside(containerRef, () => {
    setShowResults(false);
  });

  return (
    <div className="relative" ref={containerRef}>
      <PinIcon
        className={clsx("absolute top-4 left-2", "stroke-examedi-black-dark")}
      />
      <input
        type="text"
        placeholder="Ingresa tu calle y número"
        className={clsx(
          "border border-examedi-gray-line",
          "w-full",
          "py-[5px] px-7",
          "rounded-lg",
          "text-base",
          "h-12",
          "placeholder:text-sm",
          "focus:outline-examedi-blue-normal"
        )}
        value={addressSearch}
        onClick={() => {
          if (addressSearch.length > 0) {
            setShowResults(true);
          }
        }}
        onChange={(e) => {
          setAddressSearch(e.target.value);
          setAddressIsSelected(false);
        }}
      />
      {showResults && (
        <div
          className={clsx(
            "drop-shadow-md",
            "absolute top-[100%] left-0",
            "w-full",
            "max-h-[200px]",
            "overflow-y-auto",
            "bg-white",
            "z-50",
            "rounded-b-lg"
          )}
        >
          {addressResults.map((mr, index) => (
            <div
              key={mr.place_id}
              className={clsx("w-full")}
              onClick={() => {
                handleAddressClick(mr);
              }}
            >
              <div
                className={clsx(
                  "w-full",
                  "flex items-center justify-start",
                  "px-3",
                  "text-xs sm:text-sm",
                  "py-3",
                  "cursor-pointer",
                  "hover:bg-gray-100",
                  "rounded-lg"
                )}
              >
                {mr.description}
              </div>
              {index + 1 < addressResults.length && (
                <div className={clsx("w-full", "px-3", "flex justify-center")}>
                  <div className={clsx("w-full", "h-[1px]", "bg-gray-200")} />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default React.memo(AddressSearch);
