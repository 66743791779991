import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import MainLayout from "../Layout/MainLayout";
import Administration from "../Pages/Administration/Administration";
import Appointments from "../Pages/Appointments/Appointments";
import AppointmentIndividual from "../Pages/Appointments/Individual";
import Login from "../Pages/Login";
import Patients from "../Pages/Patients/Patients";
import Profile from "../Pages/Patients/Profile";
import Capacity from "../Pages/Capacity/Capacity";
import Results from "../Pages/Results/Results";
import { Clients, Users } from "../models";
import Main from "../Pages/Superpanel/Main";
import Client from "../Pages/Superpanel/Client";
import CentreProfile from "../Pages/Centre/Profile";
import PublicBooking from "../Pages/Appointments/PublicBooking";
import Payments from "../Pages/Payments";
import TokenWrapper from "../Components/Authentication/TokenWrapper";

const users = new Users();
users.sync();

const clients = new Clients();
clients.sync();

const withLayout = (element) => (
  <MainLayout users={users} clients={clients}>
    {element}
  </MainLayout>
);

function MainRouter() {
  return (
    <Router>
      <TokenWrapper>
        <Routes>
          <Route path="/" element={<Login users={users} clients={clients} />} />
          <Route
            path="/booking/date/:clientId/:appointmentId"
            element={<PublicBooking clients={clients} />}
          />
          <Route
            path="/app/admin"
            element={withLayout(
              <Administration clients={clients} users={users} />
            )}
          />
          <Route
            path="/app/payments"
            element={withLayout(<Payments clients={clients} users={users} />)}
          />
          <Route
            path="/app/results"
            element={withLayout(<Results clients={clients} users={users} />)}
          />
          <Route
            path="/app/patients"
            element={withLayout(<Patients clients={clients} users={users} />)}
          />
          <Route
            path="/app/patients/:id"
            element={withLayout(<Profile clients={clients} users={users} />)}
          />
          <Route
            path="/app/capacity"
            element={withLayout(<Capacity clients={clients} users={users} />)}
          />
          <Route
            path="/app/appointments"
            element={withLayout(
              <Appointments clients={clients} users={users} />
            )}
          />
          <Route
            path="/app/appointments/:id"
            element={withLayout(
              <AppointmentIndividual clients={clients} users={users} />
            )}
          />
          <Route
            path="/app/my-centre"
            element={withLayout(
              <CentreProfile clients={clients} users={users} />
            )}
          />
          <Route
            path="/app/clients"
            element={withLayout(<Main clients={clients} users={users} />)}
          />
          <Route
            path="/app/clients/:id"
            element={withLayout(<Client clients={clients} users={users} />)}
          />
        </Routes>
      </TokenWrapper>
    </Router>
  );
}

export default MainRouter;
