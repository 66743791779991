import React, { useState } from "react";

import { observer } from "mobx-react";
import { Toaster } from "react-hot-toast";
import { BsXLg } from "react-icons/bs";
import Swal from "sweetalert2";

import LoadingSpinnerIcon from "../../Icons/LoadingSpinner";
import { setHour } from "../../api-client/examedi/appointments";
import DisplayAvailability from "../../Components/Tools/DisplayAvailability";

const Reschedule = observer(({ close, client, appointment, services }) => {
  const [loading, setLoading] = useState(false);

  const [selectedBlock, setSelectedBlock] = useState({
    begin_date: "",
    end_date: "",
    rescheduling: true,
  });

  const setDate = async () => {
    setLoading(true);
    const setHourData = { ...selectedBlock, services_ids: services };
    const res = await setHour(appointment?.id, setHourData);
    if (res) {
      await Swal.fire({
        title: "Cita reagendada",
        text: "La cita ha sido reagendada",
        icon: "success",
        confirmButtonText: "OK",
        didClose: () => {
          close();
        },
      });
    } else {
      await Swal.fire({
        title: "Error",
        text: "No se pudo reagendar la cita",
        icon: "error",
        confirmButtonText: "OK",
        didClose: () => {
          close();
        },
      });
    }
    close();
    setLoading(false);
  };

  return (
    <div className="w-[400px]">
      <Toaster />
      <div className="flex items-center justify-between p-2">
        <div className="text-xl font-[500]">Elegir fecha y hora</div>

        <button className="hover:bg-gray-100" onClick={close} type="button">
          <BsXLg />
        </button>
      </div>
      <DisplayAvailability
        appointmentId={appointment?.id}
        callback={(beginDate, endDate) => {
          setSelectedBlock({
            ...selectedBlock,
            begin_date: beginDate,
            end_date: endDate,
          });
        }}
        client={client}
        setLoading={setLoading}
        servicesIds={services}
      />
      <div className="mt-10 flex items-center justify-end">
        {loading ? (
          <LoadingSpinnerIcon />
        ) : (
          <>
            <button
              className="rounded-full bg-gray-200 py-[10px] px-[20px] text-sm text-gray-500 drop-shadow-md hover:bg-gray-100"
              onClick={close}
              type="button"
            >
              Cancelar
            </button>
            <button
              className="ml-2 rounded-full bg-blue-600 py-[10px] px-[20px] text-sm text-white drop-shadow-md enabled:hover:bg-blue-500 disabled:opacity-50"
              onClick={setDate}
              disabled={!selectedBlock.begin_date || !selectedBlock.end_date}
              type="button"
            >
              Reagendar
            </button>
          </>
        )}
      </div>
    </div>
  );
});

export default Reschedule;
