import qs from "qs";
import { vanillaPersistedStore } from "../../vanillaStore";
import client from "../client";


export async function createAppointment(appointmentData) {
  try {
    const res = await client.post(
      "/appointments/create_sdk_appointment/",
      appointmentData
    );
    return res.data;
  } catch (err) {
    return err;
  }
}

export async function setHour(appointmentId, dateData) {
  try {
    const res = await client.post(
      `/appointments/${appointmentId}/set_date_v2/`,
      dateData
    );
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function manualConfirm(appointmentId, data) {
  try {
    const res = await client.post(
      `/appointments/${appointmentId}/manual_confirm/`,
      data
    );
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function cancelAppointment(appointmentId) {
  try {
    const token = vanillaPersistedStore.getState().getUserData().access_token;
    const res = await client.post(
      `/appointments/${appointmentId}/cancel/`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function receiveSamples(appointmentId) {
  try {
    const res = await client.post(
      `appointments/${appointmentId}/manual-received-by-lab/`
    );
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function patch(appointmentId, newData) {
  try {
    const res = await client.patch(`/appointments/${appointmentId}/`, newData);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function getAppointment(appointmentId) {
  try {
    const token = vanillaPersistedStore.getState().getUserData().access_token;
    const res = await client.get(`dashboard/appointments/${appointmentId}/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function getInvalidServices(cart) {
  try {
    const token = vanillaPersistedStore.getState().getUserData().access_token;
    const res = await client.post("appointments/validate_cart/", cart, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data.invalid_services;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function sendSlackAppointmentNotification(data) {
  try {
    const res = await client.post("/appointments/send-laas-appointment-notification/", data);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function getAppointmentsByClient(salesSource, dates = {}) {
  try {
    const res = await client.get(
      `dashboard/appointments/appointments-by-sales-source/?${qs.stringify(
        {
          sales_source: salesSource,
          begin_date: dates.beginDate,
          end_date: dates.endDate,
        },
        { skipNulls: true, addQueryPrefix: false, indices: false }
      )}`,
    );
    return res.data;
  } catch (err) {
    return err;
  }
}

