import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import moment from "moment";

import { login as firebaseLogin } from "../authentication";
import { login } from "../api-client/examedi/authentication";
import BaseCard from "../Components/BaseCard";
import Logo from "../Icons/Logo";
import { useStore } from "../store";
import { vanillaPersistedStore } from "../vanillaStore";

function Login({ users, clients }) {
  const [loading, setLoading] = useState(false);
  const rehydrated = useStore((state) => state.rehydrated);
  const user = useStore((state) => state.user);
  const navigate = useNavigate();
  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);

  const performLogin = async () => {
    setLoading(true);
    const { data, status } = await login(
      loginCredentials.email,
      loginCredentials.password,
      false
    );
    if (data && status === 200) {
      const firebaseToken = data.firebase_token;
      const expiresIn = data.expires_in;
      const expiration = moment().add(expiresIn, "seconds").valueOf();
      vanillaPersistedStore.getState().setUserData({ ...data, expiration });
      await firebaseLogin(firebaseToken, users, clients);
      navigate("/app/appointments");
    } else {
      setError("Credenciales incorrectas");
    }
    setLoading(false);
  };

  return (
    <div className="flex min-h-screen w-full items-center justify-center bg-gray-200">
      <BaseCard className="flex w-[400px] flex-col items-center pt-[40px] pb-[100px]">
        <Logo />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setError(null);
            performLogin();
          }}
        >
          <input
            type="email"
            className="mt-10 w-full rounded-sm border p-[5px] text-sm"
            placeholder="ejemplo@gmail.com"
            name="email"
            value={loginCredentials.email}
            autoComplete="email"
            onChange={(e) =>
              setLoginCredentials({
                ...loginCredentials,
                email: e.target.value,
              })
            }
            disabled={loading}
          />
          <input
            type="password"
            className="mt-2 w-full rounded-sm border p-[5px] text-sm"
            placeholder="Contraseña"
            name="password"
            autoComplete="current-password"
            onChange={(e) =>
              setLoginCredentials({
                ...loginCredentials,
                password: e.target.value,
              })
            }
            disabled={loading}
          />
          {error && <p className="mt-[10px] text-sm text-red-500">{error}</p>}
          <button
            disabled={loading}
            className="mt-[30px] flex items-center rounded-md bg-gray-100 px-[20px] py-[10px] hover:cursor-pointer hover:bg-gray-200 disabled:opacity-50"
            type="submit"
          >
            {loading ? "Cargando..." : "Iniciar sesión"}
          </button>
        </form>
      </BaseCard>
    </div>
  );
}

export default Login;
