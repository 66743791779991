import React, { useState } from "react";

import { observer } from "mobx-react";
import { Toaster, toast } from "react-hot-toast";
import { BsXLg } from "react-icons/bs";
import { validate } from "rut.js";
import clsx from "clsx";
import moment from "moment";

import LoadingSpinnerIcon from "../../Icons/LoadingSpinner";
import { createPatient as fetchCreatePatient } from "../../api-client/examedi/patients";
import { countryCode } from "../../utils/countryascode";
import { checkFilled, validateEmail } from "../../utils/inputs";

const NewPatient = observer(({ close, client, updateCreatedPatient }) => {
  const [loading, setLoading] = useState(false);
  const [newPatientData, setNewPatientData] = useState({
    name: "",
    second_name: " ",
    last_name: "",
    second_last_name: "",
    phone: "",
    email: "",
    gender: "male",
    birthdate: "",
    document_type: "ci",
    document_number: "",
  });

  const noErrors = {
    name: false,
    last_name: false,
    second_last_name: false,
    phone: false,
    document_number: false,
    email: false,
    gender: false,
    birthdate: false,
  };

  const noErrorMessages = {
    name: "",
    last_name: "",
    second_last_name: "",
    phone: "",
    document_number: "",
    email: "",
    gender: "",
    birthdate: "",
  };

  const [errors, setErrors] = useState(noErrors);
  // TODO: use errorMessages somewhere
  const [errorMessages, setErrorMessages] = useState(noErrorMessages);

  const validateFields = (code) => {
    const newErrors = { ...noErrors };
    const newErrorMessages = { ...noErrorMessages };
    const newPatientsDataAllCountries = { ...newPatientData };

    if (!checkFilled(newPatientData)) {
      Object.entries(newPatientData).forEach(([key, value]) => {
        if (!value) {
          newErrors[key] = true;
        }
      });
      if (code === "mx") {
        newErrors.document_number = false;
        newErrors.document_type = false;
        newPatientData.document_type = "generic_id";
        newPatientData.document_number = `${newPatientData.name}${
          newPatientData.last_name
        }${newPatientData.birthdate.replaceAll("-", "")}`;
      } else {
        toast.error("Debes llenar todos los datos", 3000);
      }
    }
    const phone = newPatientData.phone.trim();
    if (phone.length < 8 || /\D/gi.test(phone.slice(1))) {
      newErrors.phone = true;
      newErrorMessages.phone = "El número es inválido";
    }
    if (!validateEmail(newPatientData.email)) {
      newErrors.email = true;
      newErrorMessages.email = "El email es inválido";
    }
    if (
      code === "cl" &&
      newPatientData.document_type === "ci" &&
      !validate(newPatientData.document_number.trim())
    ) {
      newErrors.document_number = true;
      newErrorMessages.document_number = "El rut es inválido";
    }
    if (new Date() < new Date(newPatientData.birthdate)) {
      newErrors.birthdate = true;
      newErrorMessages.birthdate =
        "La fecha de nacimiento no puede ser superior a hoy";
    }
    setErrors(newErrors);
    setErrorMessages(newErrorMessages);
    setNewPatientData(newPatientsDataAllCountries);
    return Object.values(newErrors).every((value) => !value);
  };

  const createPatient = async () => {
    setLoading(true);
    const res = await fetchCreatePatient({
      first_name: newPatientData.name,
      second_name: newPatientData.second_name,
      last_name: newPatientData.last_name,
      second_last_name: newPatientData.second_last_name,
      email: newPatientData.email,
      phone: newPatientData.phone,
      country: countryCode(client),
      gender: newPatientData.gender,
      document_type: newPatientData.document_type,
      document_number: newPatientData.document_number.toUpperCase(),
      date_of_birth: moment(newPatientData.birthdate, "YYYY-MM-DD").format(),
    });
    await client?.patients?.create({
      ...newPatientData,
      document_number: newPatientData.document_number.toUpperCase(),
      examedi_patient_id: res.data.id,
    });
    if (updateCreatedPatient) {
      updateCreatedPatient(res.data);
    }
    setNewPatientData({
      name: "",
      second_name: " ",
      last_name: "",
      second_last_name: "",
      phone: "",
      email: "",
      gender: "male",
      birthdate: "",
      document_type: "",
      document_number: "",
    });
    toast.success("Paciente registrado");
    close();
    setLoading(false);
  };

  return (
    <div className="w-[400px]">
      <Toaster />
      <div className="flex items-center justify-between p-2">
        <div className="text-xl font-[500]">Registrar paciente nuevo</div>
        <button type="button" className="hover:bg-gray-100" onClick={close}>
          <BsXLg />
        </button>
      </div>
      <form
        className="mt-4"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {client?.get("country") === "Chile" && (
          <div className="flex flex-row gap-3">
            <div>
              <input
                id="ci"
                className="cursor-pointer accent-sdk-purple-normal"
                type="radio"
                value="ci"
                name="document_type"
                checked={newPatientData.document_type === "ci"}
                onChange={(e) =>
                  setNewPatientData({
                    ...newPatientData,
                    document_type: e.target.value?.trim(),
                  })
                }
              />{" "}
              <label htmlFor="ci" className="cursor-pointer">
                Rut
              </label>
            </div>
            <div>
              <input
                id="passport"
                className="cursor-pointer"
                type="radio"
                value="passport"
                name="document_type"
                checked={newPatientData.document_type === "passport"}
                onChange={(e) => {
                  setNewPatientData({
                    ...newPatientData,
                    document_type: e.target.value?.trim(),
                  });
                  // setErrors({ ...errors, document_type: false });
                }}
              />{" "}
              <label htmlFor="passport" className="cursor-pointer">
                Pasaporte
              </label>
            </div>
          </div>
        )}
        {[
          ...(client?.get("country") === "Chile"
            ? [
                {
                  placeholder:
                    newPatientData.document_type === "ci" ? "Rut" : "Pasaporte",
                  name: "document_number",
                },
              ]
            : []),
          { placeholder: "Nombre", name: "name" },
          { placeholder: "Segundo nombre (Opcional)", name: "second_name" },
          { placeholder: "Apellido", name: "last_name" },
          { placeholder: "Segundo apellido", name: "second_last_name" },
          { placeholder: "Número de celular", name: "phone" },
          { placeholder: "Correo", name: "email" },
        ].map((item, i) => (
          <input
            key={i}
            type="text"
            className={clsx(
              "mt-2 w-full rounded-sm border p-[5px] text-sm",
              errors[item.name] && ["border-pink-500"]
            )}
            placeholder={item.placeholder}
            name={item.name}
            onChange={(e) => {
              setNewPatientData({
                ...newPatientData,
                [e.target.name]: e.target.value?.trim(),
              });
            }}
          />
        ))}
        <select
          onChange={(e) => {
            setNewPatientData({
              ...newPatientData,
              [e.target.name]: e.target.value,
            });
          }}
          name="gender"
          className="mt-2 w-full cursor-pointer rounded-sm border p-[5px] text-sm"
        >
          <option value="male">Masculino</option>
          <option value="female">Femenino</option>
        </select>
        <div className="mt-[20px] text-xs font-bold">Fecha de Nacimiento</div>
        <input
          type="date"
          className="mt-2 w-full rounded-sm border p-[5px] text-sm"
          onChange={(e) => {
            setNewPatientData({
              ...newPatientData,
              [e.target.name]: e.target.value,
            });
          }}
          name="birthdate"
        />
        <div className="mt-10 flex items-center justify-end">
          {loading ? (
            <LoadingSpinnerIcon />
          ) : (
            <>
              <button
                type="button"
                className="rounded-full bg-gray-200 py-[10px] px-[20px] text-sm text-gray-500 drop-shadow-md hover:bg-gray-100"
                onClick={close}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="ml-2 rounded-full bg-blue-600 py-[10px] px-[20px] text-sm text-white drop-shadow-md hover:bg-blue-500"
                onClick={() => {
                  if (validateFields(countryCode(client))) {
                    createPatient();
                  }
                }}
              >
                Crear paciente
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  );
});

export default NewPatient;
