export const numberWithPeriods = (raw, countryCode) => {
  let separator = ".";
  if (raw === 0) {
    return "0";
  }
  if (!raw) {
    return "N/A";
  }
  if (countryCode === "mx") {
    separator = ",";
  }
  return Math.floor(raw)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export function isNumber(value) {
  const regex = /^[0-9]$/;
  const isValidNumber = regex.test(value);
  return isValidNumber;
}
