import React, { useState } from "react";

import { validateEmail } from "../../utils/inputs";

function NewUser({ close, newUserData, setNewUserData, callback, loading }) {
  const [errors, setErrors] = useState();
  return (
    <div className="w-[400px]">
      <div className="text-xl font-[500]">Agregar administrador</div>

      <form
        className="mt-4"
        onSubmit={(e) => {
          e.preventDefault();
          if (
            !newUserData.email ||
            !newUserData.name ||
            !newUserData.password
          ) {
            setErrors("Todos los campos son obligatorios");
          } else if (!validateEmail(newUserData.email)) {
            setErrors("El email no es válido");
          } else if (newUserData.password.length < 6) {
            setErrors("La contraseña debe tener al menos 6 caracteres");
          } else {
            callback();
            setNewUserData({ email: "", name: "", password: "" });
          }
        }}
      >
        <input
          type="text"
          className="mt-2 w-full rounded-sm border p-[5px] text-sm"
          placeholder="Nombre completo"
          name="name"
          onChange={(e) =>
            setNewUserData({ ...newUserData, name: e.target.value?.trim() })
          }
          disabled={loading}
        />
        <input
          type="text"
          className="mt-2 w-full rounded-sm border p-[5px] text-sm"
          placeholder="Correo electrónico"
          name="email"
          onChange={(e) =>
            setNewUserData({ ...newUserData, email: e.target.value?.trim() })
          }
          disabled={loading}
          autoComplete="username"
        />
        <input
          type="password"
          className="mt-2 w-full rounded-sm border p-[5px] text-sm"
          placeholder="Contraseña"
          name="password"
          onChange={(e) =>
            setNewUserData({ ...newUserData, password: e.target.value?.trim() })
          }
          disabled={loading}
          autoComplete="new-password"
        />
        {errors && <div className="mt-2 text-sm text-red-500">{errors}</div>}
        <div className="mt-10 flex items-center justify-end">
          <button
            className="rounded-full bg-gray-200 py-[10px] px-[20px] text-sm text-gray-500 drop-shadow-md hover:bg-gray-100 disabled:opacity-50"
            onClick={close}
            disabled={loading}
            type="button"
          >
            Cancelar
          </button>
          <button
            className="ml-2 rounded-full bg-blue-600 py-[10px] px-[20px] text-sm text-white drop-shadow-md hover:bg-blue-500 disabled:opacity-50"
            type="submit"
            disabled={loading}
          >
            Registrar usuario
          </button>
        </div>
      </form>
    </div>
  );
}

export default NewUser;
