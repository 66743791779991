import React, { useState, useEffect } from "react";

import clsx from "clsx";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Timestamp } from "firebase/firestore";

import {
  getAppointment,
  manualConfirm,
} from "../../api-client/examedi/appointments";
import BaseCard from "../../Components/BaseCard";
import LoadingSpinnerIcon from "../../Icons/LoadingSpinner";
import BackArrowIcon from "../../Icons/BackArrowIcon";
import IntroStep from "../../Components/sections/IntroStep";
import AddressStep from "../../Components/sections/AddressStep";
import DateStep from "../../Components/sections/DateStep";
import SuccessStep from "../../Components/sections/SuccessStep";

const PublicBooking = observer(({ clients }) => {
  // @example_url http://localhost:3000/booking/date/2vljGoUPTOEyTkSq7st6/lieoLbR0SBjAy6r7chLq
  const params = useParams();
  const [appointmentId, setAppointmentId] = useState(null); // UUID
  const { clientId } = params;
  const firestoreAppId = params.appointmentId;
  const [step, setStep] = useState("introduction"); // [introduction, address, date, confirmation, success, error]
  const [appointmentData, setAppointmentData] = useState({});
  const [previousStep, setPreviousStep] = useState(undefined);
  const [addressData, setAddressData] = useState(null);
  const client = clients.filterModels("id", clientId)[0];
  const [loading, setLoading] = useState({
    step: false,
    availability: false,
  });

  const payments = client?.payments;
  const appointments = client?.appointments;
  const appointment = appointments?.filterModels("id", firestoreAppId)[0];
  const patients = client?.patients;

  const fetchAppointmentData = async () => {
    if (appointment) {
      const res = await getAppointment(
        appointment?.get("examedi_appointment_id")
      );
      if (res) {
        setAppointmentData(res.data);
      }
    }
  };

  const confirmCreateAppointment = async () => {
    const res = await manualConfirm(
      appointment?.get("examedi_appointment_id"),
      {
        payment_method: "In Address",
        payment_gateway: "Kushki",
        payment_status: "Payment Pending",
      }
    );
    if (res) {
      setStep("success");
      appointment?.update({
        status: "scheduled",
      });
      payments?.create({
        created_at: Timestamp.fromDate(new Date()),
        amount: appointment?.get("amount_to_be_paid"),
        appointment_id: appointment?.id,
        paid: false,
        payor: "client",
      });
      // create payment here
      // setLoadingStep(false);
    }
  };

  useEffect(() => {
    setLoading((prev) => ({ ...prev, step: true }));
    if (appointment && Object.entries(appointmentData).length === 0) {
      fetchAppointmentData();
    }
    if (Object.entries(appointmentData).length > 0) {
      setLoading((prev) => ({ ...prev, step: false }));
    }
  }, [appointment, appointmentData]);

  useEffect(() => {
    if (appointment) {
      setAppointmentId(appointment?.get("examedi_appointment_id"));
    }
  }, [appointment]);

  return !clients?.models.length > 0 ||
    !patients.models.length > 0 ||
    loading.step ? (
    <div className="flex min-h-screen w-full items-center justify-center">
      <LoadingSpinnerIcon />
    </div>
  ) : (
    <div className="flex min-h-screen w-full items-center justify-center font-scandia text-gray-800 md:bg-gray-100">
      <Toaster />
      <BaseCard className="w-full max-w-[450px] rounded-xl border-none bg-white py-[50px] px-[30px]">
        {clients?.get(clientId) && (
          <img
            className="mx-auto w-[125px]"
            alt="clivi-examedi"
            src={clients?.get(clientId).get("logo_url")}
          />
        )}
        {step !== "introduction" && step !== "success" && (
          <BackArrowIcon
            className={clsx(
              "stroke-examedi-gray-3",
              "absolute top-8 left-4",
              "hover:cursor-pointer",
              "transition-transform duration-150 hover:scale-110"
            )}
            onClick={() => setStep(previousStep)}
          />
        )}

        <div className="mt-[40px]">
          {step === "introduction" && (
            <IntroStep setStep={setStep} appointmentData={appointmentData} />
          )}
          {step === "address" && (
            <AddressStep
              prevStep={setPreviousStep}
              nextStep={() => setStep("date")}
              addressData={addressData}
              setAddressData={setAddressData}
              appointment={appointment}
              client={client}
            />
          )}
          {step === "date" && appointmentId && (
            <DateStep
              appointment={appointment}
              appointmentData={appointmentData}
              prevStep={setPreviousStep}
              nextStep={() => setStep("success")}
              appointmentId={appointmentId}
              services={appointmentData?.services.map((item) => item)}
              confirmCreateAppointment={confirmCreateAppointment}
              paramsClient={client}
            />
          )}
          {step === "success" && (
            <SuccessStep
              appointmentData={appointmentData}
              appointment={appointment}
            />
          )}
        </div>
      </BaseCard>
    </div>
  );
});

export default PublicBooking;
