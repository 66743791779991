import React from "react";

import clsx from "clsx";

function IntroStep({ setStep, appointmentData }) {
  return (
    <div
      className={clsx(
        "w-full",
        "flex flex-col items-start justify-center",
        "gap-y-6",
        "text-examedi-black-dark"
      )}
    >
      <div className="text-3xl font-medium">Confirma tu cita</div>
      <div className="text-xl">
        En este formulario, te pediremos tu dirección y fecha preferida para tus
        exámenes a domicilio.
      </div>
      <div className="text-xl">Estás agendando estos exámenes:</div>
      <div className="flex flex-row flex-wrap gap-x-1 gap-y-1">
        {appointmentData &&
          appointmentData.services.map((item, index) => (
            <div
              key={index}
              className="max-w-[23rem] truncate rounded-md bg-examedi-gray-bg py-1 px-4"
            >
              {item.name}
            </div>
          ))}
      </div>
      <div className={clsx("flex w-full items-center justify-center", "mt-5")}>
        <button
          className={clsx(
            "rounded-full",
            "text-base font-medium text-white",
            "py-[10px] px-[20px]",
            "drop-shadow-examedi-button",
            "bg-examedi-blue-strong transition-colors duration-150 hover:bg-examedi-blue-strong-75",
            "w-60"
          )}
          onClick={() => setStep("address")}
          type="button"
        >
          Continuar
        </button>
      </div>
    </div>
  );
}

export default React.memo(IntroStep);
