export const reasonAndSymptomsCommunes = [
  "Viña del Mar",
  "Coquimbo",
  "La Serena",
  "Valparaíso",
  "Concón",
];

export const supportedRegions = {
  // Mexico
  "Ciudad de México": [-99.136, 19.419],
  "Estado de México": [-99.7233, 19.4969],
  // Chile
  "Región de Antofagasta": [-70.385, -23.621],
  "Región Metropolitana de Santiago": [-70.65, -33.49],
  "Región de Coquimbo": [-71.3, -29.942],
  "Región de Valparaíso": [-71.589, -33.038],
  "Región del Bío Bío": [-73.1, -36.811],
};

export const supportedCommunesPerRegion = {
  // Mexico
  "Ciudad de México": [
    {
      name: "Álvaro Obregón",
      center: [-99.226707, 19.360533],
      postal_codes: [1000, 1990],
    },
    {
      name: "Azcapotzalco",
      center: [-99.18594, 19.48698],
      postal_codes: [2000, 2990],
    },
    {
      name: "Benito Juárez",
      center: [-99.15766, 19.3984],
      postal_codes: [3000, 3940],
    },
    {
      name: "Coyoacán",
      center: [-99.16174, 19.3467],
      postal_codes: [4000, 4990],
    },
    {
      name: "Cuajimalpa",
      center: [-99.29388, 19.35993],
      postal_codes: [5000, 5990],
    },
    {
      name: "Cuauhtémoc",
      center: [-99.14612, 19.44506],
      postal_codes: [6000, 6995],
    },
    {
      name: "Gustavo A. Madero",
      center: [-99.1236, 19.4873],
      postal_codes: [7000, 7990],
    },
    {
      name: "Iztacalco",
      center: [-99.09778, 19.39528],
      postal_codes: [8000, 8990],
    },
    {
      name: "Iztapalapa",
      center: [-99.0671, 19.35738],
      postal_codes: [9000, 9990],
    },
    {
      name: "La Magdalena Contreras",
      center: [-99.21118, 19.33212],
      postal_codes: [10000, 10990],
    },
    {
      name: "Miguel Hidalgo",
      center: [-99.20091, 19.43471],
      postal_codes: [11000, 11990],
    },
    {
      name: "Milpa Alta",
      center: [-99.02317, 19.19251],
      postal_codes: [12000, 12990],
    },
    {
      name: "Tláhuac",
      center: [-99.00317, 19.28348],
      postal_codes: [13000, 13990],
    },
    {
      name: "Tlalpan",
      center: [-99.16787, 19.29707],
      postal_codes: [14000, 14990],
    },
    {
      name: "Venustiano Carranza",
      center: [-99.09724, 19.44286],
      postal_codes: [15000, 15990],
    },
    {
      name: "Xochimilco",
      center: [-99.103, 19.263],
      postal_codes: [16000, 16990],
    },
    // ...
  ],
  "Estado de México": [
    {
      name: "Huixquilucan",
      center: [-99.2730146, 19.4086418],
      postal_codes: [52760, 52799],
    },
    {
      name: "Naucalpan de Juárez",
      center: [-99.23963, 19.47851],
      postal_codes: [53000, 53970],
    },
    {
      name: "Atizapán",
      center: [-99.265313, 19.567999],
      postal_codes: [52900, 52998],
    },
    {
      name: "Tlalnepantla de Baz",
      center: [-99.19538, 19.54005],
      postal_codes: [54000, 54198],
    },
  ],
  // Chile
  "Región de Antofagasta": [
    { name: "Antofagasta", center: [-70.385, -23.621] },
  ],
  "Región Metropolitana de Santiago": [
    { name: "Cerrillos", center: [-70.73, -33.502] },
    { name: "Cerro Navia", center: [-70.76, -33.42] },
    { name: "Colina", center: [-70.685, -33.293] },
    { name: "Conchalí", center: [-70.696, -33.384] },
    { name: "El Bosque", center: [-70.694, -33.562] },
    { name: "El Monte", center: [-70.996, -33.678] },
    { name: "Estación Central", center: [-70.72, -33.462] },
    { name: "Huechuraba", center: [-70.682, -33.369] },
    { name: "Independencia", center: [-70.674, -33.415] },
    { name: "Isla de Maipo", center: [-70.926, -33.742] },
    { name: "La Cisterna", center: [-70.682, -33.529] },
    { name: "La Florida", center: [-70.595, -33.534] },
    { name: "La Granja", center: [-70.64, -33.536] },
    { name: "Lampa", center: [-70.885, -33.286] },
    { name: "La Pintana", center: [-70.673, -33.591] },
    { name: "La Reina", center: [-70.565, -33.447] },
    { name: "Las Condes", center: [-70.554, -33.423] },
    { name: "Lo Barnechea", center: [-70.535, -33.342] },
    { name: "Lo Espejo", center: [-70.706, -33.52] },
    { name: "Lo Prado", center: [-70.731, -33.447] },
    { name: "Macul", center: [-70.617, -33.489] },
    { name: "Maipú", center: [-70.861, -33.515] },
    { name: "Melipilla", center: [-71.221, -33.689] },
    { name: "Ñuñoa", center: [-70.618, -33.454] },
    { name: "Pedro Aguirre Cerda", center: [-70.692, -33.493] },
    { name: "Peñaflor", center: [-70.893, -33.607] },
    { name: "Peñalolén", center: [-70.548, -33.485] },
    { name: "Puente Alto", center: [-70.596, -33.595] },
    { name: "Providencia", center: [-70.626, -33.426] },
    { name: "Pudahuel", center: [-70.914, -33.418] },
    { name: "Quilicura", center: [-70.775, -33.354] },
    { name: "Quinta Normal", center: [-70.714, -33.428] },
    { name: "Recoleta", center: [-70.651, -33.406] },
    { name: "Renca", center: [-70.744, -33.403] },
    { name: "San Bernardo", center: [-70.798, -33.64] },
    { name: "San Joaquín", center: [-70.644, -33.494] },
    { name: "San Miguel", center: [-70.644, -33.497] },
    { name: "San Ramón", center: [-70.66, -33.537] },
    { name: "Santiago", center: [-70.676, -33.452] },
    { name: "Talagante", center: [-70.944, -33.662] },
    { name: "Vitacura", center: [-70.581, -33.383] },
  ],
  "Región de Coquimbo": [
    { name: "Coquimbo", center: [-71.342, -29.958] },
    { name: "La Serena", center: [-71.279, -29.906] },
  ],
  "Región de Valparaíso": [
    { name: "Concón", center: [-71.539, -32.933] },
    { name: "Valparaíso", center: [-71.628, -33.05] },
    { name: "Viña del Mar", center: [-71.565, -33.005] },
    { name: "Quilpué", center: [-71.46, -33.051] },
    { name: "Villa Alemana", center: [-71.363, -33.066] },
    { name: "Quillota", center: [-71.252, -32.882] },
    { name: "Limache", center: [-71.263, -33.007] },
  ],
  "Región del Bío Bío": [
    { name: "Chiguayante", center: [-73.057, -36.925] },
    { name: "Concepción", center: [-73.068, -36.826] },
    { name: "Coronel", center: [-73.192, -36.989] },
    { name: "Hualpén", center: [-73.122, -36.79] },
    { name: "Penco", center: [-73.011, -36.737] },
    { name: "San Pedro de la Paz", center: [-73.153, -36.87] },
    { name: "Talcahuano", center: [-73.142, -36.73] },
    { name: "Tomé", center: [-72.955, -36.613] },
  ],
};

export const unsupportedCommunesPerRegion = {
  "Ciudad de México": [
    // ...
  ],
  "Estado de México": [
    // ...
  ],
  "Región de Antofagasta": [
    // ...
  ],
  "Región Metropolitana de Santiago": [
    { name: "Alhué", center: [-71.111, -34.026] },
    { name: "Buin", center: [-70.763, -33.729] },
    { name: "Calera de Tango", center: [-70.779, -33.63] },
    { name: "Curacaví", center: [-71.151, -33.396] },
    { name: "María Pinto", center: [-71.124, -33.515] },
    { name: "Padre Hurtado", center: [-70.836, -33.572] },
    { name: "Paine", center: [-70.74, -33.81] },
    { name: "Pirque", center: [-70.665, -33.725] },
    { name: "San José de Maipo", center: [-70.358, -33.642] },
    { name: "San Pedro", center: [-71.467, -33.893] },
    { name: "Tiltil", center: [-70.94, -33.09] },
  ],
  "Región de Coquimbo": [
    { name: "La Higuera", center: [-71.204, -29.61] },
    { name: "Vicuña", center: [-70.722, -30.031] },
  ],
  "Región de Valparaíso": [
    { name: "Quintero", center: [-71.551, -32.786] },
    { name: "Puchuncaví", center: [-71.419, -32.725] },
    { name: "Casablanca", center: [-71.424, -33.318] },
    { name: "Juan Fernández", center: [-78.52, -33.37] },
    { name: "Zapallar", center: [-71.465, -32.553] },
  ],
  "Región del Bío Bío": [
    { name: "Florida", center: [-72.667, -36.822] },
    { name: "Hualqui", center: [-72.945, -36.973] },
    { name: "Lota", center: [-73.172, -37.093] },
    { name: "Santa Juana", center: [-72.955, -37.174] },
  ],
};

export const places = {
  México: {
    "Ciudad de México": [
      "Álvaro Obregón",
      "Azcapotzalco",
      "Benito Juárez",
      "Coyoacán",
      "Cuajimalpa",
      "Cuauhtémoc",
      "Gustavo A. Madero",
      "Iztacalco",
      "Iztapalapa",
      "La Magdalena Contreras",
      "Miguel Hidalgo",
      "Milpa Alta",
      "Tláhuac",
      "Tlalpan",
      "Venustiano Carranza",
      "Xochimilco",
      // ...
    ],
    "Estado de México": [
      "Huixquilucan",
      "Naucalpan de Juárez",
      "Atizapán",
      "Tlalnepantla de Baz",
    ],
    // ...
  },
  Chile: {
    "Región Metropolitana de Santiago": [
      "Alhué",
      "Buin",
      "Calera de Tango",
      "Cerrillos",
      "Cerro Navia",
      "Colina",
      "Conchalí",
      "Curacaví",
      "El Bosque",
      "El Monte",
      "Estación Central",
      "Huechuraba",
      "Independencia",
      "Isla de Maipo",
      "La Cisterna",
      "La Florida",
      "La Granja",
      "La Pintana",
      "La Reina",
      "Lampa",
      "Las Condes",
      "Lo Barnechea",
      "Lo Espejo",
      "Lo Prado",
      "Macul",
      "Maipú",
      "María Pinto",
      "Melipilla",
      "Ñuñoa",
      "Padre Hurtado",
      "Paine",
      "Pedro Aguirre Cerda",
      "Peñaflor",
      "Peñalolén",
      "Pirque",
      "Providencia",
      "Pudahuel",
      "Puente Alto",
      "Quilicura",
      "Quinta Normal",
      "Recoleta",
      "Renca",
      "San Bernardo",
      "San Joaquín",
      "San José de Maipo",
      "San Miguel",
      "San Pedro",
      "San Ramón",
      "Santiago",
      "Talagante",
      "Tiltil",
      "Vitacura",
    ],
    "Región de Arica y Parinacota": [
      "Arica",
      "Camarones",
      "General Lagos",
      "Putre",
    ],
    "Región de Tarapacá": [
      "Alto Hospicio",
      "Camiña",
      "Colchane",
      "Huara",
      "Iquique",
      "Pica",
      "Pozo Almonte",
    ],
    "Región de Antofagasta": [
      "Antofagasta",
      "Calama",
      "María Elena",
      "Mejillones",
      "Ollagüe",
      "San Pedro de Atacama",
      "Sierra Gorda",
      "Taltal",
      "Tocopilla",
    ],
    "Región de Atacama": [
      "Alto del Carmen",
      "Caldera",
      "Chañaral",
      "Copiapó",
      "Diego de Almagro",
      "Freirina",
      "Huasco",
      "Tierra Amarilla",
      "Vallenar",
    ],
    "Región de Coquimbo": [
      "Andacollo",
      "Canela",
      "Combarbalá",
      "Coquimbo",
      "Illapel",
      "La Higuera",
      "La Serena",
      "Los Vilos",
      "Monte Patria",
      "Ovalle",
      "Paiguano",
      "Punitaqui",
      "Río Hurtado",
      "Salamanca",
      "Vicuña",
    ],
    "Región de Valparaíso": [
      "Algarrobo",
      "Cabildo",
      "Calera",
      "Calle Larga",
      "Cartagena",
      "Casablanca",
      "Catemu",
      "Concón",
      "El Quisco",
      "El Tabo",
      "Hijuelas",
      "Isla de Pascua",
      "Juan Fernández",
      "La Cruz",
      "La Ligua",
      "Limache",
      "Llaillay",
      "Los Andes",
      "Nogales",
      "Olmué",
      "Panquehue",
      "Papudo",
      "Petorca",
      "Puchuncaví",
      "Putaendo",
      "Quillota",
      "Quilpué",
      "Quintero",
      "Rinconada",
      "San Antonio",
      "San Esteban",
      "San Felipe",
      "Santa María",
      "Santo Domingo",
      "Valparaíso",
      "Villa Alemana",
      "Viña del Mar",
    ],
    "Región del Libertador General Bernardo O'Higgins": [
      "Chimbarongo",
      "Chépica",
      "Codegua",
      "Coinco",
      "Coltauco",
      "Doñihue",
      "Graneros",
      "La Estrella",
      "Las Cabras",
      "Litueche",
      "Lolol",
      "Machalí",
      "Malloa",
      "Marchihue",
      "Mostazal",
      "Nancagua",
      "Navidad",
      "Olivar",
      "Palmilla",
      "Paredones",
      "Peralillo",
      "Peumo",
      "Pichidegua",
      "Pichilemu",
      "Placilla",
      "Pumanque",
      "Quinta de Tilcoco",
      "Rancagua",
      "Rengo",
      "Requínoa",
      "San Fernando",
      "San Vicente",
      "Santa Cruz",
    ],
    "Región del Maule": [
      "Cauquenes",
      "Chanco",
      "Colbún",
      "Constitución",
      "Curepto",
      "Curicó",
      "Empedrado",
      "Hualañé",
      "Licantén",
      "Linares",
      "Longaví",
      "Maule",
      "Molina",
      "Parral",
      "Pelarco",
      "Pelluhue",
      "Pencahue",
      "Rauco",
      "Retiro",
      "Romeral",
      "Río Claro",
      "Sagrada Familia",
      "San Clemente",
      "San Javier",
      "San Rafael",
      "Talca",
      "Teno",
      "Vichuquén",
      "Villa Alegre",
      "Yerbas Buenas",
    ],
    "Región del Bío Bío": [
      "Alto Biobío",
      "Antuco",
      "Arauco",
      "Bulnes",
      "Cabrero",
      "Cañete",
      "Chiguayante",
      "Chillán",
      "Chillán Viejo",
      "Cobquecura",
      "Coelemu",
      "Coihueco",
      "Concepción",
      "Contulmo",
      "Coronel",
      "Curanilahue",
      "El Carmen",
      "Florida",
      "Hualpén",
      "Hualqui",
      "Laja",
      "Lebu",
      "Los Álamos",
      "Los Ángeles",
      "Lota",
      "Mulchén",
      "Nacimiento",
      "Negrete",
      "Ninhue",
      "Pemuco",
      "Penco",
      "Pinto",
      "Portezuelo",
      "Quilaco",
      "Quilleco",
      "Quillón",
      "Quirihue",
      "Ránquil",
      "San Carlos",
      "San Fabián",
      "San Ignacio",
      "San Nicolás",
      "San Pedro de la Paz",
      "San Rosendo",
      "Santa Bárbara",
      "Santa Juana",
      "Talcahuano",
      "Tirúa",
      "Tomé",
      "Treguaco",
      "Tucapel",
      "Yumbel",
      "Yungay",
      "Ñiquén",
    ],
    "Región de la Araucanía": [
      "Angol",
      "Carahue",
      "Cholchol",
      "Collipulli",
      "Cunco",
      "Curacautín",
      "Curarrehue",
      "Ercilla",
      "Freire",
      "Galvarino",
      "Gorbea",
      "Lautaro",
      "Loncoche",
      "Lonquimay",
      "Los Sauces",
      "Lumaco",
      "Melipeuco",
      "Nueva Imperial",
      "Padre las Casas",
      "Perquenco",
      "Pitrufquén",
      "Pucón",
      "Purén",
      "Renaico",
      "Saavedra",
      "Temuco",
      "Teodoro Schmidt",
      "Toltén",
      "Traiguén",
      "Victoria",
      "Vilcún",
      "Villarrica",
    ],
    "Región de los Ríos": [
      "Corral",
      "Futrono",
      "La Unión",
      "Lago Ranco",
      "Lanco",
      "Los Lagos",
      "Mariquina",
      "Máfil",
      "Paillaco",
      "Panguipulli",
      "Río Bueno",
      "Valdivia",
    ],
    "Región de los Lagos": [
      "Ancud",
      "Calbuco",
      "Castro",
      "Chaitén",
      "Chonchi",
      "Cochamó",
      "Curaco de Vélez",
      "Dalcahue",
      "Fresia",
      "Frutillar",
      "Futaleufú",
      "Hualaihué",
      "Llanquihue",
      "Los Muermos",
      "Maullín",
      "Osorno",
      "Palena",
      "Puerto Montt",
      "Puerto Octay",
      "Puerto Varas",
      "Puqueldón",
      "Purranque",
      "Puyehue",
      "Queilén",
      "Quellón",
      "Quemchi",
      "Quinchao",
      "Río Negro",
      "San Juan de la Costa",
      "San Pablo",
    ],
    "Región de Aysén del General Carlos Ibáñez del Campo": [
      "Aisén",
      "Chile Chico",
      "Cisnes",
      "Cochrane",
      "Coihaique",
      "Guaitecas",
      "Lago Verde",
      "O'Higgins",
      "Río Ibáñez",
      "Tortel",
    ],
    "Región de Magallanes y de la Antártica Chilena": [
      "Antártica",
      "Cabo de Hornos",
      "Laguna Blanca",
      "Natales",
      "Porvenir",
      "Primavera",
      "Punta Arenas",
      "Río Verde",
      "San Gregorio",
      "Timaukel",
      "Torres del Paine",
    ],
  },
};
