import React, { useState, useEffect } from "react";

import clsx from "clsx";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Modal from "react-modal";

import BaseCard from "../../Components/BaseCard";
import Header from "../../Components/Shared/Header";
import Td from "../../Components/Table/Td";
import Th from "../../Components/Table/Th";
import Tr from "../../Components/Table/Tr";
import LoadingSpinnerIcon from "../../Icons/LoadingSpinner";
import { useStore } from "../../store";
import {
  cancelAppointment,
  receiveSamples,
  getAppointment,
} from "../../api-client/examedi/appointments";
import { getResultsByAppointment } from "../../api-client/examedi/results";
import EditPatient from "../Patients/EditPatient";
import Reschedule from "./Reschedule";
import { capitalizeFirstLetter, humanizeDate } from "../../utils/inputs";
import { getAge, formatBirthday } from "../../utils/patients-age";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function AppointmentIndividual() {
  const params = useParams();
  const [copied, setCopied] = useState({ examedi: false, lastMile: false });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [modalPatient, setModalPatient] = useState(null);
  const [results, setResults] = useState(null);
  const client = useStore((state) => state.client);
  const patients = client?.patients;
  const appointmentId = params.id;
  const [appointmentData, setAppointmentData] = useState(null);
  const [loadingAppointment, setLoadingAppointment] = useState(false);

  const handleCopyAddId = (source) => {
    if (source === "examedi" && appointmentId) {
      navigator.clipboard.writeText(appointmentId);
    } else if (source === "lastMile" && appointmentData?.folio) {
      navigator.clipboard.writeText(appointmentData?.folio);
    }
    setCopied({ ...copied, [source]: true });
  };

  const fetchAppointmentData = async () => {
    setLoadingAppointment(true);
    if (appointmentId) {
      const res = await getAppointment(appointmentId);
      setAppointmentData(res.data);
      const patientId = res.data.patients[0].id;
      const results = await getResultsByAppointment(appointmentId, patientId);
      setResults(results.data);
    }
    setLoadingAppointment(false);
  };

  const cancel = async () => {
    try {
      Swal.fire({
        title: "¿Cancelar cita?",
        text: "¿Estás seguro de que quieres cancelar la cita?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, cancelar",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.value) {
          const res = await cancelAppointment(appointmentId);
          if (res?.status === 200) {
            await Swal.fire({
              title: "Cita cancelada",
              text: "Cita cancelada con éxito",
              icon: "success",
              confirmButtonText: "OK",
            });
            await fetchAppointmentData();
          } else {
            await Swal.fire({
              title: "Error",
              text: "No se pudo cancelar la cita",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        }
      });
    } catch (e) {
      await Swal.fire({
        title: "Error",
        text: "Error cancelando la cita",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const markReceived = async () => {
    try {
      Swal.fire({
        title: "¿Marcar cita como recibida?",
        text: "¿Estás seguro de que quieres marcar la cita como recibida?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, marcar",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.value) {
          const res = await receiveSamples(appointmentId);
          if (res?.status === 200) {
            await Swal.fire({
              title: "Cita marcada como recibida",
              text: "Cita marcada como recibida con éxito",
              icon: "success",
              confirmButtonText: "OK",
            });
          } else {
            await Swal.fire({
              title: "Error",
              text: `No se pudo marcar la cita como recibida: "${res.response.data.error}"`,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        }
      });
    } catch (e) {
      await Swal.fire({
        title: "Error",
        text: "Error marcando la cita como recibida",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  useEffect(() => {
    fetchAppointmentData();
  }, [appointmentId]);

  return !appointmentId ? (
    <LoadingSpinnerIcon />
  ) : (
    <>
      <Modal isOpen={isEditModalOpen && !!modalPatient} style={customStyles}>
        <EditPatient
          client={client}
          close={() => {
            setIsEditModalOpen(false);
          }}
          patient={modalPatient}
        />
      </Modal>
      <Modal isOpen={isRescheduleModalOpen} style={customStyles}>
        <Reschedule
          client={client}
          close={() => setIsRescheduleModalOpen(false)}
          appointment={appointmentData}
          services={appointmentData?.services?.map((service) => service.id)}
        />
      </Modal>
      <BaseCard className="w-full">
        <div className="flex w-full items-center justify-between">
          <div>
            <Header>
              Cita en&nbsp;
              <span className="font-bold">
                {appointmentData && appointmentData?.target_address}
              </span>
              <span className="ml-2">
                {appointmentData?.displayable_begin_date &&
                  `el ${appointmentData?.displayable_begin_date}`}
              </span>
            </Header>
            <span className="text-sm">
              {appointmentData?.displayable_begin_date &&
                `Fecha: ${appointmentData?.displayable_begin_date}`}
            </span>
            <span className="text-sm">
              {!appointmentData?.displayable_begin_date &&
                "El paciente todavía no define la fecha de la cita"}
            </span>
            <div className="mt-[10px] text-sm">
              <span className="text-xs font-bold">Examedi ID</span>&nbsp;
              <span>{appointmentData?.id}</span>
              <button
                type="button"
                className={clsx(
                  "ml-4",
                  "border border-blue-500",
                  "py-1 px-2",
                  "rounded-md"
                )}
                onClick={() => handleCopyAddId("examedi")}
              >
                {copied.examedi ? "Copiado!" : "Copiar"}
              </button>
            </div>
            {client?.get("is_last_mile") && (
              <div className="mt-[10px] text-sm">
                <span className="text-xs font-bold">Folio última milla</span>
                &nbsp;
                {/* <span>{appointment?.get("folio")}</span> */}
                <button
                  type="button"
                  className={clsx(
                    "ml-4",
                    "border border-blue-500",
                    "py-1 px-2",
                    "rounded-md"
                  )}
                  onClick={() => handleCopyAddId("lastMile")}
                >
                  {copied.lastMile ? "Copiado!" : "Copiar"}
                </button>
              </div>
            )}
            <div className="mt-[10px] text-sm">
              <button
                type="button"
                className={clsx(
                  "ml-4",
                  "border border-red-500",
                  "py-1 px-2",
                  "rounded-md",
                  "disabled:opacity-50"
                )}
                onClick={cancel}
                disabled={["cancelled", "received-by-lab"].includes(
                  appointmentData?.status || ""
                )}
              >
                Cancelar
              </button>
              {!loadingAppointment && (
                <button
                  type="button"
                  className={clsx(
                    "ml-4",
                    "border border-green-500",
                    "py-1 px-2",
                    "rounded-md",
                    "disabled:opacity-50"
                  )}
                  onClick={() => setIsRescheduleModalOpen(true)}
                  disabled={["cancelled", "received-by-lab"].includes(
                    appointmentData?.status || ""
                  )}
                >
                  Reagendar
                </button>
              )}
              {client?.get("is_last_mile") && (
                <button
                  type="button"
                  className={clsx(
                    "ml-4",
                    "border border-green-500",
                    "py-1 px-2",
                    "rounded-md",
                    "disabled:opacity-50"
                  )}
                  onClick={markReceived}
                  disabled={["cancelled", "received-by-lab"].includes(
                    appointmentData?.status || ""
                  )}
                >
                  Marcar Recepción
                </button>
              )}
            </div>
          </div>
          <div>
            {appointmentData?.status === "patient_info_pending" && (
              <>
                <a
                  href={`${window.location.origin}/booking/date/${client?.id}/${appointmentData?.id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="mr-[10px] text-blue-500 underline"
                >
                  Link de Agendamiento paciente
                </a>
                <div className="inline rounded-sm bg-orange-500 px-[5px] py-[2px] text-xs text-white">
                  Enviado a Paciente
                </div>
              </>
            )}
            {appointmentData?.status === "scheduled" && (
              <div className="inline rounded-sm bg-blue-500 px-[5px] py-[2px] text-xs text-white">
                Agendado
              </div>
            )}
            {appointmentData?.status === "cancelled" && (
              <div className="inline rounded-sm bg-red-500 px-[5px] py-[2px] text-xs text-white">
                Cancelado
              </div>
            )}
            {appointmentData?.status === "visited" && (
              <div className="inline rounded-sm bg-green-500 px-[5px] py-[2px] text-xs text-white">
                Visitado
              </div>
            )}
            {appointmentData?.status === "received-by-lab" && (
              <div className="inline rounded-sm bg-purple-500 px-[5px] py-[2px] text-xs text-white">
                Recibido por laboratorio
              </div>
            )}
          </div>
        </div>
      </BaseCard>
      <div className="mt-[20px] grid grid-cols-3 gap-4">
        <BaseCard className="col-span-1">
          <div className="flex justify-between">
            <div className="text-lg">Datos de paciente</div>
            <button
              type="button"
              className={clsx(
                "ml-4",
                "border border-blue-500",
                "py-1 px-2",
                "rounded-md"
              )}
              onClick={() => {
                setModalPatient(patients?.get(appointmentData?.patients[0].id));
                setIsEditModalOpen(true);
              }}
            >
              Editar
            </button>
          </div>
          <div className="mt-[10px] text-sm">
            <span className="text-xs font-bold">Nombres</span>&nbsp;
            {appointmentData?.patients[0]?.first_name}{" "}
            {appointmentData?.patients[0]?.second_name}
          </div>
          <div className="mt-[10px] text-sm">
            <span className="text-xs font-bold">Apellidos</span>&nbsp;
            {appointmentData?.patients[0]?.last_name}{" "}
            {appointmentData?.patients[0]?.second_last_name}
          </div>
          <div className="mt-[10px] text-sm">
            <span className="text-xs font-bold">
              {appointmentData?.patients[0]?.document_type !== "generic_id"
                ? "Rut / Pasaporte"
                : "ID"}
            </span>
            &nbsp;
            {appointmentData?.patients[0]?.document_number}
          </div>
          <div className="mt-[10px] text-sm">
            <span className="text-xs font-bold">Nro. Celular</span>&nbsp;
            {appointmentData?.patients[0]?.phone}
          </div>
          <div className="mt-[10px] text-sm">
            <span className="text-xs font-bold">Correo</span>&nbsp;
            {appointmentData?.patients[0]?.email}
          </div>
          <div className="mt-[10px] text-sm">
            <span className="text-xs font-bold">Sexo</span>&nbsp;
            {appointmentData?.patients[0]?.gender === "male"
              ? "Masculino"
              : "Femenino"}
          </div>
          {appointmentData?.patients[0]?.date_of_birth && (
            <>
              <div className="mt-[10px] text-sm">
                <span className="text-xs font-bold">Fecha de Nacimiento</span>
                &nbsp;
                {formatBirthday(appointmentData?.patients[0]?.date_of_birth)}
              </div>
              <div className="mt-[10px] text-sm">
                <span className="text-xs font-bold">Edad</span>&nbsp;
                {`${getAge(appointmentData?.patients[0]?.date_of_birth)} años`}
              </div>
            </>
          )}
        </BaseCard>
        <BaseCard className="col-span-2">
          <div className="text-lg">Resultados</div>
          <table className="mt-[20px] w-full">
            <thead>
              <Tr>
                <Th>ID</Th>
                <Th>Acciones</Th>
              </Tr>
            </thead>
            <tbody className="divide-y">
              {results?.map((element, i) => (
                <Tr key={i} className="cursor-pointer hover:bg-gray-100">
                  <Td>{element.id}</Td>
                  <Td>
                    <a
                      href={element.file_link}
                      className="text-blue-700 hover:underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Descargar
                    </a>
                  </Td>
                </Tr>
              ))}
              <Tr />
            </tbody>
          </table>
        </BaseCard>
        <BaseCard>
          <div className="text-lg">Servicios</div>
          <div className="mt-[20px] text-sm">
            {appointmentData?.service_names}
          </div>
        </BaseCard>
        {!appointmentData && <LoadingSpinnerIcon />}
        {appointmentData?.nurse && (
          <BaseCard>
            <div className="text-lg">Datos Enfermera</div>
            <div className="mt-[20px]">
              Nombre: {appointmentData?.nurse?.user.full_name}
            </div>
            <div className="mt-[20px]">
              Gender:{" "}
              {capitalizeFirstLetter(appointmentData?.nurse?.user.gender)}
            </div>
          </BaseCard>
        )}
        {appointmentData?.timeline_events && (
          <BaseCard>
            <div className="relative text-lg">Eventos</div>
            {appointmentData?.timeline_events?.map((event) => (
              <div
                key={event.id}
                className="relative flex w-full flex-row justify-between"
              >
                <p className="mt-[20px] w-[30%]">
                  {humanizeDate(event.created_at)}
                </p>
                <p className="mt-[20px] ml-[5%] w-[65%] text-start">
                  {capitalizeFirstLetter(event.tag.split("-").join(" "))}
                </p>
              </div>
            ))}
          </BaseCard>
        )}
      </div>
    </>
  );
}

export default AppointmentIndividual;
