import React from "react";

import { BsXLg } from "react-icons/bs";

function NewClient({ close, onChange, callback }) {
  return (
    <div className="w-[400px]">
      <div className="flex items-center justify-between p-2">
        <div className="text-xl font-[500]">Crear nuevo cliente</div>
        <button className="hover:bg-gray-100" onClick={close} type="button">
          <BsXLg />
        </button>
      </div>
      <form
        className="mt-4"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {[
          { placeholder: "Nombre de Organización", name: "display_name" },
          {
            placeholder: "Tax ID",
            name: "tax_id",
          },
        ].map((item, i) => (
          <input
            key={i}
            type="text"
            className="mt-2 w-full rounded-sm border p-[5px] text-sm"
            placeholder={item.placeholder}
            name={item.name}
            onChange={onChange}
          />
        ))}
        <select
          className="mt-2 w-full rounded-sm border p-[5px] text-sm"
          name="country"
          onChange={onChange}
        >
          <option value="Mexico">Mexico</option>
          <option value="Chile">Chile</option>
        </select>
        <select
          className="mt-2 w-full rounded-sm border p-[5px] text-sm"
          name="mode"
          onChange={onChange}
        >
          <option value="development">DEV</option>
          <option value="production">PROD</option>
        </select>
        <div className="mt-10 flex items-center justify-end">
          <button
            className="rounded-full bg-gray-200 py-[10px] px-[20px] text-sm text-gray-500 drop-shadow-md hover:bg-gray-100"
            onClick={close}
            type="button"
          >
            Cancelar
          </button>
          <button
            type="button"
            className="ml-2 rounded-full bg-blue-600 py-[10px] px-[20px] text-sm text-white drop-shadow-md hover:bg-blue-500"
            onClick={callback}
          >
            Crear nueva organización
          </button>
        </div>
      </form>
    </div>
  );
}

export default NewClient;
