import React, { useState } from "react";

import { observer } from "mobx-react";
import Modal from "react-modal";
import Swal from "sweetalert2";

import { registerClientAdmin } from "../../api-client/examedi/authentication";
import BaseCard from "../../Components/BaseCard";
import Header from "../../Components/Shared/Header";
import Td from "../../Components/Table/Td";
import Th from "../../Components/Table/Th";
import Tr from "../../Components/Table/Tr";
import { useStore } from "../../store";
import NewUser from "./NewUser";

Modal.setAppElement(document.getElementById("root"));

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const AdminTables = observer(({ administrators }) => (
  <BaseCard className="w-full">
    <table className="w-full">
      <thead>
        <Tr>
          {[
            "ID",
            "Nombre",
            "Correo",
            "Status",
            // "Último log in",
            // "Roles y Permisos",
          ].map((element, i) => (
            <Th key={i}>{element}</Th>
          ))}
        </Tr>
      </thead>
      <tbody className="divide-y">
        {administrators.models.map((element, i) => (
          <Tr className="hover:bg-gray-100" key={i}>
            {[
              element.id,
              element.get("name"),
              element.get("email"),
              element.get("active") ? "Activo" : "Inactivo",
              // "Hace 3 días",
              // "Administrador",
            ].map((element, i) => (
              <Td key={i}>{element}</Td>
            ))}
          </Tr>
        ))}
      </tbody>
    </table>
  </BaseCard>
));

const Administration = observer(({ clients, users }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newUserData, setNewUserData] = useState({
    email: "",
    password: "",
    name: "",
  });
  const [loading, setLoading] = useState(false);

  const client = useStore((state) => state.client);

  const createUser = async () => {
    setLoading(true);
    const { data, status } = await registerClientAdmin(
      newUserData.email,
      newUserData.password
    );
    if (data && status < 300) {
      const created_user = await users?.create({
        email: newUserData.email,
        name: newUserData.name,
        main_client_id: client?.id,
        uid: data.auth_id,
      });
      await client?.administrators.create({
        email: newUserData.email,
        name: newUserData.name,
        active: true,
        user_id: created_user.id,
      });
      setNewUserData({ email: "", password: "", name: "" });
      if (status === 201) {
        Swal.fire({
          title: "Usuario creado",
          text: "El usuario ha sido creado exitosamente.",
          icon: "success",
        });
      } else if (status === 200) {
        Swal.fire({
          title: "Usuario asignado",
          text: "El usuario ya está registrado en Examedi.com y se le asignaron permisos. Si no recuerda la contraseña, contactar a soporte.",
          icon: "warning",
        });
      }
    }
    setLoading(false);
    setIsOpen(false);
  };

  return (
    <div>
      <Modal isOpen={isOpen} style={customStyles}>
        <NewUser
          close={() => {
            setIsOpen(false);
          }}
          newUserData={newUserData}
          setNewUserData={setNewUserData}
          callback={createUser}
          loading={loading}
        />
      </Modal>
      <div className="flex w-full items-center justify-between">
        <Header>Organización y permisos</Header>
        <button
          type="button"
          onClick={() => {
            setIsOpen(true);
          }}
          className="rounded-full bg-blue-600 py-[10px] px-[20px] text-sm font-[500] text-white drop-shadow-md hover:bg-blue-500"
        >
          + Crear nuevo usuario
        </button>
      </div>
      <div className="mt-[20px]">
        {client?.administrators?.models.length > 0 && (
          <AdminTables administrators={client?.administrators} />
        )}
      </div>
    </div>
  );
});

export default Administration;
