import moment from "moment";

export const getAge = (dateString) => {
  const today = moment().endOf("day");
  const birthDate = moment(dateString).utc();
  const age = today.diff(birthDate, "years");
  return age;
};

export const formatBirthday = (dateString) => {
  const birthDate = moment(dateString).utc().format("DD/MM/YYYY");
  return birthDate;
};
