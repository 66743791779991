import React, { useState } from "react";

function ChangePassword({
  close,
  passwordData,
  setPasswordData,
  callback,
  loading,
}) {
  const [error, setError] = useState();

  return (
    <div className="w-[400px]">
      <div className="text-xl font-[500]">Cambiar contraseña</div>
      <form
        className="mt-4"
        onSubmit={(e) => {
          e.preventDefault();
          if (passwordData.password !== passwordData.password_confirmation) {
            setError("Las contraseñas no coinciden");
          } else if (passwordData.password.length < 6) {
            setError("La contraseña debe tener al menos 6 caracteres");
          } else {
            callback();
            setPasswordData({ password: "", password_confirmation: "" });
          }
        }}
      >
        <input
          type="password"
          className="mt-2 w-full rounded-sm border p-[5px] text-sm"
          placeholder="Contraseña"
          name="password"
          onChange={(e) =>
            setPasswordData({ ...passwordData, password: e.target.value })
          }
          autoComplete="new-password"
          disabled={loading}
        />
        <input
          type="password"
          className="mt-2 w-full rounded-sm border p-[5px] text-sm"
          placeholder="Confirmar contraseña"
          name="password_confirmation"
          onChange={(e) =>
            setPasswordData({
              ...passwordData,
              [e.target.name]: e.target.value,
            })
          }
          autoComplete="new-password"
          disabled={loading}
        />
        {error && <div className="mt-2 text-sm text-red-500">{error}</div>}
        <div className="mt-10 flex items-center justify-end">
          <button
            className="rounded-full bg-gray-200 py-[10px] px-[20px] text-sm text-gray-500 drop-shadow-md hover:bg-gray-100 disabled:opacity-50"
            onClick={close}
            disabled={loading}
            type="button"
          >
            Cancelar
          </button>
          <button
            className="ml-2 rounded-full bg-blue-600 py-[10px] px-[20px] text-sm text-white drop-shadow-md hover:bg-blue-500 disabled:opacity-50"
            type="submit"
            disabled={loading}
          >
            Cambiar contraseña
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChangePassword;
