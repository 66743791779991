import React, { useState } from "react";

import clsx from "clsx";

import { useStore } from "../../store";
import TrashIcon from "../../Icons/TrashIcon";
import GenericTooltip from "../Shared/GenericTooltip";
import InfoCircleYellowIcon from "../Shared/InfoCircleYellowIcon";

import { numberWithPeriods } from "../../utils/numbers";
import { countryCode } from "../../utils/countryascode";

function ServiceDetailLine({
  service,
  invalidServices,
  handleServiceEliminate,
}) {
  const [tooltip, showTooltip] = useState(true);
  const client = useStore((state) => state.client);

  return (
    <div
      className={clsx(
        "flex items-center justify-between",
        "border-none text-sm leading-6"
      )}
      key={service.id}
    >
      <div className="flex w-9/12 font-light">
        <p>{service.name}</p>
        {invalidServices && Object.keys(invalidServices).includes(service.id) && (
          <>
            <InfoCircleYellowIcon
              data-tip={`Este exámen tiene valor 0 porque es parte de ${
                invalidServices[service.id]
              }`}
              className={clsx(
                "!z-0 ml-4",
                "!fill-examedi-blue-strong-25 !stroke-examedi-blue-strong-75 opacity-90"
              )}
              onMouseEnter={() => showTooltip(true)}
              onMouseLeave={() => {
                showTooltip(false);
                setTimeout(() => showTooltip(true), 50);
              }}
            />
            {tooltip && (
              <GenericTooltip className="font-normal" isMobile={false} />
            )}
          </>
        )}
      </div>
      <div className="flex w-3/12 justify-between">
        <p>${numberWithPeriods(service.price, countryCode(client))}</p>
        <button
          type="button"
          onClick={() => {
            handleServiceEliminate(service);
          }}
        >
          <TrashIcon
            className={clsx(
              "icon-hover",
              "h-4 w-4",
              "stroke-examedi-gray-4",
              "transition-colors",
              "group-hover:stroke-examedi-gray-3"
            )}
          />
        </button>
      </div>
    </div>
  );
}

export default ServiceDetailLine;
