import React from "react";
import clsx from "clsx";

function BaseCard({ children, className, style }) {
  return (
    <div
      className={clsx(
        "rounded-md bg-white",
        "p-[20px]",
        "drop-shadow-md",
        className
      )}
      style={style}
    >
      {children}
    </div>
  );
}

export default BaseCard;
