import React, { useEffect } from "react";

import moment from "moment";

import { vanillaPersistedStore } from "../../vanillaStore";
import { logout } from "../../authentication";

function TokenWrapper({ children }) {
  const checkIfExpired = (expiration) => {
    const now = moment().valueOf();

    return expiration < now;
  };

  useEffect(() => {
    const { expiration } = vanillaPersistedStore.getState().getUserData();
    if (expiration && checkIfExpired(expiration)) {
      logout();
    }
  });
  return children;
}

export default TokenWrapper;
