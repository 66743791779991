import moment from "moment";

export function checkFilled(obj) {
  return Object.values(obj).every((item) => item.length > 0);
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatPhoneNumber(number, countryCode) {
  if (countryCode === "cl") {
    const numberPrefix = "+569";
    if (number.startsWith(numberPrefix) && number.length === 12) {
      return number;
    }
    if (number.length === 8 && !number.startsWith("+")) {
      return `${numberPrefix}${number}`;
    }
    if (number.length === 9) {
      return `${numberPrefix}${number.slice(1)}`;
    }
    if (number.length === 11 && number.startsWith("+")) {
      return `${numberPrefix}${number.slice(3)}`;
    }
    return number;
  }
  if (countryCode === "mx") {
    const numberPrefix = "+52";
    if (!number.startsWith(numberPrefix)) {
      return `${numberPrefix}${number}`;
    }
    return number;
  }
  return number;
}

export const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .trim()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

export const humanizeHour = (hour) => hour.slice(0, -3);

export const humanizeDate = (date) =>
  moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm");

export default {
  checkFilled,
  capitalizeFirstLetter,
  formatPhoneNumber,
  validateEmail,
  humanizeHour,
};
