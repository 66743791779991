import React, { useEffect, useRef, useState, useMemo } from "react";

import clsx from "clsx";

import CardContent from "../common/CardContent";
import ServiceDetailLine from "./ServiceDetailLine";
import CartIcon from "../../Icons/CartIcon";

import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useStore } from "../../store";
import { getServices } from "../../api-client/examedi/services";
import { getPacks } from "../../api-client/examedi/packs";
import { validateCoupon } from "../../api-client/examedi/referrals";
import { numberWithPeriods } from "../../utils/numbers";
import { countryCode } from "../../utils/countryascode";

function ServicesSearch({
  handleServiceEliminate,
  handleServiceSelect,
  selectedServices,
  preferenceServices,
  commune,
  price,
  extraAmount,
  invalidServices,
  parseSelectedServices,
}) {
  const [query, setQuery] = useState("");
  const [availableServices, setAvailableServices] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [discount, setDiscount] = useState(0);
  const containerRef = useRef();
  const client = useStore((state) => state.client);
  const salesSource = client?.get("sales_source").toLowerCase();

  useOnClickOutside(containerRef, () => {
    setShowResults(false);
  });

  const prefetch = async () => {
    const [services, packs] = await Promise.all([
      getServices({
        query: "",
        source: salesSource,
        commune,
        country: countryCode(client),
        labsIds: client?.get("labs_ids"),
      }),
      getPacks({
        source: salesSource,
        commune,
      }),
    ]);

    if (services || packs) {
      const validLabServices = services.map((service) => ({
        ...service,
        isPack: false,
      }));
      const validPacks = packs.map((pack) => ({
        ...pack,
        isPack: true,
        name:
          pack.country === "Chile"
            ? `Pack: ${pack.name}`
            : `Check up: ${pack.name}`,
      }));

      const packsAndServices = [...validLabServices, ...validPacks];
      setAvailableServices(packsAndServices);
    }
  };

  useEffect(() => {
    if (client) {
      prefetch();
    }
  }, [client]);

  const discountedAmount = useMemo(() => {
    if (discount) {
      return price * discount;
    }
    return 0;
  }, [discount, price]);

  const totalPrice = useMemo(
    () => price + extraAmount - discountedAmount,
    [price, extraAmount, discountedAmount]
  );

  useEffect(() => {
    const getAndSetDiscount = async () => {
      const cart = parseSelectedServices();
      if (client?.get("coupon") && cart.length > 0) {
        const { discount } = await validateCoupon(client.get("coupon"), cart);
        if (discount) {
          setDiscount(discount);
        }
      }
    };
    if (!discount) {
      getAndSetDiscount();
    }
  }, [client, selectedServices]);

  return (
    <div className="relative" ref={containerRef}>
      <input
        type="text"
        placeholder="Buscar por nombre"
        className={clsx(
          "rounded-md border py-[5px] px-[10px]",
          "w-full",
          "h-10",
          "placeholder:text-sm"
        )}
        onChange={(e) => {
          setQuery(e.target.value.toLowerCase());
        }}
        onClick={() => {
          setShowResults(true);
        }}
      />
      <div
        className={clsx(
          "left-0 h-[150px] w-full divide-y",
          "z-50 overflow-scroll bg-white"
        )}
      >
        {preferenceServices?.length > 0 && (
          <>
            <div className="mt-5 text-xs font-[500]">Los más frecuentes</div>
            <div className="mb-2 border">
              {preferenceServices.map((item, i) => (
                <div
                  key={i}
                  className={clsx(
                    "flex items-center p-2 hover:bg-gray-100",
                    "cursor-pointer text-sm text-gray-800"
                  )}
                  onClick={() => {
                    handleServiceSelect(item);
                  }}
                >
                  <span className="w-[80px]">
                    $
                    {numberWithPeriods(
                      item.price || item.final_price,
                      countryCode(client)
                    )}
                  </span>
                  {item.name}
                </div>
              ))}
            </div>
          </>
        )}

        {availableServices
          .filter((item) => !selectedServices.includes(item))
          .filter((item) => item.name.toLowerCase().includes(query))
          .map((item, i) => (
            <div
              key={i}
              className={clsx(
                "flex items-center p-2 hover:bg-gray-100",
                "cursor-pointer text-sm text-gray-800"
              )}
              onClick={() => {
                handleServiceSelect(item);
              }}
            >
              <span className="w-[80px]">
                $
                {numberWithPeriods(
                  item.price || item.final_price,
                  countryCode(client)
                )}
              </span>
              {item.name}
            </div>
          ))}
      </div>
      {selectedServices.length > 0 && (
        <CardContent>
          <div className="flex items-center gap-x-2 text-sm font-medium">
            <CartIcon className="h-4 w-4 fill-transparent stroke-examedi-black-dark" />
            Servicios Seleccionados:
          </div>
          <div className="mt-[5px] mb-2 max-h-[150px] w-full overflow-y-scroll">
            {selectedServices.map((item, i) => (
              <ServiceDetailLine
                key={item.id}
                service={item}
                invalidServices={invalidServices}
                handleServiceEliminate={handleServiceEliminate}
              />
            ))}
          </div>
          <div
            className={clsx(
              "flex flex-col pr-4",
              "text-sm font-light leading-6"
            )}
          >
            {extraAmount > 0 && (
              <>
                <div className="flex w-full rounded-sm">
                  <p className="w-9/12">Precio servicios médicos</p>
                  <p className="w-2/12 font-medium">
                    ${numberWithPeriods(price, countryCode(client))}
                  </p>
                </div>
                <div className="flex w-full rounded-sm">
                  <p className="w-9/12">Precio servicio a domicilio</p>
                  <p className="w-2/12 font-medium">
                    ${numberWithPeriods(extraAmount, countryCode(client))}
                  </p>
                </div>
              </>
            )}
            <div
              className={clsx(
                "flex w-full rounded-sm pb-2",
                "font-medium text-examedi-blue-strong"
              )}
            >
              <p className="w-9/12">
                Descuento {client.get("display_name")} {discount * 100}%
              </p>
              <p className="w-2/12">
                ${numberWithPeriods(discountedAmount, countryCode(client))}
              </p>
            </div>
            <div
              className={clsx(
                "flex w-full rounded-sm pt-2 font-medium",
                "border-t-[0.5px] border-examedi-gray-regular"
              )}
            >
              <p className="w-9/12">Monto a pagar:</p>
              <p className="w-2/12 font-medium">
                ${numberWithPeriods(totalPrice, countryCode(client))}
              </p>
            </div>
          </div>
        </CardContent>
      )}
    </div>
  );
}

export default ServicesSearch;
