import React, { useState, useEffect } from "react";

import { observer } from "mobx-react";
import Modal from "react-modal";
import { Link } from "react-router-dom";

import BaseCard from "../../Components/BaseCard";
import Header from "../../Components/Shared/Header";
import Td from "../../Components/Table/Td";
import Th from "../../Components/Table/Th";
import Tr from "../../Components/Table/Tr";
import { useStore } from "../../store";
import NewPatient from "./NewPatient";
import LoadingSpinnerIcon from "../../Icons/LoadingSpinner";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function PatientTable({ client }) {
  const patients = client?.patients;
  const [filteredPatients, setFilteredPatients] = useState(patients.models);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const filteredPatients = patients.models.filter((element) => !!element.get("examedi_patient_id"));
    setFilteredPatients(filteredPatients);
  }, [patients]);

  const handleSearch = (e) => {
    let filteredPatients = [];
    setSearch(e.target.value);
    if (e.target.value === "") {
      filteredPatients = patients.models;
    } else {
      filteredPatients = patients.models.filter((patient) => (
        `${patient.get("name")} ${patient.get("last_name")}`?.toLowerCase()
          .includes(e.target.value.toLowerCase())
        || patient.get("name")?.toLowerCase().includes(e.target.value?.toLowerCase())
        || patient.get("email")?.toLowerCase().includes(e.target.value?.toLowerCase())
        || patient.get("last_name")?.toLowerCase().includes(e.target.value?.toLowerCase())
        || patient.get("document_number")?.toLowerCase().includes(e.target.value?.toLowerCase())
      ));
    }
    filteredPatients = filteredPatients.filter((element) => !!element.get("examedi_patient_id"));
    setFilteredPatients(filteredPatients);
  };

  return (
    <>
      <input
        className="w-[30%] rounded-md border-2 border-gray-500 p-2"
        placeholder="Filtrar por nombre, identificador, o email"
        value={search}
        onChange={handleSearch}
      />
      <BaseCard className="mt-[20px] w-full">
        <table className="w-full">
          <thead>
            <Tr>
              {["", "Nombre", "Correo", "Nro. Celular", "Sexo"].map(
                (element, i) => (
                  <Th key={i}>{element}</Th>
                )
              )}
            </Tr>
          </thead>
          <tbody className="divide-y">
            {filteredPatients?.map((element, i) => (
              <Tr className="hover:bg-gray-100" key={i}>
                {[
                  <Link
                    to={`/app/patients/${element.get("examedi_patient_id")}`}
                    className="text-blue-700 hover:underline"
                  >
                    Ver más
                  </Link>,
                  `${element.get("name")} ${element.get("last_name")}`,
                  element.get("email"),
                  element.get("phone"),
                  element.get("gender") === "male" ? "Masculino" : "Femenino",
                ].map((element, i) => (
                  <Td key={i}>{element}</Td>
                ))}
              </Tr>
            ))}
          </tbody>
        </table>
      </BaseCard>
    </>
  );
}

const Patients = observer(({ clients, users }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [creatingPatient, setCreatingPatient] = useState(false);

  const client = useStore((state) => state.client);

  return (
    <>
      <Modal isOpen={isOpen} style={customStyles}>
        {creatingPatient ? (
          <LoadingSpinnerIcon />
        ) : (
          <NewPatient
            close={() => {
              setIsOpen(false);
            }}
            client={client}
          />
        )}
      </Modal>
      <div className="flex w-full items-center justify-between">
        <Header>Listado de Pacientes</Header>
        <button
          type="button"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="button ml-[10px] flex items-center justify-between rounded-full bg-blue-600 px-[20px] py-[10px] text-sm font-[500] text-white drop-shadow-md hover:bg-blue-500 "
        >
          + Paciente
        </button>
      </div>
      <div className="mt-[20px]">
        {!client?.patients && (
          <div className="mt-[20px] flex h-[300px] w-full items-center justify-center bg-gray-200">
            <LoadingSpinnerIcon size={44} />
          </div>
        )}
        {client?.patients && client?.patients?.models.length > 0 && (
          <PatientTable client={client} />
        )}
        {client?.patients && client?.patients?.models.length <= 0 && (
          <div className="mt-[20px] flex h-[300px] w-full items-center justify-center rounded-sm bg-gray-200 text-gray-500">
            Todavía no tienes ningún paciente registrado 🥺
          </div>
        )}
      </div>
    </>
  );
});

export default Patients;
