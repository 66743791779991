import React, { useEffect, useState } from "react";

import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import moment from "moment";

import BaseCard from "../Components/BaseCard";
import Header from "../Components/Shared/Header";
import Td from "../Components/Table/Td";
import Th from "../Components/Table/Th";
import Tr from "../Components/Table/Tr";
import LoadingSpinnerIcon from "../Icons/LoadingSpinner";
import { useStore } from "../store";

import { getAppointmentsByClient } from "../api-client/examedi/appointments"
import { numberWithPeriods } from "../utils/numbers";
import { getCountryCode } from "../utils/country";

function PaymentTable({ appointments }) {
  const client = useStore((state) => state.client);

  return (
    <BaseCard className="mt-[20px] w-full">
      <table className="w-full">
        <thead>
          <Tr>
            {["Appointment", "Fecha", "Estado", "Monto"].map(
              (element, i) => (
                <Th key={i}>{element}</Th>
              )
            )}
          </Tr>
        </thead>
        <tbody className="divide-y">
          {appointments.map((element, i) => (
            <Tr className="hover:bg-gray-100" key={i}>
              {[
                <Link
                  to={`/app/appointments/${element?.id}`}
                  className="text-blue-700 hover:underline"
                >
                  Ver detalle
                </Link>,
                `${moment(
                  element?.begin_date,
                ).format("LL")}`,
                element?.payments?.some((element) => element.status === "Payment Successful") ? (
                  <div className="font-[500] text-green-400">Pagado</div>
                ) : (
                  <div className="font-[500] text-orange-400">
                    Pendiente de pago
                  </div>
                ),
                `$${numberWithPeriods(element?.payments?.reduce((prev, current) => prev + current.amount, 0),
                    getCountryCode(client.get("country")) )}`,
              ].map((element, i) => (
                <Td key={i}>{element}</Td>
              ))}
            </Tr>
          ))}
        </tbody>
      </table>
    </BaseCard>
  );
}

const Payments = observer(({ clients, users }) => {
  const client = useStore((state) => state.client);
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAppointments = async () => {
      if (client) {
        const res = await getAppointmentsByClient(client.get("sales_source"));
        const appointmentsWithPayments = res.filter((appointment) => appointment?.payments?.length > 0);
        setAppointments(appointmentsWithPayments);
      }
      setLoading(false);
    };
    fetchAppointments();
  }, [client]);

  const renderPayments = () => {
    if (loading) {
      return (
        <div className="mt-[20px] flex h-[300px] w-full items-center justify-center bg-gray-200">
          <LoadingSpinnerIcon size={44} />
        </div>
      );
    }
    if (appointments.length !== 0) {
      return <PaymentTable appointments={appointments} />;
    }
    return (
      <div className="mt-[20px] flex h-[300px] w-full items-center justify-center rounded-sm bg-gray-200 text-gray-500">
        Todavía no tienes ningún pago registrado 🥺
      </div>
    );
  };

  return (
    <>
      <Header>Pagos y Transacciones</Header>
      <div className="mt-[20px]">{renderPayments()}</div>
    </>
  );
});

export default Payments;
