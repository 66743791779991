import React, { useEffect, useState } from "react";

import Modal from "react-modal";
import { observer } from "mobx-react";

import moment from "moment/moment";
import Header from "../../Components/Shared/Header";
import NewAppointment from "./NewAppointment";
import { useStore } from "../../store";
import LoadingSpinnerIcon from "../../Icons/LoadingSpinner";
import AppointmentsFilter from "../../Components/Tools/appointments/AppointmentsFilter";
import AppointmentsTable from "../../Components/Tools/appointments/AppointmentsTable";
import { getAppointmentsByClient } from "../../api-client/examedi/appointments";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Appointments = observer(({ users, clients }) => {
  const client = useStore((state) => state.client);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [appointments, setAppointments] = useState(false);
  const [datesFilters, setDatesFilters] = useState({
    beginDate: `${moment().format("YYYY-MM-DD")}`,
    endDate: `${moment().add(5, "days").format("YYYY-MM-DD")}`,
  });

  const fetchAppointmentsData = async () => {
    const res = await getAppointmentsByClient(
      client?.get("sales_source"),
      datesFilters
    );
    setAppointments(res);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAppointmentsData();
  }, [client]);

  const renderAppointments = () => {
    if (isLoading) {
      return (
        <div className="mt-[20px] flex h-[300px] w-full items-center justify-center bg-gray-200">
          <LoadingSpinnerIcon />
        </div>
      );
    }
    if (appointments.length > 0) {
      return <AppointmentsTable appointments={appointments} />;
    }
    return (
      <div className="mt-[20px] flex h-[300px] w-full items-center justify-center rounded-sm bg-gray-200 text-gray-500">
        Todavía no hay agendas 🥺
      </div>
    );
  };

  return (
    <>
      <Modal isOpen={isOpen} style={customStyles}>
        <NewAppointment
          users={users}
          clients={clients}
          close={() => {
            setIsOpen(false);
          }}
        />
      </Modal>
      <div className="mb-[10px] flex w-full items-center justify-between">
        <Header>Agendas de Pacientes</Header>
      </div>
      <AppointmentsFilter
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        setIsLoading={setIsLoading}
        client={client}
        setAppointments={setAppointments}
        datesFilters={datesFilters}
        setDatesFilters={setDatesFilters}
      />
      <div className="mt-5">{renderAppointments()}</div>
    </>
  );
});

export default Appointments;
