import React from "react";

function PinIcon(props) {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5 2C13.775 2 14 2.22375 14 2.5C14 2.77625 13.775 3 13.5 3H12.9281L12.1313 14.1438C12.0594 15.1906 11.1594 16 10.1375 16H3.8625C2.81281 16 1.94219 15.1906 1.86719 14.1438L1.07156 3H0.5C0.223875 3 0 2.77625 0 2.5C0 2.22375 0.223875 2 0.5 2H3.47187L4.28125 0.705C4.55625 0.266437 5.0375 0 5.55312 0H8.44687C8.9625 0 9.44375 0.266437 9.69063 0.705L10.5281 2H13.5ZM5.55312 1C5.38125 1 5.22187 1.08875 5.13125 1.235L4.65312 2H9.34688L8.86875 1.235C8.77812 1.08875 8.61875 1 8.44687 1H5.55312ZM11.925 3H2.07406L2.86469 14.0719C2.90219 14.5938 3.3375 15 3.8625 15H10.1375C10.6625 15 11.0969 14.5938 11.1344 14.0719L11.925 3Z"
        fill="black"
      />
    </svg>
  );
}

export default PinIcon;
