import React, { useState, useEffect } from "react";

import moment from "moment";
import { observer } from "mobx-react";

import nurseService from "../../api-client/examedi/nurse";
import Header from "../../Components/Shared/Header";
import places from "../../utils/places.json";
import { useStore } from "../../store";

const initialFilters = {
  country: "Mexico",
  region: "all",
  city: "all",
  service_type: "all",
};

const SERVICE_TYPES = {
  blood: "Sangre",
  urine: "Orina",
  covid: "Covid",
  kine: "Kine",
  vaccine: "Vacunas",
};
const heatmapColors = {
  0: "red",
  1: "#87dfff",
  2: "#87dfff",
  3: "#87dfff",
  4: "#87dfff",
  5: "#7ec4fd",
  6: "#7ec4fd",
  7: "#7ec4fd",
  8: "#7ec4fd",
  9: "#46a5f5",
  10: "#46a5f5",
  11: "#46a5f5",
  12: "#46a5f5",
  13: "#305ec4",
  14: "#305ec4",
  15: "#305ec4",
  16: "#305ec4",
  17: "blue",
  18: "blue",
  19: "blue",
  20: "blue",
  21: "#2d00df",
  22: "#2d00df",
  23: "#2d00df",
  24: "#2d00df",
  25: "#5000bf",
};

const Capacity = observer(({ clients, users }) => {
  const [filters, setFilters] = useState(initialFilters);
  const [capacityHourLabels, setCapacityHourLabels] = useState([]);
  const [dates, setDates] = useState([]);
  const [heatmap, setHeatmap] = useState({});
  const [loading, setLoading] = useState(true);
  const client = useStore((state) => state.client);

  const regions = Object.keys(places[filters.country]);
  const cities = places[filters.country][filters.region];

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  useEffect(() => {
    const clientCountry =
      client && client.get("country") ? client.get("country") : "";
    if (filters && clientCountry) {
      const region = Object.keys(places[clientCountry])[0];
      try {
        setFilters({
          ...filters,
          country: clientCountry,
          region,
          city: places[clientCountry][region][0],
        });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
  }, [client]);

  const fetchHeatmap = async () => {
    setLoading(true);
    const labs_ids =
      client && client.get("labs_ids") ? client.get("labs_ids") : [];
    const fetchedHeatmap = await nurseService.fetchHeatMap(
      filters.city,
      filters.service_type,
      labs_ids
    );

    const tempHeatmap = fetchedHeatmap.data.data;
    const days = Object.keys(fetchedHeatmap.data.data);

    setHeatmap(tempHeatmap);

    // Temporarily store name and value
    let tempHourLabels = ["", 0, []];

    days.forEach((day) => {
      const hourLabels = Object.keys(tempHeatmap[`${day}`]).sort();
      const hourLengths = hourLabels.length;
      if (hourLengths > tempHourLabels[1]) {
        tempHourLabels = [day, hourLengths, hourLabels];
      }
    });

    setDates(days);
    setCapacityHourLabels(tempHourLabels[2]);
    setLoading(false);
  };

  return (
    <>
      <Header>Capacity</Header>
      <div className="mt-4 grid grid-cols-5 2xl:grid-cols-6">
        <div className="mx-2 flex flex-col">
          <span>País</span>
          <select
            className="rounded-md border border-gray-400 px-2 py-1"
            value={filters.country}
            name="country"
            onChange={handleFilterChange}
            disabled
          >
            <option value={filters.country} key={filters.country}>
              {filters.country}
            </option>
          </select>
        </div>
        <div className="mx-2 flex flex-col">
          <span>{filters.country === "México" ? "Estado" : "Region"}</span>
          <select
            className="rounded-md border border-gray-400 px-2 py-1"
            value={filters.region}
            label={filters.country === "México" ? "Estado" : "Region"}
            name="region"
            onChange={handleFilterChange}
          >
            {regions?.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>
        </div>

        <div className="mx-2 flex flex-col">
          <span>{filters.country === "México" ? "Municipio" : "Comuna"}</span>
          <select
            className="rounded-md border border-gray-400 px-2 py-1"
            value={filters.city}
            label={filters.country === "México" ? "Municipio" : "Comuna"}
            name="city"
            onChange={handleFilterChange}
            disabled={filters.region === "all"}
          >
            {cities?.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>
        </div>

        <div className="mx-2 flex flex-col">
          <span>Categoría del Servicio</span>
          <select
            className="rounded-md border border-gray-400 px-2 py-1"
            value={filters.service_type}
            label="Categoría de Servicio"
            name="service_type"
            onChange={handleFilterChange}
          >
            <option value="all">Todos</option>
            {Object.entries(SERVICE_TYPES).map(([key, value]) => (
              <option value={key} key={key}>
                {value}
              </option>
            ))}
          </select>
        </div>

        <div className="flex items-center justify-center">
          <button
            type="button"
            className={`h-10 rounded py-2 px-4 font-bold text-white ${
              loading ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-700"
            }`}
            onClick={() => {
              fetchHeatmap();
            }}
            disabled={loading}
          >
            Filtrar
          </button>
        </div>
      </div>

      <div className="heatmap mt-12">
        <div className="relative mt-4 rounded-md">
          <div className="absolute left-0 top-12 bottom-0 w-24">
            {capacityHourLabels?.map((item, i) => (
              <div
                className="flex h-12 items-center justify-center border-2 border-gray-300 bg-white px-1 py-3"
                key={i}
              >
                {item}
              </div>
            ))}
          </div>

          <div className="relative ml-24 inline-block w-[90%] overflow-scroll whitespace-nowrap">
            <div className="absolute w-[90%]">
              {dates?.map((item, i) => (
                <div
                  className="inline-flex h-12 w-28 items-center justify-center border-2 border-gray-300 bg-white px-1 py-3"
                  key={i}
                >
                  {moment(item).locale("es").format("ddd Do")}
                </div>
              ))}
            </div>
            {dates?.map((date, i) => (
              <div
                className="mt-12 inline-block h-full w-28 overflow-scroll bg-white"
                key={i}
              >
                {capacityHourLabels.map((hourLabel, k) => {
                  let capacityAmount = 0;
                  if (
                    heatmap[`${date}`] &&
                    heatmap[`${date}`][`${hourLabel}`]
                  ) {
                    capacityAmount = heatmap[`${date}`][`${hourLabel}`].length;
                  }

                  return (
                    <div
                      className="flex h-12 w-28 items-center justify-center border-2 border-gray-300"
                      key={k}
                      style={{
                        backgroundColor: capacityAmount
                          ? heatmapColors[`${capacityAmount}`]
                          : "red",
                      }}
                    >
                      <p style={{ color: "white", fontWeight: 500 }}>
                        {capacityAmount}
                      </p>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
});

export default Capacity;
