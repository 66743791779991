import create from "zustand/vanilla";
import { persist } from "zustand/middleware";

export const vanillaStore = create((set) => ({
  // user
  user: 0,
  setUser: (newUser) => set(() => ({ user: newUser })),
  // rehydrated
  rehydrated: false,
  setRehydrated: (newRehydrated) => set(() => ({ rehydrated: newRehydrated })),
  client: false,
  setClient: (newClient) => set(() => ({ client: newClient })),
}));

export const vanillaPersistedStore = create(
  persist(
    (set, get) => ({
      userData: {
        email: "",
        roles: [],
        auth_id: "",
        "https://api.saludando.cl/jwt/claims:": {
          roles: [],
        },
        access_token: "",
        scope: "",
        token_type: "",
        expiration: "",
      },
      setUserData: (newUserData) => set(() => ({ userData: newUserData })),
      getUserData: () => get().userData,
    }),
    {
      name: "vanillaPersistedStore",
      getStorage: () => localStorage,
    }
  )
);
