import { places, supportedCommunesPerRegion } from "../constants/places";

function findStreetNumber(result, query) {
  const found = result.address_components.find((cmp) =>
    cmp.types.includes("street_number")
  );
  if (found?.long_name) {
    return [found.long_name];
  }
  const regexResult = query.match(/\d+/g);
  if (regexResult && Array.isArray(regexResult) && regexResult.length > 1) {
    return [...new Set(regexResult)];
  }
  return regexResult || undefined;
}

function findStreet(result) {
  const found = result.address_components.find((cmp) =>
    cmp.types.includes("route")
  );
  return found ? found.long_name : undefined;
}

function filterMunicipality(municipality, fromPostalCode) {
  if (municipality.postal_codes && fromPostalCode?.long_name) {
    const [min, max] = municipality.postal_codes;
    const postal_code = parseInt(fromPostalCode?.long_name, 10);
    if (min <= postal_code && max >= postal_code) {
      return true;
    }
  }
  return false;
}

/**
 * Finds "comunas" for Chile and "colonias" for México
 */
function findCommune(result, country) {
  if (country === "Chile") {
    const fromLocality = result.address_components.find((cmp) =>
      cmp.types.includes("locality")
    );
    const fromArea3 = result.address_components.find((cmp) =>
      cmp.types.includes("administrative_area_level_3")
    );
    if (fromLocality?.long_name && fromArea3?.long_name) {
      if (fromLocality?.long_name === fromArea3?.long_name) {
        return [fromArea3?.long_name];
      }
      return [fromLocality.long_name, fromArea3.long_name];
    }
    if (fromLocality?.long_name) {
      return [fromLocality.long_name];
    }
    if (fromArea3?.long_name) {
      return [fromArea3.long_name];
    }

    return undefined;
  }
  if (country === "México") {
    const fromPostalCode = result.address_components.find((cmp) =>
      cmp.types.includes("postal_code")
    );
    const fromRegion = result.address_components.find((cmp) =>
      cmp.types.includes("administrative_area_level_1")
    );
    let municipalities = supportedCommunesPerRegion[fromRegion?.long_name];

    municipalities = municipalities?.filter((mun) =>
      filterMunicipality(mun, fromPostalCode)
    );
    const municipality = municipalities?.[0]?.name;
    return municipality ? [municipality] : undefined;
  }
  return undefined;
}

function findColony(result, country) {
  if (country === "Chile") {
    return undefined;
  }
  if (country === "México") {
    const fromSublocality = result.address_components.find((cmp) =>
      cmp.types.includes("sublocality")
    );
    if (fromSublocality?.long_name) {
      return [fromSublocality.long_name];
    }
    return undefined;
  }
  return undefined;
}

function findRegion(result) {
  const fromArea1 = result.address_components.find((cmp) =>
    cmp.types.includes("administrative_area_level_1")
  );
  const fromArea2 = result.address_components.find((cmp) =>
    cmp.types.includes("administrative_area_level_2")
  );
  if (
    fromArea1?.long_name &&
    fromArea2?.long_name &&
    fromArea1.long_name !== fromArea2.long_name
  ) {
    return [fromArea2.long_name, fromArea1.long_name];
  }
  if (fromArea1?.long_name) {
    return [fromArea1.long_name];
  }
  if (fromArea2?.long_name) {
    return [fromArea2.long_name];
  }
  return undefined;
}

/**
 * Note:
 *
 * Seems impossible no to find a country because we use country filtering on google map request
 */
function findCountry(result) {
  const found = result.address_components.find((cmp) =>
    cmp.types.includes("country")
  );
  return found ? found.long_name : undefined;
}

function findZipCode(result) {
  const found = result.address_components.find((cmp) =>
    cmp.types.includes("postal_code")
  );
  return found ? found.long_name : undefined;
}

function translateColony(raw) {
  if (raw.includes("Polanco")) {
    return "Polanco";
  }
  if (raw.includes("Lomas de Chapultepec")) {
    return "Lomas de Chapultepec";
  }
  if (raw.includes("Escandón")) {
    return "Escandón";
  }
  const mapper = {
    "Colonia Condesa": "La Condesa",
    "Colonia del Valle Centro": "Del Valle Centro",
    "Colonia del Valle Norte": "Del Valle Norte",
    "Colonia del Valle Sur": "Del Valle Sur",
    Penalolen: "Peñalolén",
    Águilas: "Las Águilas",
  };
  return mapper[raw] || raw;
}

function translateRegion(raw) {
  const mapper = {
    Coquimbo: "Región de Coquimbo",
    "Región Metropolitana": "Región Metropolitana de Santiago",
    Valparaíso: "Región de Valparaíso",
    "Bío Bío": "Región del Bío Bío",
    "Arica y Parinacota": "Región de Arica y Parinacota",
    Tarapacá: "Región de Tarapacá",
    Antofagasta: "Región de Antofagasta",
    Atacama: "Región de Atacama",
    "Libertador General Bernardo O'Higgins":
      "Región del Libertador General Bernardo O'Higgins",
    Maule: "Región del Maule",
    "La Araucanía": "Región de la Araucanía",
    "Los Ríos": "Región de los Ríos",
    "Los Lagos": "Región de los Lagos",
    "Aysén del General Carlos Ibáñez del Campo":
      "Región de Aysén del General Carlos Ibáñez del Campo",
    "Magallanes y Antártica Chilena":
      "Región de Magallanes y de la Antártica Chilena",
    Concepcion: "Región del Bío Bío",
  };
  return mapper[raw] || raw;
}

export function parseAddress(result, query) {
  const country = findCountry(result);
  const numbers = findStreetNumber(result, query);
  const street = findStreet(result);
  const communes = findCommune(result, country);
  let colonies = findColony(result, country)?.map(translateColony);
  const regions = findRegion(result)
    ?.map(translateRegion)
    .filter(
      (region) => country && Object.keys(places[country]).includes(region)
    );
  const zip_code = findZipCode(result);
  const longitude = result.geometry.location.lng;
  const latitude = result.geometry.location.lat;

  // To remove duplicated commune names after traslation step
  if (colonies && colonies.length > 1) {
    colonies = [...new Set(colonies)];
  }

  if (
    !numbers ||
    !street ||
    !communes ||
    !regions ||
    !country ||
    !longitude ||
    !latitude
  ) {
    const missing = [];
    if (!numbers) missing.push("number");
    if (!street) missing.push("street");
    if (!communes) missing.push("commune");
    if (!colonies && country === "México") missing.push("colonies");
    if (!regions) missing.push("region");
    if (!country) missing.push("country");
    if (!longitude) missing.push("longitude");
    if (!latitude) missing.push("latitude");

    return undefined;
  }

  const parsedAddresses = [];
  for (const number of numbers) {
    for (const commune of communes) {
      for (const region of regions) {
        parsedAddresses.push({
          type: "unknown",
          number,
          street,
          commune,
          region,
          country,
          zip_code,
          longitude,
          latitude,
        });
      }
    }
  }
  if (country === "Chile") {
    /**
     * Filter for only existing (and known [we are supposed to know them all]) region <=> communes matches
     */
    const filtered = parsedAddresses.filter((pas) =>
      places[pas.country]?.[pas.region]?.includes(pas.commune)
    );

    return filtered;
  }
  if (country === "México") {
    if (colonies) {
      const parsedAddressesWithColonies = [];
      for (const colony of colonies) {
        for (const address of parsedAddresses) {
          parsedAddressesWithColonies.push({
            ...address,
            colony,
          });
        }
      }
      return parsedAddressesWithColonies;
    }
  }
  return parsedAddresses;
}

export default { parseAddress };
