import React, { useMemo } from "react";

import clsx from "clsx";
import ReactTooltip from "react-tooltip";

/**
 * @example
 *  <InfoCircleYellowIcon
 *      data-tip={content}
 *  />
 *  <GenericTooltip />
 */
function GenericTooltip({
  className,
  position = "bottom",
  removeArrow,
  disabled,
  clickable = false,
  effect = "float",
  isMobile,
}) {
  const offset = useMemo(() => {
    if (isMobile) {
      return { bottom: 3 };
    }
    return undefined;
  }, [isMobile]);

  return (
    <ReactTooltip
      place={position}
      effect={effect}
      arrowColor={removeArrow ? "transparent" : "#00000099"}
      disable={disabled}
      clickable={clickable}
      offset={offset}
      className={clsx(
        "!transition-opacity !duration-300",
        "pointer-events-none !opacity-80",
        "border-2 border-transparent",
        "!w-64 lg:w-56",
        "!text-sm lg:!text-sm",
        "!m-0 !p-2",
        "!rounded-lg",
        "!backdrop-blur-xl",
        className
      )}
    />
  );
}

export default GenericTooltip;
