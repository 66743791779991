import { initializeApp } from "firebase/app";
import { getAuth, signOut, signInWithCustomToken } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import { vanillaStore, vanillaPersistedStore } from "./vanillaStore";

const firebaseConfig = {
  apiKey: "AIzaSyC65j06OhvHIvEYTh428btH0sHnMWZkVdE",
  authDomain: "examedi-as-a-service.firebaseapp.com",
  projectId: "examedi-as-a-service",
  storageBucket: "examedi-as-a-service.appspot.com",
  messagingSenderId: "38349204066",
  appId: "1:38349204066:web:5ae07262ac08eb2a1a7f3b",
  measurementId: "G-3XKX2ZMB7J",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const auth = getAuth();

export async function login(token, users, clients) {
  const result = await signInWithCustomToken(auth, token);
  const { user } = result;
  if (user) {
    vanillaStore.getState().setUser(user);
    vanillaStore.getState().setRehydrated(true);
    const { email } = vanillaPersistedStore.getState().getUserData();
    const myUser = users.filterModels("email", email)[0];
    const newClient = clients?.get(myUser?.get("main_client_id"));
    vanillaStore.getState().setClient(newClient);
  }
}

export function logout() {
  signOut(auth)
    .then(() => {
      vanillaPersistedStore.getState().setUserData({
        email: "",
        roles: [],
        auth_id: "",
        "https://api.saludando.cl/jwt/claims:": {
          roles: [],
        },
        access_token: "",
        scope: "",
        token_type: "",
        expiration: "",
      });
      vanillaStore.getState().setClient(false);
      // User is signed out
      // ...
      vanillaStore.getState().setRehydrated(true);
      vanillaStore.getState().setUser(0);
    })
    .catch((error) => {
      console.log("LOGOUT ERROR DETECTED");
      console.error(error);
    });
}
