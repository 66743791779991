import React, { useEffect, useState } from "react";

import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { BiArrowFromLeft, BiArrowFromRight } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";

import { logout } from "../authentication";
import { useStore } from "../store";

const TopBar = observer(
  ({ clients, user, collapse, isCollapsed, className }) => {
    const rehydrated = useStore((state) => state.rehydrated);
    const client = useStore((state) => state.client);
    const [showMenu, setShowMenu] = useState(true);

    const sessionUser = useStore((state) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
      if (!sessionUser) {
        navigate("/");
      }
    }, [sessionUser]);

    useEffect(() => {
      if (client?.get("display_name")) {
        document.title = `${client.get(
          "display_name"
        )} - Laboratory as a Service by Examedi`;
      } else {
        document.title = "Examedi as a Service";
      }
    }, [client]);

    return (
      <div
        className={clsx(
          "flex w-full items-center justify-between border-b-[1px] py-[10px] px-[40px]",
          className
        )}
      >
        <div
          onClick={collapse}
          className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full hover:bg-gray-100"
        >
          {isCollapsed ? (
            <BiArrowFromLeft className="cursor-pointer" size={18} />
          ) : (
            <BiArrowFromRight className="cursor-pointer" size={18} />
          )}
        </div>
        <div className="flex items-center">
          <span className="mr-[20px] text-sm font-bold text-gray-500">
            {client?.get("logo_url") ? (
              <img
                src={client?.get("logo_url")}
                className="h-12 w-48 object-contain"
                alt="logo"
              />
            ) : (
              <span className="text-2xl font-bold">
                {client?.get("display_name")}
              </span>
            )}
          </span>
          <div className="relative">
            <div className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-blue-600 text-base text-white drop-shadow-md hover:bg-blue-500">
              {user?.get("name").split(" ")?.[0]?.[0] || ""}
              {user?.get("name").split(" ")?.[1]?.[0] || ""}
            </div>
            {showMenu && <div className="absolute top-0" />}
          </div>
          <div
            className="ml-2 cursor-pointer rounded-full p-2 transition-colors hover:bg-gray-200"
            onClick={logout}
          >
            <FiLogOut />
          </div>
        </div>
      </div>
    );
  }
);

export default TopBar;
