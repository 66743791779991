import qs from "qs";
import client from "../client";

export async function fetchAvailability(
  appointmentId,
  servicesIds,
  packsIds,
  localePath
) {
  try {
    const params = {
      appointment_id: appointmentId,
      locale_path: localePath,
    };
    if (servicesIds) {
      params.services_ids = servicesIds;
    }
    if (packsIds) {
      params.packs_ids = packsIds;
    }
    const res = await client.get(
      `/multiple-availabilities-v2/?${qs.stringify(params, {
        addQueryPrefix: false,
        arrayFormat: "repeat",
      })}`
    );
    return res.data;
  } catch (err) {
    return err;
  }
}

export default { fetchAvailability };
