import client from "../client";

const scopes = [
  "create:consulta_appointment",
  "create:hso_appointment",
  "list:consulta_appointment",
  "list:hso_appointment",
  "list:hso_service",
  "create:hso_service",
  "delete:hso_service",
  "update:hso_service",
  "read:consulta_appointment",
  "read:consulta_service",
  "read:hso_appointment",
  "update:consulta_appointment",
  "update:hso_appointment",
  "list:hso_workers",
  "create:hso_workers",
  "update:hso_workers",
  "delete:hso_workers",
  "list:payments",
];

export async function login(email, password) {
  try {
    const res = await client.post("/users/client-admin-login/", {
      email,
      password,
      scopes: scopes.join(" "),
    });
    const { data, status } = res;
    return { data, status };
  } catch (err) {
    return { err, status: err?.response?.status };
  }
}

export async function registerClientAdmin(email, password) {
  try {
    const res = await client.post("/users/client-admin-register/", {
      email,
      password,
    });
    const { data, status } = res;
    return { data, status };
  } catch (err) {
    return { err, status: err?.response?.status };
  }
}

export async function changeClientAdminPassword(authId, password) {
  try {
    const res = await client.post("/users/client-admin-change-password/", {
      auth_id: authId,
      password,
    });
    const { data, status } = res;
    return { data, status };
  } catch (err) {
    return { err, status: err?.response?.status };
  }
}
