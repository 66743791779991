import React, { useState, useEffect, useMemo } from "react";

import clsx from "clsx";
import moment from "moment";
import { Timestamp } from "firebase/firestore";
import { observer } from "mobx-react";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import { BsXLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import {
  createAppointment,
  manualConfirm,
  setHour,
  getInvalidServices,
  sendSlackAppointmentNotification,
} from "../../api-client/examedi/appointments";
import AddressSearch from "../../Components/Tools/AddressSearch";
import DisplayAvailability from "../../Components/Tools/DisplayAvailability";
import PatientSearch from "../../Components/Tools/PatientSearch";
import ServicesSearch from "../../Components/Tools/ServicesSearch";
import { useStore } from "../../store";

const NewAppointment = observer(({ users, clients, close }) => {
  const navigate = useNavigate();
  const [propertyType, setPropertyType] = useState("house");
  const [infoStorage, setInfoStorage] = useState({
    services: [],
    patient: false,
    address: false,
    folio: null,
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const [step, setStep] = useState("main"); // [main, services]
  const [selectedServices, setSelectedServices] = useState([]);
  const [appointmentId, setAppointmentId] = useState("");
  const [invalidServices, setInvalidServices] = useState({});
  const [selectedBlock, setSelectedBlock] = useState({
    begin_date: "",
    end_date: "",
  });
  // CLIVI logic
  const [paymentResponsible, setPaymentResponsible] = useState("client"); // [client, patient]: who has to pay?
  const [scheduleResponsible, setScheduleResponsible] = useState("client"); // [client, patient]: who adds address + date?
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const user = useStore((state) => state.user);
  const myUser = users.filterModels("uid", user.uid)[0];
  // const client = clients.filterModels("id", myUser?.get("main_client_id"))[0];
  const client = useStore((state) => state.client);
  const payments = client?.payments;
  const packs = useMemo(
    () => selectedServices.filter((service) => service.isPack),
    [selectedServices]
  );
  const services = useMemo(
    () => selectedServices.filter((service) => !service.isPack),
    [selectedServices]
  );

  const handleServiceSelect = (service) => {
    setSelectedServices((prevServices) => [...prevServices, service]);
  };

  const parseSelectedServices = () => {
    const cart = [{ insurance: "particular", services: [], packs: [] }];
    for (const service of selectedServices) {
      if (service.isPack) {
        cart[0].packs.push({ item: service });
      } else {
        cart[0].services.push({ item: service });
      }
    }
    return cart;
  };

  const calculateTotalPrice = (invalidServices) => {
    let total = 0;
    for (const service of selectedServices) {
      if (!Object.keys(invalidServices).includes(service.id)) {
        total += service.isPack ? service.final_price : service.price;
      }
    }
    return total;
  };

  const setInvalidServicesAndAdjustedPrice = async () => {
    const invalidServices = {};
    const cart = parseSelectedServices();
    const services = await getInvalidServices(cart);

    services?.[0]?.forEach((item) => {
      invalidServices[item.service] = item.parent_services;
    });

    setInvalidServices(invalidServices);
    setTotalPrice(calculateTotalPrice(invalidServices));
  };

  useEffect(() => {
    setInvalidServicesAndAdjustedPrice();
  }, [selectedServices]);

  const handleServiceEliminate = (service) => {
    setSelectedServices((prevServices) =>
      prevServices.filter((item) => item !== service)
    );
  };

  const partialAddressUpdate = (key, value) => {
    setInfoStorage({
      ...infoStorage,
      address: {
        ...infoStorage.address,
        [key]: value,
      },
    });
  };

  const formatSalesSource = (salesSource) =>
    salesSource.replaceAll(" ", "").toLowerCase();

  const createPendingPatientAppointment = async () => {
    setLoading(true);
    const addr = infoStorage.address;

    const addressData = {
      address_line_1: `${addr.street} ${addr.number}`,
      colony: addr.colony,
      comuna: addr.commune,
      country: addr.country,
      region: addr.region,
      target_address: `${addr.street} ${addr.number}, ${addr.commune}, ${addr.region}, ${addr.country}`,
      target_address_additional_info: addr.target_address_additional_info,
      target_apartment_number: addr.target_apartment_number,
      target_lat: addr.latitude,
      target_lng: addr.longitude,
      target_residence_type: propertyType,
      target_street_number: addr.number,
      zip_code: addr.zip_code,
    };

    const packs = selectedServices.filter((service) => service.isPack);
    const services = selectedServices.filter((service) => !service.isPack);
    if (packs.length > 1) {
      toast.error("Solo puedes seleccionar un pack por cita");
      return;
    }

    const res = await createAppointment({
      extra_amount: client?.get("extra_amount") || 0,
      is_last_mile: client?.get("is_last_mile"),
      last_mile_folio: infoStorage.folio,
      patient: {
        patient_id: infoStorage.patient.get("examedi_patient_id"),
      },
      services: services.map((service) => ({
        id: service.id,
        quantity: 1,
      })),
      packs: packs.map((pack) => ({
        id: pack.id,
        quantity: 1,
      })),
      address: addressData,
      sales_source: formatSalesSource(client?.get("sales_source")),
      section_origin:
        scheduleResponsible === "patient" ? "laas-link" : "laas-platform",
      client: client.id,
      is_factura: true,
    });
    let priceTotal = client?.get("extra_amount") || 0;
    selectedServices.map((item) => {
      priceTotal += item.price;
      return true;
    });

    if (res) {
      const appointment = await client?.appointments?.create({
        address: `${infoStorage.address.street} ${infoStorage.address.number}, ${infoStorage.address.commune}`,
        examedi_appointment_id: res.appointment_id,
        service_names: services.map((item) => item.name).join(", "),
        pack_names: packs.map((item) => `Pack: ${item.name}`).join(", "),
        packs_services_names: packs.map((item) =>
          item.services.map((service) => service.name).join(", ")
        ),
        status: "patient_info_pending",
        patient_id: infoStorage.patient.id,
        amount_to_be_paid: priceTotal,
        schedule_responsible: scheduleResponsible,
        created_by: myUser?.get("id"),
        payment_responsible: paymentResponsible,
        created_at: Timestamp.fromDate(new Date()),
        folio: infoStorage.folio,
      });
      Swal.fire({
        icon: "success",
        title: "Envía el link a tu paciente",
        text: "Lo puedes ver entrando a la cita",
      }).then(() => {
        close();
        navigate(`${appointment?.id}`);
      });
    }
    setLoading(false);
  };

  const callCreateAppointment = async () => {
    setLoading(true);
    const addr = infoStorage.address;

    const addressData = {
      address_line_1: `${addr.street} ${addr.number}`,
      colony: addr.colony,
      comuna: addr.commune,
      country: addr.country,
      region: addr.region,
      target_address: `${addr.street} ${addr.number}, ${addr.commune}, ${addr.region}, ${addr.country}`,
      target_address_additional_info: addr.target_address_additional_info,
      target_apartment_number: addr.target_apartment_number,
      target_lat: addr.latitude,
      target_lng: addr.longitude,
      target_residence_type: propertyType,
      target_street_number: addr.number,
      zip_code: addr.zip_code,
    };
    const extraAmount = client?.get("extra_amount") || 0;
    const isLastMile = client?.get("is_last_mile");
    const packs = selectedServices.filter((service) => service.isPack);
    const services = selectedServices.filter((service) => !service.isPack);

    if (packs.length > 1) {
      toast.error("Solo puedes seleccionar un pack por cita");
      return;
    }
    const data = {
      extra_amount: extraAmount,
      is_last_mile: isLastMile,
      last_mile_folio: infoStorage.folio,
      patient: {
        patient_id: infoStorage.patient.get("examedi_patient_id"),
      },
      services: services.map((service) => ({
        id: service.id,
        quantity: 1,
      })),
      packs: packs.map((pack) => ({
        id: pack.id,
        quantity: 1,
      })),
      address: addressData,
      sales_source: formatSalesSource(client?.get("sales_source")),
      section_origin:
        scheduleResponsible === "patient" ? "laas-link" : "laas-platform",
      client: client.id,
      is_factura: true,
    };
    if (client.get("coupon")) {
      data.referral_code = client.get("coupon");
    }

    const res = await createAppointment(data);
    if ("appointment_id" in res) {
      setAppointmentId(res.appointment_id);
      setStep("date");
    } else {
      setStep("error");
      setError("No se pudo crear la cita");
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se ha podido crear la cita",
        footer: `Si el error persiste, por favor comunícate con el equipo de desarrollo, incluyendo la siguiente información:<br/> ${res.response.data.err}`,
      });
      setLoading(false);
    }
  };

  const getPaymentData = () => {
    if (paymentResponsible === "client") {
      return {
        payment_method: null,
        payment_gateway: "Partner",
      };
    }
    return {
      payment_method: "In Address",
      payment_gateway: "Kushki",
      payment_status: "Payment Pending",
    };
  };

  const callSetDate = async () => {
    setLoading(true);
    const setHourData = {
      ...selectedBlock,
      services_ids: services.map((item) => item.id),
      packs_ids: packs.map((item) => item.id),
    };
    const data = {
      country: infoStorage.address.country,
      address_data: {
        address: `${infoStorage.address.street} ${infoStorage.address.number}, ${infoStorage.address.commune} ${infoStorage.address.region}`,
        property_type: propertyType === "house" ? "Casa" : "Departamento",
        extra_property_info: infoStorage.address.target_address_additional_info,
      },
      date: `${moment(selectedBlock.begin_date).format(
        "DD/MM/YYYY HH:mm"
      )} - ${moment(selectedBlock.end_date).format("HH:mm")}`,
      patient_data: {
        document_type: infoStorage.patient.get("document_type"),
        document_number: infoStorage.patient.get("document_number"),
        first_name: infoStorage.patient.get("name"),
        second_name: infoStorage.patient.get("second_name")
          ? infoStorage.patient.get("second_name")
          : "",
        last_name: infoStorage.patient.get("last_name"),
        second_last_name: infoStorage.patient.get("second_last_name")
          ? infoStorage.patient.get("second_last_name")
          : "",
        email: infoStorage.patient.get("email"),
        phone: infoStorage.patient.get("phone"),
        gender: infoStorage.patient.get("gender"),
        birthdate: moment(infoStorage.patient.get("birthdate")).format(
          "DD/MM/YYYY"
        ),
      },
      selected_services: services.map((item) => item.name).join(", "),
      selected_packs: packs.map((item) => item.name).join(", "),
      coupon: client?.get("coupon"),
      sales_source: formatSalesSource(client?.get("display_name")),
    };
    if (client.get("is_last_mile")) {
      data.is_last_mile = true;
      data.last_mile_folio = infoStorage.folio;
      data.extra_amount = client.get("extra_amount") || 0;
    } else {
      data.is_last_mile = false;
    }

    if (propertyType === "apartment") {
      data.address_data.target_apartment_number =
        infoStorage.address.target_apartment_number;
    }

    const res = await sendSlackAppointmentNotification(data);
    // if (res) {
    //   const confirmRes = await manualConfirm(appointmentId, getPaymentData());
    //   if (confirmRes) {
    //     const appointment = await client?.appointments?.create({
    //       address: `${infoStorage.address.street} ${infoStorage.address.number}, ${infoStorage.address.commune}`,
    //       begin_date: selectedBlock.begin_date,
    //       end_date: selectedBlock.end_date,
    //       examedi_appointment_id: appointmentId,
    //       service_names: services.map((item) => item.name).join(", "),
    //       pack_names: packs.map((item) => `Pack: ${item.name}`).join(", "),
    //       packs_services_names: packs.map((item) =>
    //         item.services.map((service) => service.name).join(", ")
    //       ),
    //       created_by: myUser?.get("id"),
    //       status: "scheduled",
    //       patient_id: infoStorage.patient.id,
    //       created_at: Timestamp.fromDate(new Date()),
    //       folio: infoStorage.folio,
    //     });
    //     Swal.fire({
    //       icon: "success",
    //       title: "Cita agendada",
    //       text: "Nos preocuparemos de que salga perfecto",
    //     }).then(async () => {
    //       close();
    //       navigate(`${appointment?.id}`);
    //     });
    //   }
    // }
    if (res.msg === "OK") {
      Swal.fire({
        icon: "success",
        title: "Cita agendada",
        text: "Nos preocuparemos de que salga perfecto",
      }).then(async () => {
        close();
        // navigate(`${appointment?.id}`);
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se ha podido agendar la cita",
      });
    }
    setLoading(false);
  };

  const handleContinueMainStep = () => {
    if (infoStorage.address.street && infoStorage.patient) {
      setStep("services");
    } else if (!infoStorage.patient) {
      toast.error("Debes elegir paciente");
    } else if (!infoStorage.address.street) {
      toast.error("Debes elegir dirección", 3000);
    }
  };

  return (
    <div className="w-[400px]">
      <div className="flex items-center justify-between p-2">
        <div className="text-xl font-[500]">Crea una nueva cita</div>
        <button className="hover:bg-gray-100" onClick={close} type="button">
          <BsXLg />
        </button>
      </div>
      <Toaster />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {step === "error" && (
          <div className="flex flex-col items-center justify-center p-4 text-sm">
            Ha ocurrido un error, por favor intente nuevamente
            <div className="text-red-600">{error}</div>
          </div>
        )}
        {step === "main" && (
          <>
            <div className="mt-[20px]">
              <div className="mb-2 text-sm font-[500]">Paciente</div>
              <PatientSearch
                users={users}
                clients={clients}
                patientSelection={infoStorage.patient}
                callback={(selection) => {
                  setInfoStorage({
                    ...infoStorage,
                    patient: selection,
                  });
                }}
              />
            </div>
            {/* <div className="mt-[20px]">
              <div className="mb-2 text-sm font-[500]">
                ¿Quién ingresará la fecha y hora?
              </div>
              <div className="flex">
                <button
                  type="button"
                  onClick={() => {
                    setScheduleResponsible("client");
                  }}
                  className={clsx(
                    "text-sm",
                    "ml-[5px]",
                    "text-blue-600",
                    "button bg-white",
                    "border-2 border-blue-600",
                    "h-[40px] px-[10px]",
                    "rounded-full drop-shadow-md",
                    scheduleResponsible === "client" &&
                      "bg-blue-600 !text-white"
                  )}
                >
                  Nosotros
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setScheduleResponsible("patient");
                  }}
                  className={clsx(
                    "ml-[5px] text-sm text-blue-600",
                    "button border-2 border-blue-600 bg-white",
                    "h-[40px] px-[10px]",
                    "rounded-full drop-shadow-md",
                    scheduleResponsible === "patient" &&
                      "bg-blue-600 !text-white"
                  )}
                >
                  El/la paciente
                </button>
              </div>
            </div> */}
            <div className="mt-[20px]">
              <div className="mb-2 text-sm font-[500]">Dirección</div>
              <AddressSearch
                addressSelection={infoStorage.address}
                callback={(address) => {
                  setInfoStorage({ ...infoStorage, address });
                }}
              />
              <div className="mt-[20px]">
                <div className="mb-2 text-sm font-[500]">Tipo de Propiedad</div>
                <div className="flex w-1/2 items-center text-sm">
                  <button
                    type="button"
                    className={clsx(
                      "button text-blue-600",
                      "border-2 border-blue-600 bg-white",
                      "h-[40px] px-[10px]",
                      "rounded-full drop-shadow-md",
                      propertyType === "house" && "bg-blue-600 !text-white"
                    )}
                    onClick={() => {
                      setPropertyType("house");
                    }}
                  >
                    Casa
                  </button>
                  <button
                    type="button"
                    className={clsx(
                      "button ml-[5px] bg-white text-blue-600",
                      "border-2 border-blue-600",
                      "h-[40px] px-[10px]",
                      "rounded-full drop-shadow-md",
                      propertyType === "apartment" && "bg-blue-600 !text-white"
                    )}
                    onClick={() => {
                      setPropertyType("apartment");
                    }}
                  >
                    Departamento
                  </button>
                </div>
              </div>

              <div className="mt-4 mb-2 text-sm font-[500]">
                Piso/dpto/oficina (opcional)
              </div>
              <input
                type="text"
                placeholder="Ej: 305"
                className={clsx(
                  "rounded-md border py-[5px] px-[10px]",
                  "w-full",
                  "h-10",
                  "placeholder:text-sm"
                )}
                name="target_apartment_number"
                onChange={(e) => {
                  partialAddressUpdate(e.target.name, e.target.value);
                }}
              />
              <div className="mt-4 mb-2 text-sm font-[500]">
                Número de folio (opcional)
              </div>
              <input
                type="text"
                placeholder="Ej: ABCD23-123"
                className={clsx(
                  "rounded-md border py-[5px] px-[10px]",
                  "w-full",
                  "h-10",
                  "placeholder:text-sm"
                )}
                name="folio"
                onChange={(e) =>
                  setInfoStorage({
                    ...infoStorage,
                    folio: e.target.value,
                  })
                }
              />
              <div className="mt-4 mb-2 text-sm font-[500]">
                Información Adicional (opcional)
              </div>
              <input
                type="text"
                placeholder="Ej: Casa B en condominio, timbre malo"
                className={clsx(
                  "rounded-md border py-[5px] px-[10px]",
                  "w-full",
                  "h-10",
                  "placeholder:text-sm"
                )}
                name="target_address_additional_info"
                onChange={(e) => {
                  partialAddressUpdate(e.target.name, e.target.value);
                }}
              />
            </div>
          </>
        )}
        {step === "services" && (
          <div className="mt-[20px]">
            <div className="mb-2 text-sm font-[500]">
              Selecciona los análisis clínicos
            </div>
            <ServicesSearch
              handleServiceEliminate={handleServiceEliminate}
              handleServiceSelect={handleServiceSelect}
              selectedServices={selectedServices}
              commune={infoStorage.address.commune}
              callback={(services) =>
                setInfoStorage({ ...infoStorage, services })
              }
              preferenceServices={client?.get("preference_services")}
              price={totalPrice}
              extraAmount={client?.get("extra_amount") || 0}
              invalidServices={invalidServices}
              parseSelectedServices={parseSelectedServices}
            />
          </div>
        )}
        {step === "date" && (
          <>
            <div className="mb-2 mt-[20px] text-sm font-[500]">
              Día de la atención
            </div>
            <DisplayAvailability
              appointmentId={appointmentId}
              callback={(beginDate, endDate) => {
                setSelectedBlock({
                  begin_date: beginDate,
                  end_date: endDate,
                });
              }}
              client={client}
              setLoading={setLoading}
              servicesIds={selectedServices.map((item) => item.id)}
              packsIds={packs.map((item) => item.id)}
              setError={setError}
              setStep={setStep}
            />
          </>
        )}

        {paymentResponsible && scheduleResponsible && (
          <>
            {step === "main" && (
              <div className="mt-10 flex items-center justify-end">
                <button
                  type="button"
                  className="rounded-full bg-gray-200 py-[10px] px-[20px] text-sm text-gray-500 drop-shadow-md hover:bg-gray-100"
                  onClick={close}
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="ml-2 rounded-full bg-blue-600 py-[10px] px-[20px] text-sm text-white drop-shadow-md hover:bg-blue-500"
                  onClick={handleContinueMainStep}
                >
                  Ingresar servicios
                </button>
              </div>
            )}
            {step === "services" && scheduleResponsible === "client" && (
              <div className="mt-10 flex items-center justify-end">
                <button
                  type="button"
                  className={clsx(
                    "bg-gray-200 enabled:hover:bg-gray-100",
                    "rounded-full",
                    "text-sm text-gray-500",
                    "py-[10px] px-[20px] drop-shadow-md",
                    "disabled:opacity-50"
                  )}
                  onClick={() => {
                    setStep("main");
                  }}
                  disabled={loading}
                >
                  Editar información
                </button>
                <button
                  type="button"
                  className={clsx(
                    "ml-2 bg-blue-600",
                    "enabled:hover:bg-blue-500",
                    "rounded-full",
                    "text-sm text-white",
                    "py-[10px] px-[20px] drop-shadow-md",
                    "disabled:opacity-50"
                  )}
                  disabled={loading}
                  onClick={callCreateAppointment}
                >
                  Elegir Fecha y Hora
                </button>
              </div>
            )}
            {step === "services" && scheduleResponsible === "patient" && (
              <div>
                <div className="text-sm font-bold">
                  *Solo se puede agregar un pack a la cita
                </div>
                <div className="mt-10 flex items-center justify-end">
                  <button
                    type="button"
                    className={clsx(
                      "bg-gray-200 enabled:hover:bg-gray-100",
                      "rounded-full text-sm text-gray-500",
                      "py-[10px] px-[20px]",
                      "drop-shadow-md disabled:opacity-50"
                    )}
                    onClick={() => {
                      setStep("main");
                    }}
                    disabled={loading}
                  >
                    Editar información
                  </button>
                  <button
                    type="button"
                    className={clsx(
                      "ml-2 bg-blue-600 enabled:hover:bg-blue-500",
                      "rounded-full text-sm text-white",
                      "py-[10px] px-[20px]",
                      "drop-shadow-md disabled:opacity-50"
                    )}
                    onClick={() => {
                      createPendingPatientAppointment();
                    }}
                    disabled={loading}
                  >
                    Crear link de agendamiento
                  </button>
                </div>
              </div>
            )}
            {step === "date" && (
              <div className="mt-10 flex items-center justify-end">
                <button
                  type="button"
                  className={clsx(
                    "rounded-full bg-gray-200 enabled:hover:bg-gray-100",
                    "text-sm text-gray-500",
                    "py-[10px] px-[20px]",
                    "drop-shadow-md",
                    "disabled:opacity-50"
                  )}
                  onClick={() => {
                    setStep("services");
                  }}
                  disabled={loading}
                >
                  Editar servicios
                </button>
                <button
                  type="button"
                  className={clsx(
                    "ml-2 bg-blue-600 enabled:hover:bg-blue-500",
                    "rounded-full text-sm text-white",
                    "py-[10px] px-[20px]",
                    "drop-shadow-md",
                    "disabled:opacity-50"
                  )}
                  onClick={() => {
                    callSetDate();
                  }}
                  disabled={loading}
                >
                  Agendar
                </button>
              </div>
            )}
            {step === "error" && (
              <div className="mt-10 flex items-center justify-end">
                <button
                  type="button"
                  className="rounded-full bg-gray-200 py-[10px] px-[20px] text-sm text-gray-500 drop-shadow-md hover:bg-gray-100"
                  onClick={close}
                >
                  Cerrar
                </button>
              </div>
            )}
          </>
        )}
      </form>
    </div>
  );
});

export default NewAppointment;
