import React from "react";

function ClockIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 4.5V8H11.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8 13.5C11.0376 13.5 13.5 11.0376 13.5 8C13.5 4.96243 11.0376 2.5 8 2.5C4.96243 2.5 2.5 4.96243 2.5 8C2.5 11.0376 4.96243 13.5 8 13.5Z"
        strokeMiterlimit="10"
      />
      <path
        d="M12.2437 1.63751L14.3624 3.75626"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.6377 3.75626L3.75645 1.63751"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ClockIcon;
