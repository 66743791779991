import qs from "qs";
import { vanillaPersistedStore } from "../../vanillaStore";
import client from "../client";

export async function getResultFile(resultId) {
  try {
    const res = await client.get(
      `/results/result-from-id/?result_id=${resultId}`
    );
    return res.data;
  } catch (err) {
    return err;
  }
}

export async function getResultsByAppointment(appointmentId, patientId) {
  try {
    const token = vanillaPersistedStore.getState().getUserData().access_token;
    const res = await client.get(
      `v2/results/?${qs.stringify(
        {
          appointment: appointmentId,
          patient: patientId,
        },
        {
          skipNulls: true,
          addQueryPrefix: false,
          indices: false,
        }
      )}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
}
