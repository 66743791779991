export const ALL_STEPS = [
  {
    key: 1,
    name: "1. Introducción",
    value: "introduction",
  },
  {
    key: 2,
    name: "2. Tipo de agendamiento",
    value: "schedule-type",
  },
  {
    key: 3,
    name: "3. Orden Médica",
    value: "medical-order",
  },
  {
    key: 4,
    name: "4. Direccion",
    value: "address",
  },
  {
    key: 5,
    name: "5. Servicios",
    value: "services",
  },
  {
    key: 6,
    name: "6. Fecha y hora",
    value: "date",
  },
  {
    key: 7,
    name: "7. Paciente",
    value: "patient",
  },
  {
    key: 8,
    name: "8. Información de contacto",
    value: "contact-info",
  },
  {
    key: 9,
    name: "9. Confirmación",
    value: "confirmation",
  },
  {
    key: 10,
    name: "10. Pago",
    value: "payment",
  },
  {
    key: 11,
    name: "11. Subir orden médica",
    value: "upload-order",
  },
  {
    key: 12,
    name: "12. Contacto orden médica",
    value: "contact-order",
  },
  {
    key: 13,
    name: "13. Direccion orden médica",
    value: "address-order",
  },
  {
    key: 14,
    name: "14. Subiendo orden médica",
    value: "uploading-order",
  },
  {
    key: 15,
    name: "15. Orden médica recibida",
    value: "order-received",
  },
];
