import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { Link, useParams } from "react-router-dom";
import Modal from "react-modal";
import Swal from "sweetalert2";

import { FaSave, FaCheck } from "react-icons/fa";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import BaseCard from "../../Components/BaseCard";
import Header from "../../Components/Shared/Header";
import Td from "../../Components/Table/Td";
import Th from "../../Components/Table/Th";
import Tr from "../../Components/Table/Tr";
import NewUser from "../Administration/NewUser";
import { registerClientAdmin } from "../../api-client/examedi/authentication";
import { vanillaPersistedStore } from "../../vanillaStore";
import { ALL_STEPS } from "../../constants/steps";

const storage = getStorage();

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const COUNT_SELF_SERVICE_STEPS = 10;
const COUNT_MEDICAL_ORDER_STEPS = 5;

const AdminTables = observer(({ administrators }) => (
  <BaseCard className="w-full">
    <table className="w-full">
      <thead>
        <Tr>
          {[
            <input
              type="checkbox"
              className="ml-[10px] h-[20px] w-[20px] cursor-pointer"
            />,
            "Nombre",
            "Correo",
            "Status",
          ].map((element, i) => (
            <Th key={i}>{element}</Th>
          ))}
        </Tr>
      </thead>
      <tbody className="divide-y">
        {administrators?.models?.map((element, i) => (
          <Tr className="hover:bg-gray-100" key={i}>
            {[
              <input
                type="checkbox"
                className="ml-[10px] h-[20px] w-[20px] cursor-pointer"
              />,
              <Link
                to={`/app/admin/${element.id}`}
                className="text-blue-700 hover:underline"
              >
                {element.get("name")}
              </Link>,
              element.get("email"),
              element.get("active") ? "Activo" : "Inactivo",
            ].map((element, i) => (
              <Td key={i}>{element}</Td>
            ))}
          </Tr>
        ))}
      </tbody>
    </table>
  </BaseCard>
));

const LogoPicker = observer(({ client, editing, setEditing }) => {
  // TODO: use imgUrl and progresspercent somewhere
  const [imgUrl, setImgUrl] = useState(null);
  const [progressPercent, setProgressPercent] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    const file = e.target[0]?.files[0];
    const filename = `${client.id}.${file.name.split(".")[1]}`;

    if (!file) return;

    const storageRef = ref(storage, `files/${filename}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgressPercent(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          client?.update({ logo_url: downloadURL });
          setEditing(false);
          setImgUrl(downloadURL);
        });
      }
    );
  };
  return (
    <BaseCard>
      <form className="form" onSubmit={handleSubmit}>
        <input type="file" accept="image/*" />
        <div className="mt-[20px] flex">
          <button
            type="submit"
            className="rounded-lg bg-blue-600 py-[5px] px-[20px] text-sm text-white hover:bg-blue-500"
          >
            Subir logo nuevo
          </button>
          {editing && (
            <button
              type="button"
              onClick={() => {
                setEditing(false);
              }}
              className="ml-[10px] rounded-lg bg-red-600 py-[5px] px-[20px] text-sm text-white hover:bg-red-500"
            >
              cancelar
            </button>
          )}
        </div>
      </form>
    </BaseCard>
  );
});

function WidgetForm({ client, updateLogoBg }) {
  const [country, setCountry] = useState("cl");
  const [mainColor, setMainColor] = useState("#ffffff");
  const [availableFeatures, setAvailableFeatures] = useState([
    "self-service",
    "medical-order",
  ]);
  const [showBanner, setShowBanner] = useState(true);
  const [fullPageAvailable, setFullPageAvailable] = useState(false);
  const [customFlow, setCustomFlow] = useState(false);
  const [checkedValueStep, setCheckedValueStep] = useState(ALL_STEPS);
  const [checkedStep, setCheckedStep] = useState(
    new Array(ALL_STEPS.length).fill(true)
  );
  const [error, setError] = useState({});
  const [showDone, setShowDone] = useState(false);

  const userData = vanillaPersistedStore.getState().getUserData();

  const availableCountries = [
    { text: "Chile", value: "Chile" },
    { text: "México", value: "Mexico" },
  ];
  const availableFeaturesOptions = [
    { text: "Autoservicio", value: "self-service" },
    { text: "Orden médica", value: "medical-order" },
  ];

  const toggleAvailableFeatures = (checked, feature) => {
    if (checked) {
      setAvailableFeatures([...availableFeatures, feature]);
      if (feature === "self-service") {
        setCheckedStep(checkedStep.fill(true, 0, COUNT_SELF_SERVICE_STEPS));
        setCheckedValueStep([
          ...checkedValueStep,
          ...ALL_STEPS.slice(0, COUNT_SELF_SERVICE_STEPS),
        ]);
      } else if (feature === "medical-order") {
        setCheckedStep(checkedStep.fill(true, -COUNT_MEDICAL_ORDER_STEPS));
        setCheckedValueStep([
          ...checkedValueStep,
          ...ALL_STEPS.slice(-COUNT_MEDICAL_ORDER_STEPS),
        ]);
      }
    } else {
      setAvailableFeatures(
        availableFeatures.filter((item) => item !== feature)
      );
      if (feature === "self-service") {
        setCheckedStep(checkedStep.fill(false, 0, COUNT_SELF_SERVICE_STEPS));
        setCheckedValueStep(
          checkedValueStep.filter(
            (item) =>
              !ALL_STEPS.slice(0, COUNT_SELF_SERVICE_STEPS).includes(item)
          )
        );
      } else if (feature === "medical-order") {
        setCheckedStep(checkedStep.fill(false, -COUNT_MEDICAL_ORDER_STEPS));
        setCheckedValueStep(
          checkedValueStep.filter(
            (item) =>
              !ALL_STEPS.slice(-COUNT_MEDICAL_ORDER_STEPS).includes(item)
          )
        );
      }
    }
  };

  const setEditing = () => {
    if (showDone) {
      setShowDone(false);
    }
  };

  const save = async () => {
    const error = {};
    if (!mainColor.replace(/ /g, "").length) {
      error.mainColor = "Debe ingresar un color";
    }
    setError(error);

    const uniqueSteps = [
      ...new Map(checkedValueStep.map((item) => [item.key, item])).values(),
    ];

    if (!Object.keys(error).length) {
      const widgetData = {
        main_color: mainColor,
        available_features: availableFeatures,
        show_banner: showBanner,
        full_page_available: fullPageAvailable,
        custom_flow: customFlow,
        steps: uniqueSteps.sort((a, b) => a.key - b.key),
        stepsChecked: checkedStep,
      };
      client.update({
        widget: widgetData,
        country,
      });
      updateLogoBg(widgetData.main_color);
      setShowDone(true);
    }
  };

  const handleOnChangeStep = (event, position, name, key) => {
    const { checked, value } = event.target;
    if (checked) {
      setCheckedValueStep([...checkedValueStep, { key, name, value }]);
    } else {
      setCheckedValueStep(
        checkedValueStep.filter((item) => item.value !== value)
      );
    }
    const updatedCheckedState = checkedStep.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedStep(updatedCheckedState);
  };

  useEffect(() => {
    const getWidgetData = () => {
      const widgetData = client.get("widget");
      if (widgetData) {
        if (widgetData.main_color) {
          setMainColor(widgetData.main_color);
          updateLogoBg(widgetData.main_color);
        }
        if (widgetData.available_features) {
          setAvailableFeatures(widgetData.available_features);
        }
        setShowBanner(!!widgetData.show_banner);
        setFullPageAvailable(!!widgetData.full_page_available);
        setCustomFlow(!!widgetData.custom_flow);
        if (widgetData.steps) {
          setCheckedValueStep(widgetData.steps);
        }
        if (widgetData.stepsChecked) {
          setCheckedStep(widgetData.stepsChecked);
        }
      }
    };
    if (client) {
      if (client.get("country")) {
        setCountry(client.get("country"));
      }
      getWidgetData();
    }
  }, [client, updateLogoBg]);

  return (
    <BaseCard className="flex flex-col gap-y-9">
      <div className="grid grid-cols-2 gap-5">
        {/* Country */}
        <div className="flex flex-col gap-y-2">
          <p className="font-bold">País</p>
          <select
            className={clsx("rounded-md border border-gray-400", "px-2 py-1")}
            value={country}
            name="country"
            onChange={(e) => {
              setEditing();
              setCountry(e.target.value);
            }}
          >
            {availableCountries.map((country) => (
              <option key={country.value} value={country.value}>
                {country.text}
              </option>
            ))}
          </select>
        </div>
        {/* Main color */}
        <div className="flex flex-col gap-y-2">
          <p className="font-bold">Color principal</p>
          <div className="flex gap-x-1">
            <input
              type="text"
              placeholder="Nombre a mostrar"
              className={clsx(
                "rounded-md border border-gray-400",
                "px-2 py-1",
                "h-8",
                "placeholder:text-sm"
              )}
              name="mainColor"
              value={mainColor}
              onChange={(e) => {
                setEditing();
                setMainColor(e.target.value);
              }}
            />
            <input
              type="color"
              className={clsx("bg-white", "h-8 w-7", "cursor-pointer")}
              name="mainColorPicker"
              value={mainColor}
              onChange={(e) => {
                setEditing();
                setMainColor(e.target.value);
              }}
            />
          </div>
          {error.mainColor && (
            <div className="text-sm text-red-600">{error.mainColor}</div>
          )}
        </div>
        {/* Available features */}
        <div className="flex flex-col gap-y-2">
          <p className="font-bold">Funciones disponibles</p>
          {availableFeaturesOptions.map((feature) => (
            <label
              htmlFor={feature.value}
              className="relative inline-flex cursor-pointer items-center"
              key={feature.value}
            >
              <input
                type="checkbox"
                checked={availableFeatures.includes(feature.value)}
                id={feature.value}
                name="availableFeatures"
                className="peer sr-only"
                onChange={(e) => {
                  setEditing();
                  toggleAvailableFeatures(e.target.checked, feature.value);
                }}
              />
              <div
                className={clsx(
                  "relative h-6 w-11 rounded-full bg-gray-200",
                  "peer-checked:after:translate-x-full dark:bg-gray-400",
                  "after:content-[''] peer-checked:after:border-white",
                  "after:absolute after:top-0.5 after:left-[2px]",
                  "after:rounded-full after:border after:bg-white",
                  "after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600 dark:border-gray-600"
                )}
              />
              <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-600">
                {feature.text}
              </span>
            </label>
          ))}
        </div>
        {/* Appearance */}
        <div className="flex flex-col gap-y-2">
          <p className="font-bold">Apariencia</p>
          {/* Show banner */}
          <label
            htmlFor="show_banner"
            className="relative inline-flex cursor-pointer items-center"
          >
            <input
              type="checkbox"
              checked={showBanner}
              id="show_banner"
              name="showBanner"
              className="peer sr-only"
              onChange={(e) => {
                setEditing();
                setShowBanner(e.target.checked);
              }}
            />
            <div
              className={clsx(
                "relative h-6 w-11 rounded-full bg-gray-200",
                "peer-checked:after:translate-x-full dark:bg-gray-400",
                "after:content-[''] peer-checked:after:border-white",
                "after:absolute after:top-0.5 after:left-[2px]",
                "after:rounded-full after:border after:bg-white",
                "after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600 dark:border-gray-600"
              )}
            />
            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-600">
              Mostrar banner
            </span>
          </label>
          {/* Full page available */}
          <label
            htmlFor="full_page_available"
            className="relative inline-flex cursor-pointer items-center"
          >
            <input
              type="checkbox"
              checked={fullPageAvailable}
              id="full_page_available"
              className="peer sr-only"
              onChange={(e) => {
                setEditing();
                setFullPageAvailable(e.target.checked);
              }}
            />
            <div
              className={clsx(
                "relative h-6 w-11 rounded-full bg-gray-200",
                "peer-checked:after:translate-x-full dark:bg-gray-400",
                "after:content-[''] peer-checked:after:border-white",
                "after:absolute after:top-0.5 after:left-[2px]",
                "after:rounded-full after:border after:bg-white",
                "after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600 dark:border-gray-600"
              )}
            />
            <span className="ml-3 mr-2 text-sm font-medium text-gray-900 dark:text-gray-600">
              Pantalla completa
            </span>
            <span className="text-xs text-gray-500">(Url Examedi)</span>
          </label>
        </div>
      </div>
      {/* Custom Flow */}
      {userData?.email?.endsWith("@examedi.com") && (
        <div>
          <div className="flex flex-col gap-y-2 pb-6">
            <p className="font-bold">Custom Flow</p>
            {/* Select custom flow */}
            <label
              htmlFor="custom_flow"
              className="relative inline-flex cursor-pointer items-center"
            >
              <input
                type="checkbox"
                checked={customFlow}
                id="custom_flow"
                name="customFlow"
                className="peer sr-only"
                onChange={(e) => {
                  setEditing();
                  setCustomFlow(e.target.checked);
                }}
              />
              <div
                className={clsx(
                  "relative h-6 w-11 rounded-full bg-gray-200",
                  "peer-checked:after:translate-x-full dark:bg-gray-400",
                  "after:content-[''] peer-checked:after:border-white",
                  "after:absolute after:top-0.5 after:left-[2px]",
                  "after:rounded-full after:border after:bg-white",
                  "after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600 dark:border-gray-600"
                )}
              />
              <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-600">
                Activar
              </span>
            </label>
          </div>
          {customFlow && (
            <div className="flex w-full flex-col gap-y-2">
              <p className="font-bold">Seleccionar Pasos</p>
              <div className="grid grid-flow-col sm:grid-rows-6 md:grid-rows-6 lg:grid-rows-4">
                {ALL_STEPS.map((step, index) => (
                  <div className="flex items-center gap-1 pb-2" key={index}>
                    <input
                      type="checkbox"
                      id={`custom-checkbox-${index}`}
                      checked={checkedStep[index]}
                      value={step.value}
                      onChange={(e) => {
                        setEditing();
                        handleOnChangeStep(e, index, step.name, step.key);
                      }}
                      className="ml-[10px] h-4 w-4 cursor-pointer"
                    />
                    <label
                      htmlFor={`custom-checkbox-${index}`}
                      className="ml-2"
                    >
                      {step.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      <div className="flex flex-col items-center justify-center gap-y-2">
        <button
          type="button"
          className={clsx(
            "flex items-center gap-x-2",
            "bg-blue-600 drop-shadow-md hover:bg-blue-500",
            "rounded-full py-[10px] px-[15px] text-sm font-medium text-white"
          )}
          onClick={save}
        >
          <FaSave /> Guardar
        </button>
        {showDone && (
          <div className="flex items-center gap-x-2 text-green-700">
            <FaCheck /> Cambios guardados con éxito
          </div>
        )}
      </div>
    </BaseCard>
  );
}

const Client = observer(({ clients, users }) => {
  const params = useParams();
  const client = clients?.get(params.id);
  const [isOpen, setIsOpen] = useState(false);
  const [newUserData, setNewUserData] = useState({
    email: "",
    name: "",
    password: "",
  });
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logoBackground, setLogoBackground] = useState("#ffffff");

  const createUser = async () => {
    setLoading(true);
    const { data, status } = await registerClientAdmin(
      newUserData.email,
      newUserData.password
    );
    if (data && status < 300) {
      const created_user = await users?.create({
        email: newUserData.email,
        name: newUserData.name,
        main_client_id: client?.id,
        uid: data.auth_id,
      });
      await client?.administrators.create({
        email: newUserData.email,
        name: newUserData.name,
        active: true,
        user_id: created_user.id,
      });
      setNewUserData({ email: "", password: "", name: "" });
      if (status === 201) {
        Swal.fire({
          title: "Usuario creado",
          text: "El usuario ha sido creado exitosamente.",
          icon: "success",
        });
      } else if (status === 200) {
        Swal.fire({
          title: "Usuario asignado",
          text: "El usuario ya está registrado en Examedi.com y se le asignaron permisos. Si no recuerda la contraseña, contactar a soporte.",
          icon: "warning",
        });
      }
    }
    setLoading(false);
    setIsOpen(false);
  };

  const logoTextColor = (bgColor) => {
    const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#ffffff";
  };

  return (
    clients?.models.length > 0 && (
      <>
        <Modal isOpen={isOpen} style={customStyles}>
          <NewUser
            close={() => {
              setIsOpen(false);
            }}
            callback={createUser}
            newUserData={newUserData}
            setNewUserData={setNewUserData}
            loading={loading}
          />
        </Modal>
        <div className="flex w-full items-center justify-between">
          <Header>
            Gestión de Cliente:{" "}
            <span className="font-bold">{client?.get("display_name")}</span>
          </Header>
          <button
            type="button"
            onClick={() => {
              setIsOpen(true);
            }}
            className="ml-[20px] rounded-full bg-blue-600 py-[10px] px-[20px] text-sm font-[500] text-white drop-shadow-md hover:bg-blue-500"
          >
            + Crear nuevo usuario
          </button>
        </div>
        <div className="mt-[20px]">
          <div className="mb-[10px] font-[500] text-gray-500">Branding</div>
          {client?.get("logo_url") && !editing ? (
            <BaseCard
              className="flex items-center"
              style={{
                backgroundColor: logoBackground,
                color: logoTextColor(logoBackground),
              }}
            >
              <img
                src={client.get("logo_url")}
                alt="uploaded file"
                className="h-auto w-[200px]"
              />
              <div
                onClick={() => {
                  setEditing(true);
                }}
                className="ml-[50px] cursor-pointer text-xs underline hover:text-blue-500"
              >
                Cambiar imagen
              </div>
            </BaseCard>
          ) : (
            <div>
              <LogoPicker
                client={client}
                editing={editing}
                setEditing={setEditing}
              />
            </div>
          )}
        </div>
        <div className="mt-[20px]">
          <div className="mb-[10px] font-medium text-gray-500">
            Configuración de Widget
          </div>
          <WidgetForm client={client} updateLogoBg={setLogoBackground} />
        </div>
        <div className="mt-[20px]">
          <div className="mb-[10px] font-[500] text-gray-500">
            Administradores de la Cuenta
          </div>
          <AdminTables administrators={client?.administrators} />
        </div>
      </>
    )
  );
});

export default Client;
