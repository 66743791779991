import React, { useEffect, useMemo, useRef, useState } from "react";

import clsx from "clsx";
import ReactDOM from "react-dom";

import BackArrowIcon from "../../Icons/BackArrowIcon";

function CarouselWithArrows({
  items,
  automaticProgress,
  delayProgress = 5000,
  arrowPosition = "bottom-right",
  amount = 1,
  transition,
  transitionDirection = "vertical",
  className,
  verticalArrowClassName,
  arrowLeftContainerClassName,
  arrowRightContainerClassName,
  arrowContainerClassName,
  arrowColor = "#4F4F4F",
  arrowColorHover = "#191A18",
  showDots,
  dotClassName,
  dotActiveClassName,
  isMobile,
  disableExtraSpacing,
  keepArrowsMobile,
  arrowPositionInMobile,
  keepDotsDesktop,
  dotsContainerClassName,
  elementTakesFullWidth = false,
  itemsContainerClassName,
  itemWrapperClassName,
  externalSidesArrowBreakpoint,
  disableEndsPadding,
  onItemsOverflow,
}) {
  const [selected, setSelected] = useState();
  const [itemsOverflow, setItemsOverflow] = useState();
  const [localItems, setLocalItems] = useState({});
  const [extraOverflowWidth, setExtraOverflow] = useState();
  const [disableArrowLeft, setDisableArrowLeft] = useState();
  const [disableArrowRight, setDisableArrowRight] = useState();
  const mainContainerRef = useRef(null);
  const containerRef = useRef(null);
  const itemsRef = useRef({});

  function onClickMoveRightHandler() {
    setSelected((prevValue) => {
      if (prevValue !== undefined) {
        if (prevValue + amount <= items.length - 1) {
          return prevValue + amount;
        }
      }
      return prevValue;
    });
  }

  function onClickMoveLeftHandler() {
    setSelected((prevValue) => {
      if (prevValue !== undefined) {
        if (prevValue - amount >= 0) {
          const nextPosition = prevValue - amount;
          const elem = itemsRef.current[nextPosition.toString()];
          if (elem) {
            const { parentElement } = elem;
            const offsetScroll = elem.offsetLeft;
            const containerScrollLeft = parentElement.scrollLeft;
            if (offsetScroll > containerScrollLeft) {
              if (nextPosition - amount >= 0) {
                return nextPosition - amount;
              }
            }
          }
          return nextPosition;
        }
      }
      return 0;
    });
  }

  function onScrollHandler(event) {
    const { target } = event;
    const { scrollWidth, scrollLeft, clientWidth } = target;
    if (scrollLeft === 0) {
      setDisableArrowLeft(true);
      setDisableArrowRight(false);
    } else if (scrollLeft + clientWidth === scrollWidth) {
      setDisableArrowLeft(false);
      setDisableArrowRight(true);
    } else {
      setDisableArrowLeft(false);
      setDisableArrowRight(false);
    }
  }

  function onTouchEndHandler() {
    if (isMobile) {
      setTimeout(() => {
        if (containerRef?.current?.clientWidth) {
          const center = containerRef.current.clientWidth / 2;
          const { scrollLeft } = containerRef.current;
          const scrollCenterValue = scrollLeft + center;
          const index = Object.values(itemsRef.current).findIndex(
            (value) =>
              value &&
              value.offsetLeft < scrollCenterValue &&
              value.offsetLeft + value.clientWidth > scrollCenterValue
          );
          if (index >= 0) {
            setSelected(index);
          }
        }
      }, 500);
    }
  }

  function onClickDotHandler(index) {
    setSelected(index);
  }

  const currentArrowPosition = useMemo(
    () => arrowPositionInMobile || arrowPosition,
    [arrowPosition, arrowPositionInMobile]
  );

  const getTransitionAnimation = (transitionDirection, item) => {
    if (transitionDirection === "vertical") {
      if (item.willOut) {
        return "vertical-out-to-bottom";
      }
      return "vertical-in-from-bottom";
    }
    if (item.willOut) {
      return "horizontal-out-to-left";
    }
    return "horizontal-in-from-left";
  };

  useEffect(() => {
    if (items) {
      setLocalItems((prevValues) => {
        if (transition && Object.keys(prevValues).length > 0) {
          setTimeout(() => {
            setLocalItems(
              items.reduce(
                (acc, elem, index) => ({
                  ...acc,
                  [index]: { ...elem, key: (Math.random() * 100).toString() },
                }),
                {}
              )
            );
          }, 300);
          return Object.values(prevValues).reduce(
            (acc, value, index) => ({
              ...acc,
              [index]: { ...value, willOut: true },
            }),
            {}
          );
        }
        return items.reduce(
          (acc, elem, index) => ({
            ...acc,
            [index]: { ...elem, key: (Math.random() * 100).toString() },
          }),
          {}
        );
      });
    }
  }, [items]);

  useEffect(() => {
    if (
      containerRef &&
      containerRef.current &&
      itemsRef &&
      itemsRef.current &&
      typeof selected === "number"
    ) {
      const elem = itemsRef.current[selected.toString()];
      if (elem) {
        const width = elem.clientWidth;
        const parentRef = elem.parentElement;
        if (isMobile) {
          const elemOffsetLeft = elem.offsetLeft;
          const parentClientWidth = parentRef.clientWidth;
          parentRef?.scrollTo({
            left: elemOffsetLeft - (parentClientWidth / 2 - width / 2),
            behavior: "smooth",
          });
        } else {
          parentRef?.scrollTo({
            left: (width + 16) * selected,
            behavior: "smooth",
          });
        }
      }
    }
  }, [selected, isMobile]);

  useEffect(() => {
    if (localItems && Object.keys(localItems).length > 0) {
      setSelected(0);
      setDisableArrowLeft(true);
    }
  }, [localItems]);

  useEffect(() => {
    setTimeout(() => {
      if (
        items &&
        items.length &&
        itemsRef &&
        itemsRef.current &&
        Object.keys(itemsRef.current).length > 0
      ) {
        const elem = itemsRef.current[0];
        if (elem) {
          const elemWidth = elem.clientWidth;
          const containerElement = elem.parentElement;
          const {
            scrollWidth: containerScrollWidth,
            clientWidth: containerWidth,
          } = containerElement;
          if (!disableExtraSpacing && containerWidth > 0 && elemWidth > 0) {
            const value = (containerWidth - elemWidth) / 2 - 16;
            setExtraOverflow(value);
          }

          if (containerScrollWidth !== 0 || containerWidth !== 0) {
            if (containerScrollWidth > containerWidth) {
              setItemsOverflow(true);
            } else {
              setItemsOverflow(false);
            }
          }
        }
      }
    }, 500);
  }, [items, itemsRef]);

  useEffect(() => {
    let timer;
    if (
      selected !== undefined &&
      selected >= 0 &&
      items &&
      automaticProgress &&
      containerRef &&
      containerRef.current &&
      itemsRef &&
      itemsRef.current
    ) {
      timer = setTimeout(() => {
        if (selected !== undefined) {
          if (selected + 1 < items.length) {
            setSelected(selected + 1);
          } else {
            setSelected(0);
          }
        }
      }, delayProgress);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [automaticProgress, items, selected]);

  useEffect(() => {
    if (mainContainerRef?.current?.parentElement) {
      const containerElement = mainContainerRef.current.parentElement;
      if (!containerElement.classList.contains("relative")) {
        containerElement.classList.add("relative");
      }
    }
  }, [mainContainerRef]);

  useEffect(() => {
    if (onItemsOverflow && itemsOverflow !== undefined) {
      onItemsOverflow(itemsOverflow);
    }
  }, [itemsOverflow]);

  return (
    <section
      ref={mainContainerRef}
      className={clsx(
        ["none", "sides"].includes(currentArrowPosition) && "py-5",
        !["none", "sides"].includes(currentArrowPosition) && "py-14",
        isMobile && !keepArrowsMobile && "pt-3 pb-0",
        "overflow-hidden",
        "relative",
        className
      )}
    >
      {["top", "top-left", "top-right"].includes(currentArrowPosition) &&
        (!isMobile || keepArrowsMobile) &&
        !keepDotsDesktop && (
          <div
            className={clsx(
              "w-full",
              "px-20",
              "flex",
              "gap-x-10",
              "opacity-0",
              "absolute top-4",
              currentArrowPosition === "top" && "justify-center",
              currentArrowPosition === "top-left" && "justify-start",
              currentArrowPosition === "top-right" && "justify-end",
              verticalArrowClassName
            )}
            style={{
              animation: itemsOverflow
                ? "vertical-in-from-top 200ms ease 300ms forwards"
                : "vertical-out-to-top 200ms ease forwards",
            }}
          >
            <button
              type="button"
              onClick={onClickMoveLeftHandler}
              disabled={disableArrowLeft || !itemsOverflow}
              className={clsx(
                "group hover:scale-[0.8] active:scale-75 lg:hover:scale-105 lg:active:scale-95",
                "scale-[0.75] hover:active:scale-[0.7] hover:disabled:scale-[0.7] lg:hover:active:scale-100 lg:hover:disabled:scale-100",
                "mb-2 lg:mb-0",
                disableArrowLeft && "!bg-inherit",
                arrowLeftContainerClassName,
                arrowContainerClassName
              )}
            >
              {disableArrowLeft && (
                <BackArrowIcon
                  className={clsx("h-7 w-7", "stroke-examedi-gray-3")}
                />
              )}
              {!disableArrowLeft && (
                <>
                  <BackArrowIcon
                    className={clsx(
                      "h-7 w-7",
                      "group-hover:hidden",
                      "stroke-examedi-gray-3"
                    )}
                    color={arrowColor}
                  />
                  <BackArrowIcon
                    className={clsx(
                      "h-7 w-7",
                      "hidden group-hover:block",
                      "stroke-examedi-gray-3"
                    )}
                    color={arrowColorHover}
                  />
                </>
              )}
            </button>
            <button
              type="button"
              onClick={onClickMoveRightHandler}
              disabled={disableArrowRight || !itemsOverflow}
              className={clsx(
                "group hover:scale-[0.8] active:scale-75 lg:hover:scale-105 lg:active:scale-95",
                "scale-[0.75] hover:active:scale-[0.7] hover:disabled:scale-[0.7] lg:hover:active:scale-100 lg:hover:disabled:scale-100",
                "mb-2 lg:mb-0",
                disableArrowRight && "!bg-inherit",
                arrowRightContainerClassName,
                arrowContainerClassName
              )}
            >
              {disableArrowRight && (
                <BackArrowIcon
                  className={clsx(
                    "h-7 w-7",
                    "rotate-180",
                    "stroke-examedi-gray-3"
                  )}
                />
              )}
              {!disableArrowRight && (
                <>
                  <BackArrowIcon
                    className={clsx(
                      "h-7 w-7",
                      "rotate-180",
                      "group-hover:hidden",
                      "stroke-examedi-gray-3"
                    )}
                    color={arrowColor}
                  />
                  <BackArrowIcon
                    className={clsx(
                      "h-7 w-7",
                      "rotate-180",
                      "hidden group-hover:block",
                      "stroke-examedi-gray-3"
                    )}
                    color={arrowColorHover}
                  />
                </>
              )}
            </button>
          </div>
        )}
      <div className={clsx("h-full w-full", "flex items-center", "px-2")}>
        {currentArrowPosition === "sides" &&
          (!isMobile || keepArrowsMobile) &&
          !keepDotsDesktop &&
          mainContainerRef &&
          mainContainerRef.current &&
          mainContainerRef.current.parentElement &&
          !(!items || (Array.isArray(items) && items.length === 0)) && (
            <>
              {!!externalSidesArrowBreakpoint &&
                ReactDOM.createPortal(
                  <div
                    className={clsx(
                      "h-full",
                      "hidden",
                      externalSidesArrowBreakpoint === "md" && "md:flex",
                      externalSidesArrowBreakpoint === "lg" && "lg:flex",
                      externalSidesArrowBreakpoint === "2lg" && "2lg:flex",
                      externalSidesArrowBreakpoint === "xl" && "xl:flex",
                      externalSidesArrowBreakpoint === "1xl" && "1xl:flex",
                      "absolute",
                      "top-0 left-0"
                    )}
                    style={{
                      animation: itemsOverflow
                        ? "horizontal-in-from-right 200ms ease 300ms forwards"
                        : "horizontal-out-to-right 200ms ease forwards",
                    }}
                  >
                    <button
                      type="button"
                      onClick={onClickMoveLeftHandler}
                      disabled={disableArrowLeft || !itemsOverflow}
                      className={clsx(
                        "group hover:scale-[0.8] active:scale-75 lg:hover:scale-105 lg:active:scale-95",
                        "scale-[0.75] hover:active:scale-[0.7] hover:disabled:scale-[0.7] lg:hover:active:scale-100 lg:hover:disabled:scale-100",
                        "mb-2 lg:mb-0",
                        "-translate-x-10",
                        disableArrowLeft && "!bg-inherit",
                        arrowLeftContainerClassName,
                        arrowContainerClassName
                      )}
                    >
                      {disableArrowLeft && (
                        <BackArrowIcon
                          className={clsx("h-7 w-7", "stroke-examedi-gray-3")}
                        />
                      )}
                      {!disableArrowLeft && (
                        <>
                          <BackArrowIcon
                            className={clsx(
                              "h-7 w-7",
                              "group-hover:hidden",
                              "stroke-examedi-gray-3"
                            )}
                            color={arrowColor}
                          />
                          <BackArrowIcon
                            className={clsx(
                              "h-7 w-7",
                              "hidden group-hover:block",
                              "stroke-examedi-gray-3"
                            )}
                            color={arrowColorHover}
                          />
                        </>
                      )}
                    </button>
                  </div>,
                  mainContainerRef.current.parentElement
                )}
              <div
                className={clsx(
                  "h-full",
                  "flex",
                  !!externalSidesArrowBreakpoint &&
                    `${externalSidesArrowBreakpoint}:hidden`,
                  "items-center justify-center"
                )}
                style={{
                  animation: itemsOverflow
                    ? "horizontal-in-from-right 200ms ease 300ms forwards"
                    : "horizontal-out-to-right 200ms ease forwards",
                }}
              >
                <button
                  type="button"
                  onClick={onClickMoveLeftHandler}
                  disabled={disableArrowLeft || !itemsOverflow}
                  className={clsx(
                    "group duration-100 hover:scale-[0.8] active:scale-75 lg:hover:scale-105  lg:active:scale-95",
                    "scale-[0.75] hover:active:scale-[0.7] hover:disabled:scale-[0.7] lg:hover:active:scale-100 lg:hover:disabled:scale-100",
                    "mb-2 lg:mb-0",
                    disableArrowLeft && "!bg-inherit",
                    arrowRightContainerClassName,
                    arrowContainerClassName
                  )}
                >
                  {disableArrowLeft && (
                    <BackArrowIcon
                      className={clsx("h-7 w-7 stroke-examedi-gray-4")}
                    />
                  )}
                  {!disableArrowLeft && (
                    <>
                      <BackArrowIcon
                        className={clsx(
                          "h-7 w-7",
                          "group-hover:hidden",
                          "stroke-examedi-gray-3"
                        )}
                        color={arrowColor}
                      />
                      <BackArrowIcon
                        className={clsx(
                          "h-7 w-7",
                          "hidden group-hover:block",
                          "stroke-examedi-gray-3"
                        )}
                        color={arrowColorHover}
                      />
                    </>
                  )}
                </button>
              </div>
            </>
          )}
        {(!items || (Array.isArray(items) && items.length === 0)) && (
          <div
            className={clsx(
              "w-full",
              "col-span-full",
              "py-4",
              "text-center",
              "text-examedi-gray-strong",
              "font-medium",
              "border border-examedi-gray-5/70",
              "rounded-lg bg-examedi-gray-6/60"
            )}
          >
            No hay datos para mostrar
          </div>
        )}
        {Array.isArray(items) && items.length > 0 && (
          <div
            ref={containerRef}
            onTouchEnd={onTouchEndHandler}
            onScroll={onScrollHandler}
            className={clsx(
              "w-full",
              "flex gap-x-4",
              "overflow-x-hidden",
              "overflow-y-hidden",
              "no-scrollbar",
              "relative",
              !itemsOverflow && "justify-center",
              elementTakesFullWidth && "!gap-x-0",
              itemsContainerClassName
            )}
          >
            {(!items || (Array.isArray(items) && items.length === 0)) &&
              "Nada para mostrar"}
            {!disableEndsPadding && (
              <div className={clsx("w-0", "hidden lg:grid")} />
            )}
            {items && Array.isArray(items) && items.length > 0 && (
              <div
                style={{ minWidth: `${extraOverflowWidth}px` }}
                className={clsx("grid lg:hidden")}
              />
            )}
            {Array.isArray(items) &&
              items.length > 0 &&
              Object.entries(localItems).map(([key, item], index) => (
                <div
                  key={item.key}
                  style={
                    transition
                      ? {
                          opacity: item.willOut ? "1" : "0",
                          animation: `${getTransitionAnimation(
                            transitionDirection,
                            item
                          )} 300ms ease ${100 + index * 100}ms forwards`,
                        }
                      : {}
                  }
                  ref={(el) => {
                    itemsRef.current[key] = el;
                  }}
                  className={clsx("grid py-2", itemWrapperClassName)}
                >
                  {item}
                </div>
              ))}
            {items && Array.isArray(items) && items.length > 0 && (
              <div
                style={{ minWidth: `${extraOverflowWidth}px` }}
                className={clsx("grid lg:hidden")}
              />
            )}
            {!disableEndsPadding && (
              <div className={clsx("w-0", "hidden lg:grid")} />
            )}
          </div>
        )}
        {currentArrowPosition === "sides" &&
          (!isMobile || keepArrowsMobile) &&
          !keepDotsDesktop &&
          mainContainerRef &&
          mainContainerRef.current &&
          mainContainerRef.current.parentElement &&
          !(!items || (Array.isArray(items) && items.length === 0)) && (
            <>
              {!!externalSidesArrowBreakpoint &&
                ReactDOM.createPortal(
                  <div
                    className={clsx(
                      "h-full",
                      "hidden",
                      externalSidesArrowBreakpoint === "md" && "md:flex",
                      externalSidesArrowBreakpoint === "lg" && "lg:flex",
                      externalSidesArrowBreakpoint === "2lg" && "2lg:flex",
                      externalSidesArrowBreakpoint === "xl" && "xl:flex",
                      externalSidesArrowBreakpoint === "1xl" && "1xl:flex",
                      "absolute",
                      "top-0 right-0"
                    )}
                    style={{
                      animation: itemsOverflow
                        ? "horizontal-in-from-left 200ms ease 300ms forwards"
                        : "horizontal-out-to-left 200ms ease forwards",
                    }}
                  >
                    <button
                      type="button"
                      onClick={onClickMoveRightHandler}
                      disabled={disableArrowRight || !itemsOverflow}
                      className={clsx(
                        "group hover:scale-[0.8] active:scale-75 lg:hover:scale-105 lg:active:scale-95",
                        "scale-[0.75] hover:active:scale-[0.7] hover:disabled:scale-[0.7] lg:hover:active:scale-100 lg:hover:disabled:scale-100",
                        "mb-2 lg:mb-0",
                        "translate-x-10",
                        disableArrowRight && "!bg-inherit",
                        arrowRightContainerClassName,
                        arrowContainerClassName
                      )}
                    >
                      {disableArrowRight && (
                        <BackArrowIcon
                          className={clsx(
                            "h-7 w-7",
                            "rotate-180",
                            "stroke-examedi-gray-4"
                          )}
                        />
                      )}
                      {!disableArrowRight && (
                        <>
                          <BackArrowIcon
                            className={clsx(
                              "h-7 w-7",
                              "rotate-180",
                              "group-hover:hidden",
                              "stroke-examedi-gray-3"
                            )}
                            color={arrowColor}
                          />
                          <BackArrowIcon
                            className={clsx(
                              "h-7 w-7",
                              "rotate-180",
                              "hidden group-hover:block",
                              "stroke-examedi-gray-3"
                            )}
                            color={arrowColorHover}
                          />
                        </>
                      )}
                    </button>
                  </div>,
                  mainContainerRef.current.parentElement
                )}
              <div
                className={clsx(
                  "h-full",
                  "flex",
                  !!externalSidesArrowBreakpoint &&
                    `${externalSidesArrowBreakpoint}:hidden`,
                  "items-center justify-center"
                )}
                style={{
                  animation: itemsOverflow
                    ? "horizontal-in-from-left 200ms ease 300ms forwards"
                    : "horizontal-out-to-left 200ms ease forwards",
                }}
              >
                <button
                  type="button"
                  onClick={onClickMoveRightHandler}
                  disabled={disableArrowRight || !itemsOverflow}
                  className={clsx(
                    "group duration-100 hover:scale-[0.8] active:scale-75 lg:hover:scale-105 lg:active:scale-95",
                    "scale-[0.75] hover:active:scale-[0.7] hover:disabled:scale-[0.7] lg:hover:active:scale-100 lg:hover:disabled:scale-100",
                    disableArrowRight && "!bg-inherit",
                    arrowRightContainerClassName,
                    arrowContainerClassName
                  )}
                >
                  {disableArrowRight && (
                    <BackArrowIcon
                      className={clsx(
                        "h-7 w-7",
                        "rotate-180",
                        "stroke-examedi-gray-4"
                      )}
                    />
                  )}
                  {!disableArrowRight && (
                    <>
                      <BackArrowIcon
                        className={clsx(
                          "h-7 w-7",
                          "rotate-180",
                          "group-hover:hidden",
                          "stroke-examedi-gray-3"
                        )}
                        color={arrowColor}
                      />
                      <BackArrowIcon
                        className={clsx(
                          "h-7 w-7",
                          "rotate-180",
                          "hidden group-hover:block",
                          "stroke-examedi-gray-3"
                        )}
                        color={arrowColorHover}
                      />
                    </>
                  )}
                </button>
              </div>
            </>
          )}
      </div>
      {["bottom", "bottom-left", "bottom-right"].includes(
        currentArrowPosition
      ) &&
        (!isMobile || keepArrowsMobile) &&
        !keepDotsDesktop && (
          <div
            className={clsx(
              "w-full",
              "px-5 lg:px-20",
              "flex",
              "gap-x-4 lg:gap-x-10",
              "opacity-0",
              "absolute bottom-4",
              currentArrowPosition === "bottom" && "justify-center",
              currentArrowPosition === "bottom-left" && "justify-start",
              currentArrowPosition === "bottom-right" && "justify-end",
              verticalArrowClassName
            )}
            style={{
              animation: itemsOverflow
                ? "vertical-in-from-bottom 200ms ease 300ms forwards"
                : "vertical-out-to-bottom 200ms ease forwards",
            }}
          >
            <button
              type="button"
              onClick={onClickMoveLeftHandler}
              disabled={disableArrowLeft || !itemsOverflow}
              className={clsx(
                "group hover:scale-105 active:scale-95",
                "hover:active:scale-100 hover:disabled:scale-100",
                disableArrowLeft && "!bg-inherit",
                arrowLeftContainerClassName,
                arrowContainerClassName
              )}
            >
              {disableArrowLeft && (
                <BackArrowIcon
                  className={clsx("h-7 w-7", "stroke-examedi-gray-4")}
                />
              )}
              {!disableArrowLeft && (
                <>
                  <BackArrowIcon
                    className={clsx(
                      "h-7 w-7",
                      "stroke-examedi-gray-3 group-hover:hidden"
                    )}
                  />
                  <BackArrowIcon
                    className={clsx(
                      "h-7 w-7",
                      "hidden stroke-examedi-gray-3 group-hover:block"
                    )}
                  />
                </>
              )}
            </button>
            <button
              type="button"
              onClick={onClickMoveRightHandler}
              disabled={disableArrowRight || !itemsOverflow}
              className={clsx(
                "group hover:scale-105 active:scale-95",
                "hover:active:scale-100 hover:disabled:scale-100",
                disableArrowRight && "!bg-inherit",
                arrowRightContainerClassName,
                arrowContainerClassName
              )}
            >
              {disableArrowRight && (
                <BackArrowIcon
                  className={clsx(
                    "h-7 w-7",
                    "rotate-180",
                    "stroke-examedi-gray-3"
                  )}
                />
              )}
              {!disableArrowRight && (
                <>
                  <BackArrowIcon
                    className={clsx(
                      "h-7 w-7",
                      "rotate-180",
                      "group-hover:hidden",
                      "stroke-examedi-gray-3"
                    )}
                    color={arrowColor}
                  />
                  <BackArrowIcon
                    className={clsx(
                      "h-7 w-7",
                      "rotate-180",
                      "hidden group-hover:block",
                      "stroke-examedi-gray-3"
                    )}
                    color={arrowColorHover}
                  />
                </>
              )}
            </button>
          </div>
        )}
      {itemsOverflow && showDots && Array.isArray(items) && items.length > 0 && (
        <div
          className={clsx(
            "w-full",
            "mt-5",
            "flex justify-center",
            "px-5 py-1",
            "gap-x-1.5",
            !keepDotsDesktop && "lg:hidden",
            dotsContainerClassName
          )}
        >
          {items.map((_, index) => (
            <div
              key={index}
              onClick={() => onClickDotHandler(index)}
              className={clsx(
                "cursor-pointer",
                "h-4 w-4 rounded-full border border-black",
                selected != index && [
                  !dotClassName && "bg-white",
                  dotClassName,
                ],
                selected == index && [
                  !dotActiveClassName && "bg-black",
                  dotActiveClassName,
                ]
              )}
            />
          ))}
        </div>
      )}
    </section>
  );
}

function CarouselWithArrowsWrapper({
  className,
  arrowPositionInMobile,
  keepArrowsMobile,
  ...rest
}) {
  return (
    <>
      <CarouselWithArrows
        {...rest}
        className={clsx("hidden lg:block", className)}
      />
      <CarouselWithArrows
        {...rest}
        arrowPositionInMobile={arrowPositionInMobile}
        keepArrowsMobile={keepArrowsMobile}
        className={clsx("lg:hidden", className)}
        isMobile
      />
    </>
  );
}

export default React.memo(CarouselWithArrowsWrapper);
