import React from "react";

import { Link } from "react-router-dom";

import Td from "../../Table/Td";
import Th from "../../Table/Th";
import Tr from "../../Table/Tr";
import BaseCard from "../../BaseCard";
import { humanizeHour } from "../../../utils/inputs";

function AppointmentsTable({ appointments }) {
  const getStatusTagElement = (element) => {
    if (!element) return null;
    if (element?.status === "received-by-lab") {
      return (
        <div className="inline rounded-lg bg-purple-500 px-[5px] py-[2px] text-xs text-white">
          Recibido por laboratorio
        </div>
      );
    }
    if (element.timeline_events.some(({ tag }) => tag === "results-sent")) {
      return (
        <div className="inline rounded-lg bg-green-500 px-[10px] py-[3px] text-xs text-white">
          Resultados Disponibles
        </div>
      );
    }
    if (
      element.timeline_events.some(({ tag }) =>
        ["visited", "finished"].includes(tag)
      )
    ) {
      return (
        <div className="inline rounded-lg bg-green-500 px-[10px] py-[3px] text-xs text-white">
          Visitado
        </div>
      );
    }
    if (element.timeline_events.some(({ tag }) => tag === "cancelled")) {
      return (
        <div className="inline rounded-lg bg-red-500 px-[10px] py-[3px] text-xs text-white">
          Cancelado
        </div>
      );
    }
    if (
      element.timeline_events.some(({ tag }) =>
        ["booked", "confirmed"].includes(tag)
      )
    ) {
      return (
        <div className="inline rounded-lg bg-blue-500 px-[10px] py-[3px] text-xs text-white">
          Agendado
        </div>
      );
    }
    if (
      element.timeline_events.some(({ tag }) => tag === "patient_info_pending")
    ) {
      return (
        <div className="inline rounded-lg bg-orange-500 px-[10px] py-[3px] text-xs text-white">
          Enviado a Paciente
        </div>
      );
    }
    if (
      element.timeline_events.some(({ tag }) =>
        ["initialized", "scheduled"].includes(tag)
      )
    ) {
      return (
        <div className="inline rounded-lg bg-orange-500 px-[10px] py-[3px] text-xs text-white">
          Inicializado
        </div>
      );
    }
    return null;
  };
  return (
    <BaseCard className="mt-[20px] w-full">
      <table className="w-full overflow-scroll">
        <thead>
          <Tr className="h-[30px]">
            {[
              "",
              "Fecha y Hora",
              "Paciente",
              "Dirección",
              "Exámenes",
              "Status",
            ].map((element, i) => (
              <Th key={i}>{element}</Th>
            ))}
          </Tr>
        </thead>
        <tbody className="divide-y">
          {appointments.map((element, i) => (
            <Tr className="h-[30px] hover:bg-gray-100" key={i}>
              {[
                <Link
                  to={`/app/appointments/${element?.id}`}
                  className="text-blue-700 hover:underline"
                >
                  Ver
                </Link>,
                humanizeHour(element?.local_begin_date) || "Pendiente",
                <Link
                  to={`/app/patients/${element?.patients[0]?.id}`}
                  className="text-blue-700 hover:underline"
                >
                  {element?.patients[0]?.fullname}
                </Link>,
                `${element?.target_address.slice(0, 30)}...`,
                `${element?.service_names.slice(0, 30)}...`,
                getStatusTagElement(element),
              ].map((element, i) => (
                <Td key={i}>{element}</Td>
              ))}
            </Tr>
          ))}
        </tbody>
      </table>
    </BaseCard>
  );
}

export default AppointmentsTable;
