import React, { useEffect, useState } from "react";

import moment from "moment";
import clsx from "clsx";

import { fetchAvailability } from "../../api-client/examedi/availability";
import { countryCode } from "../../utils/countryascode";
import LoadingSpinnerIcon from "../../Icons/LoadingSpinner";
import { humanizeHour } from "../../utils/inputs";

function DateCell({ date, dateSelection, handleDateClick }) {
  return (
    <div
      className={clsx(
        "mr-[5px] inline-flex h-[100px] w-[80px] cursor-pointer flex-col items-center justify-center rounded-md border hover:drop-shadow-md",
        dateSelection === date ? "bg-blue-600" : "bg-white"
      )}
      onClick={() => handleDateClick(date)}
    >
      <div
        className={clsx(
          "text-xs font-[500]",
          dateSelection === date ? "text-white" : "text-blue-500"
        )}
      >
        {`${moment(date).format("dddd")}`}
      </div>
      <div
        className={clsx(
          "text-3xl font-[600]",
          dateSelection === date ? "text-white" : "text-black"
        )}
      >{`${moment(date).format("D")}`}</div>
    </div>
  );
}

function DatesDisplay({ days, dateSelection, handleDateClick }) {
  return (
    <div className="w-full overflow-scroll whitespace-nowrap pb-[10px]">
      {[...days].sort().map((item, i) => (
        <DateCell
          key={i}
          date={item}
          dateSelection={dateSelection}
          handleDateClick={handleDateClick}
        />
      ))}
    </div>
  );
}

function HourCell({ hour, hourSelection, handleHourClick }) {
  return (
    <div
      className="mb-[10px] flex h-[40px] cursor-pointer items-center rounded-md bg-gray-100 pl-[10px] hover:bg-gray-200"
      onClick={() => {
        handleHourClick(hour);
      }}
    >
      <input
        type="radio"
        className="h-[20px] w-[20px] cursor-pointer"
        checked={hourSelection === hour}
      />
      <div className="ml-[20px] text-sm text-gray-800">
        {humanizeHour(hour[0])} - {humanizeHour(hour[1])}
      </div>
    </div>
  );
}

function HoursDisplay({
  availability,
  dateSelection,
  hourSelection,
  handleHourClick,
}) {
  return (
    <div className="max-h-[200px] overflow-scroll pb-[20px]">
      {availability[dateSelection]?.map((item, i) => (
        <HourCell
          key={i}
          hour={item}
          hourSelection={hourSelection}
          handleHourClick={handleHourClick}
        />
      ))}
    </div>
  );
}

function DisplayAvailability({
  appointmentId,
  callback,
  client,
  setLoading,
  packsIds,
  servicesIds,
  setStep,
  setError
}) {
  const [availability, setAvailability] = useState(null);
  const [loadingAvailability, setLoadingAvailability] = useState(true);
  const [dateSelection, setDateSelection] = useState(false);
  const [hourSelection, setHourSelection] = useState(false);

  const fetchAvailableHours = async () => {
    setLoadingAvailability(true);
    setLoading(true);
    const res = await fetchAvailability(
      appointmentId,
      servicesIds,
      packsIds,
      countryCode(client)
    );
    if (res) {
      if (Object.keys(res.block_based_availability).length < 1) {
        setStep("error");
        setError("No hay horarios disponibles para estos servicios");
      }
      const rb = res.block_based_availability;
      setAvailability(rb);
      const keys = Object.keys(rb);
      const values = Object.values(rb);
      setDateSelection(keys[0]);
      setHourSelection(values[0][0]);
      callback(`${keys[0]} ${values[0][0][0]}`, `${keys[0]} ${values[0][0][1]}`);
    } else {
      setStep("error");
      setError("Hubo un error al cargar la disponibilidad");
    }
    setLoadingAvailability(false);
    setLoading(false);
  };
  useEffect(() => {
    fetchAvailableHours();
  }, []);

  /*
  submit payload
  {
    begin_date: "yyyy-mm-dd hh:mm:ss",
    end_date: "yyyy-mm-dd hh:mm:ss"
  }
  */

  return (
    <div>
      {loadingAvailability ? (
        <div className="flex h-[100px] w-full items-center justify-center">
          <LoadingSpinnerIcon />
        </div>
      ) : (
        <>
          <DatesDisplay
            days={Object.keys(availability)}
            dateSelection={dateSelection}
            handleDateClick={(newDate) => {
              setDateSelection(newDate);
            }}
          />
          {availability && dateSelection && (
            <HoursDisplay
              availability={availability}
              dateSelection={dateSelection}
              hourSelection={hourSelection}
              handleHourClick={(newHour) => {
                setHourSelection(newHour);
                callback(
                  `${dateSelection} ${newHour[0]}`,
                  `${dateSelection} ${newHour[1]}`
                );
              }}
            />
          )}
        </>
      )}
    </div>
  );
}

export default DisplayAvailability;
