import client from "../client";

class NurseService {
  fetchHeatMap = (comuna = "all", serviceTypeValue = "all", labsIds = []) => {
    const comunaAsParam = `comunas[]=${comuna}`;
    const labsAsParam = labsIds.map((id) => `labs_ids[]=${id}`).join("&");
    return client.get(
      `nurse-blocktables/timeblocks/nurse_availabilities_heatmap_by_lab/?range=14&${comunaAsParam}&service_type=${serviceTypeValue}&${labsAsParam}`
    );
  };
}

const nurseService = new NurseService();

export default nurseService;
