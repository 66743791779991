import {
  doc,
  updateDoc,
  addDoc,
  getFirestore,
  onSnapshot,
  query,
  where,
  getDocs,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { collection } from "firebase/firestore";
import { action, makeObservable, observable } from "mobx";

const firebaseConfig = {
  apiKey: "AIzaSyC65j06OhvHIvEYTh428btH0sHnMWZkVdE",
  authDomain: "examedi-as-a-service.firebaseapp.com",
  projectId: "examedi-as-a-service",
  storageBucket: "examedi-as-a-service.appspot.com",
  messagingSenderId: "38349204066",
  appId: "1:38349204066:web:5ae07262ac08eb2a1a7f3b",
  measurementId: "G-3XKX2ZMB7J",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

class Model {
  attributes = new Map();
  parent_collection_path = [];

  constructor(attributes, parent_collection_path) {
    this.set(attributes);
    this.id = attributes.id;
    this.parent_collection_path = parent_collection_path;
    makeObservable(this, {
      attributes: observable,
    });
  }

  path() {
    console.log("path is: ", this.parent_collection_path);
    return [...this.parent_collection_path, this.id];
  }

  firestore() {
    return doc(db, ...this.path());
  }

  createInSubcollection(subCollection, obj) {
    addDoc(collection(db, ...this.path(), subCollection), obj);
  }

  delete() {
    deleteDoc(this.firestore());
  }

  async update(updateObj) {
    await updateDoc(this.firestore(), updateObj);
  }

  set(attributes) {
    Object.keys(attributes).forEach((attr_name) => {
      this.attributes.set(attr_name, attributes[attr_name]);
    });
  }

  save() {
    return this.firestore.set(Object.fromEntries(this.attributes));
  }

  get(attr_name) {
    return this.attributes.get(attr_name);
  }
}
class Collection {
  models = new Array();
  parsed_models = new Array();
  models_id_map = new Object();

  constructor() {
    makeObservable(this, {
      models: observable,
      set: action,
    });
  }

  firestore() {
    return collection(db, ...this.path());
  }

  filterModels(key, value) {
    return this.models.filter((doc) => doc.get(key) === value);
  }

  partialFilter(key, value) {
    return this.models.filter((doc) =>
      doc.get(key).toLowerCase().includes(value)
    );
  }

  set(array_of_firestore_objects) {
    array_of_firestore_objects.forEach((model) => {
      if (model) {
        let dataObj = { ...model.data(), id: model.id };
        if (!this.models_id_map[model.id]) {
          let modelObj = this.baseModel(dataObj);
          this.models_id_map[model.id] = modelObj;
          this.models.unshift(modelObj);
        } else {
          //this.models_id_map[model.id] = modelObj;
          this.get(model.id).set(dataObj);
        }
      }
    });
  }

  get(model_id) {
    return this.models_id_map[model_id] || false;
  }

  sync() {
    onSnapshot(this.snapshotQuery(), (col) => {
      if (col.docChanges().length !== 0) {
        this.set(col);
      }
    });
  }

  async filter(fieldPath, opStr, value) {
    const q = query(this.firestore(), where(fieldPath, opStr, value));
    const querySnapshot = await getDocs(q);
    let results = [];
    querySnapshot.forEach((doc) => {
      let dataObj = { ...doc.data(), id: doc.id };
      let modelObj = this.baseModel(dataObj);
      results.push(modelObj);
    });
    return results;
  }

  async create(obj) {
    return await addDoc(collection(db, ...this.path()), obj);
  }
}

// ADMINISTRATORS
class Administrator extends Model {
  constructor(params, parent_collection_path) {
    super(params);
    this.parent_collection_path = parent_collection_path;
  }
}

class Administrators extends Collection {
  clientId = "";
  constructor(params) {
    super(params);
    this.clientId = params.clientId;
  }

  baseModel(obj) {
    return new Administrator(obj, this.path());
  }

  path() {
    return ["client", this.clientId, "administrators"];
  }

  snapshotQuery() {
    // filter this with user uid as shared filter parameter
    //const q = query(this.firestore(), orderBy("created_at", "asc"))
    return this.firestore();
  }
}

// PAYMENTS

class Payment extends Model {
  constructor(params) {
    super(params);
  }
}

class Payments extends Collection {
  clientId = "";
  constructor(params) {
    super(params);
    this.clientId = params.clientId;
  }
  baseModel(obj) {
    return new Payment(obj);
  }

  path() {
    return ["client", this.clientId, "payments"];
  }

  snapshotQuery() {
    const q = query(this.firestore(), orderBy("created_at", "asc"));
    return q;
  }
}

// PATIENTS
class Patient extends Model {
  constructor(params, parent_collection_path){
    super(params);
    this.parent_collection_path = parent_collection_path;
  }
}

class Patients extends Collection {
  clientId = "";
  constructor(params) {
    super(params);
    this.clientId = params.clientId;
  }

  baseModel(obj){
    return new Patient(obj, this.path());
  }

  path() {
    return ["client", this.clientId, "patients"];
  }

  snapshotQuery() {
    // filter this with user uid as shared filter parameter
    //const q = query(this.firestore(), orderBy("created_at", "asc"))
    return this.firestore();
  }
}

// APPOINTMENTS

export class Appointment extends Model {
  constructor(params, parent_collection_path, clientId) {
    super(params);
    this.parent_collection_path = parent_collection_path;
    this.results = new Results({ clientId: clientId });
    this.results.sync();
  }
}

export class Appointments extends Collection {
  clientId = "";
  constructor(params) {
    super(params);
    this.clientId = params.clientId;
  }

  baseModel(obj) {
    return new Appointment(obj, this.path(), this.clientId);
  }

  path() {
    return ["client", this.clientId, "appointments"];
  }

  snapshotQuery() {
    // filter this with user uid as shared filter parameter
    return query(this.firestore(), orderBy("created_at", "asc"));
  }
}

// RESULT FILES

class File extends Model {
  constructor(params) {
    super(params);
  }
}

class Files extends Collection {
  clientId = "";
  resultId = "";
  constructor(params) {
    super(params);
    this.clientId = params.clientId;
    this.resultId = params.resultId;
  }

  baseModel(obj) {
    return new File(obj);
  }

  path() {
    return ["client", this.clientId, "results", this.resultId, "files"];
  }

  snapshotQuery() {
    return this.firestore();
  }
}

// RESULTS
class Result extends Model {
  constructor(params, parent_collection_path, clientId) {
    super(params, parent_collection_path, clientId);
    this.parent_collection_path = parent_collection_path;
    this.files = new Files({ clientId: clientId, resultId: this.id });
    this.files.sync();
  }
}

class Results extends Collection {
  clientId = "";
  constructor(params) {
    super(params);
    this.clientId = params.clientId;
  }

  baseModel(obj) {
    return new Result(obj, this.path(), this.clientId);
  }

  path() {
    return ["client", this.clientId, "results"];
  }

  snapshotQuery() {
    // filter this with user uid as shared filter parameter
    const q = query(this.firestore(), orderBy("created_at", "asc"));
    return q;
  }
}

// CLIENTS / CUSTOMERS
export class Client extends Model {
  constructor(params, parent_collection_path) {
    super(params, parent_collection_path);
    this.parent_collection_path = parent_collection_path;
    this.administrators = new Administrators({ clientId: this.id });
    this.administrators.sync();

    this.patients = new Patients({ clientId: this.id });
    this.patients.sync();

    this.results = new Results({ clientId: this.id });
    this.results.sync();

    this.appointments = new Appointments({ clientId: this.id });
    this.appointments.sync();

    this.payments = new Payments({ clientId: this.id });
    this.payments.sync();
  }
}

export class Clients extends Collection {
  constructor(params) {
    super(params);
  }

  baseModel(obj) {
    return new Client(obj, this.path());
  }

  path() {
    return ["client"];
  }

  snapshotQuery() {
    // filter this with user uid as shared filter parameter
    //const q = query(this.firestore(), orderBy("created_at", "asc"))
    return this.firestore();
  }
}

// USERS
class User extends Model {
  constructor(params, parent_collection_path) {
    super(params, parent_collection_path);
  }
}

export class Users extends Collection {
  constructor(params) {
    super(params);
  }

  baseModel(obj) {
    return new User(obj, this.path());
  }

  path() {
    return ["users"];
  }

  snapshotQuery() {
    // filter this with user uid as shared filter parameter
    //const q = query(this.firestore(), orderBy("created_at", "asc"))
    return this.firestore();
  }
}
