import React from "react";

import clsx from "clsx";
import { observer } from "mobx-react";
import moment from "moment";

import PinIcon from "../../Icons/PinIcon";
import CartIcon from "../../Icons/CartIcon";
import CalendarIcon from "../../Icons/CalendarIcon";
import ClockIcon from "../../Icons/ClockIcon";
import UserIcon from "../../Icons/UserIcon";

const SuccessStep = observer(({ appointment, appointmentData }) => {
  const sendCloseEvent = () => {
    window.parent.postMessage({ event: "close" }, "*");
  };

  return (
    <div className="w-full min-w-[275px]">
      <div
        className={clsx(
          "w-full",
          "flex flex-col items-start justify-center",
          "gap-y-4",
          "text-examedi-black-dark"
        )}
      >
        <div className="text-3xl font-medium">Tu cita está agendada</div>
        <div className="text-xl">
          Te enviaremos un mail con el resumen de tu cita
        </div>
        <div
          className={clsx(
            "w-full",
            "bg-examedi-white-light",
            "rounded-xl",
            "flex flex-col items-start justify-center",
            "gap-y-2",
            "py-6 px-4",
            "text-sm"
          )}
        >
          {appointment && (
            <>
              <div className="flex w-full items-start">
                <UserIcon
                  className={clsx("stroke-examedi-blue-strong", "w-4", "mr-2")}
                />
                <div className="font-bold">Paciente&nbsp;</div>
                <div>{appointmentData.patient_full_name}</div>
              </div>
              <div className="flex w-full items-start">
                <PinIcon
                  className={clsx("stroke-examedi-blue-strong", "w-8", "mr-2")}
                />
                <div className="font-bold">Dirección&nbsp;</div>
                <div>{appointmentData.target_address}</div>
              </div>
              <div className="flex w-full items-center">
                <CalendarIcon
                  className={clsx(
                    "stroke-examedi-blue-strong",
                    "h-4 w-4",
                    "mr-2"
                  )}
                />
                <div className="font-bold">Fecha&nbsp;</div>
                <div className="text-base capitalize">
                  {moment(appointment.begin_date).locale("es").format("dddd D")}
                  &nbsp;
                  <span className="lowercase">
                    de{" "}
                    {moment(appointment.begin_date).locale("es").format("MMMM")}
                  </span>
                </div>
              </div>
              <div className="flex w-full items-start">
                <ClockIcon
                  className={clsx(
                    "stroke-examedi-blue-strong",
                    "h-4 w-4",
                    "mr-2"
                  )}
                />
                <div className="font-bold">Bloque horario&nbsp;</div>
                <div>
                  {appointment &&
                    moment(appointment?.get("begin_date")).format("HH:mm")}
                  hrs - {moment(appointment?.get("end_date")).format("HH:mm")}
                  hrs.
                </div>
              </div>
              <div className="flex w-full items-start">
                <CartIcon
                  className={clsx(
                    "stroke-examedi-blue-strong",
                    "h-4 w-4",
                    "mr-2"
                  )}
                />
                <div className="flex w-full flex-col items-start">
                  <div className="font-bold">Servicios&nbsp;</div>
                  <ul className="list-disc pl-4">
                    {appointmentData &&
                      appointmentData.services.map((service) => (
                        <li>{service.name}</li>
                      ))}
                  </ul>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex w-full items-center justify-center">
          <button
            className={clsx(
              "rounded-full",
              "text-base font-medium text-white",
              "py-[10px] px-[20px]",
              "drop-shadow-examedi-button",
              "bg-examedi-blue-strong transition-colors duration-150 hover:bg-examedi-blue-strong-75",
              "w-60"
            )}
            onClick={sendCloseEvent}
            type="button"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
});

export default SuccessStep;
