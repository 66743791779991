import React from "react";
import "./App.css";
import "normalize.css";

import MainRouter from "./Router/MainRouter";

function App() {
  return (
    <div id="myApp">
      <MainRouter />
    </div>
  );
}

export default App;
