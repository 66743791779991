import React from "react";

function Logo(props) {
  return (
    <svg
      width="105"
      height="17"
      viewBox="0 0 105 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_0_3)">
        <path
          d="M27.6277 17.0012C26.4412 17.0012 25.4013 16.7366 24.5082 16.2074C23.6392 15.7045 22.9151 14.9525 22.4152 14.0338C21.9157 13.0663 21.6628 11.9726 21.6824 10.8647C21.6824 9.62827 21.9198 8.54184 22.3946 7.60544C22.8594 6.68012 23.5445 5.90613 24.379 5.36374C25.2697 4.80996 26.2828 4.52871 27.31 4.55016C28.2481 4.53371 29.1709 4.80878 29.9688 5.34272C30.7529 5.84246 31.3911 6.57091 31.8104 7.44469C32.2577 8.33494 32.4673 9.35337 32.4391 10.5L32.4173 11.4743H24.4659C24.5904 12.2839 24.9945 13.0123 25.5964 13.512C26.2099 14.0066 26.9842 14.2538 27.9192 14.2538C28.3831 14.2614 28.8445 14.1776 29.2807 14.0066C29.7698 13.7955 30.2227 13.4971 30.6204 13.1237L32.0013 15.2059C31.3922 15.7853 30.6896 16.2384 29.9288 16.5425C29.1925 16.8397 28.4136 16.995 27.6277 17.0012V17.0012ZM27.334 7.22213C25.7572 7.22213 24.8081 7.99162 24.4865 9.53059H29.7196V9.44033C29.7056 9.13321 29.6311 8.83287 29.5009 8.55948C29.3708 8.28608 29.1881 8.04595 28.9652 7.8552C28.5141 7.43025 27.9319 7.20428 27.334 7.22213V7.22213Z"
          fill="#133963"
        />
        <path
          d="M32.838 16.7749L36.9829 10.7064L32.9009 4.82213H36.3816L38.8108 8.48946L40.7986 4.82213H44.1479L40.3174 10.7089L44.4417 16.7762H41.0249L38.4907 12.9518L36.2045 16.7774H32.838V16.7749Z"
          fill="#133963"
        />
        <path
          d="M49.1844 17.0012C48.2966 17.012 47.4271 16.7275 46.6936 16.1864C45.9372 15.6206 45.3254 14.8571 44.9149 13.967C44.47 12.9731 44.248 11.8797 44.2668 10.7757C44.2668 9.55159 44.4828 8.47999 44.9149 7.5609C45.325 6.66963 45.9463 5.91191 46.7153 5.36496C47.491 4.83446 48.3925 4.55911 49.3102 4.57239C50.0258 4.55338 50.7341 4.73257 51.3677 5.09294C51.9273 5.40028 52.4089 5.8511 52.7703 6.40605V4.82215H55.6167V16.7749H52.7383V15.1676C52.3214 15.7183 51.8003 16.1661 51.21 16.4807C50.5843 16.8307 49.8888 17.0094 49.1844 17.0012V17.0012ZM49.956 14.3119C50.8065 14.3119 51.4973 13.9872 52.0285 13.3376C52.5589 12.6897 52.8286 11.8353 52.8286 10.7806C52.8286 9.72593 52.5634 8.8703 52.0285 8.2224C51.4935 7.5745 50.8065 7.24807 49.956 7.24807C49.5676 7.23444 49.1813 7.31485 48.8253 7.48342C48.4693 7.65199 48.1526 7.90447 47.8984 8.2224C47.384 8.867 47.1268 9.7181 47.1268 10.7757C47.1268 11.8333 47.3848 12.6856 47.9007 13.3327C48.1542 13.6512 48.4703 13.9045 48.8259 14.0739C49.1815 14.2434 49.5676 14.3247 49.956 14.3119V14.3119Z"
          fill="#133963"
        />
        <path
          d="M57.9395 16.775V4.82221H60.7035L60.7458 6.51986C61.1354 5.94764 61.6375 5.47595 62.2158 5.13874C62.7744 4.78471 63.4099 4.5974 64.0574 4.59594C64.853 4.59594 65.516 4.7847 66.0464 5.16223C66.5752 5.5241 67.0077 6.10523 67.3438 6.90563C67.7495 6.21203 68.3079 5.63809 68.9691 5.23522C69.6302 4.83235 70.3734 4.61313 71.132 4.59717C73.6568 4.59717 74.9195 6.24248 74.9203 9.5331V16.7775H72.0465V9.75689C72.0465 8.09592 71.4186 7.26585 70.1627 7.26667C69.5434 7.24587 68.9416 7.49026 68.488 7.94672C68.2762 8.16487 68.109 8.42869 67.9974 8.72113C67.8857 9.01356 67.8321 9.3281 67.8399 9.64438V16.7762H64.9707V9.71115C64.9707 8.92723 64.8107 8.32343 64.4906 7.89974C64.1705 7.47605 63.7106 7.2642 63.1109 7.2642C62.4985 7.24587 61.9043 7.49034 61.4579 7.94425C61.249 8.15515 61.0833 8.41117 60.9716 8.69574C60.86 8.9803 60.8048 9.28706 60.8098 9.59615V16.7676L57.9395 16.775Z"
          fill="#133963"
        />
        <path
          d="M82.3551 17.0012C81.1685 17.0012 80.1287 16.7366 79.2355 16.2074C78.3665 15.7045 77.6424 14.9525 77.1425 14.0337C76.6422 13.0666 76.3889 11.9728 76.4086 10.8647C76.4086 9.62826 76.646 8.54183 77.1208 7.60542C77.5868 6.67988 78.2731 5.90593 79.1087 5.36373C79.9989 4.8101 81.0117 4.52886 82.0384 4.55014C82.9766 4.53369 83.8994 4.80877 84.6973 5.34271C85.4817 5.84213 86.1199 6.57066 86.5388 7.44468C86.9862 8.33492 87.1957 9.35335 87.1676 10.5L87.1458 11.4743H79.191C79.3154 12.2839 79.7195 13.0122 80.3215 13.512C80.935 14.0065 81.7092 14.2538 82.6443 14.2538C83.1082 14.2614 83.5695 14.1776 84.0057 14.0065C84.4948 13.7955 84.9477 13.497 85.3454 13.1237L86.7263 15.2059C86.1172 15.7853 85.4147 16.2384 84.6539 16.5425C83.9184 16.8402 83.1403 16.9955 82.3551 17.0012V17.0012ZM82.0624 7.22212C80.4865 7.22212 79.5377 7.9916 79.2161 9.53057H84.4492V9.44032C84.4347 9.13344 84.3601 8.83341 84.2302 8.56012C84.1003 8.28684 83.9182 8.04654 83.6959 7.85518C83.2435 7.43123 82.6609 7.20545 82.0624 7.22212V7.22212Z"
          fill="#133963"
        />
        <path
          d="M93.1346 17.0011C92.2096 17.0171 91.3004 16.7413 90.518 16.2073C89.7412 15.6541 89.1131 14.8889 88.697 13.9891C88.2522 12.9948 88.0301 11.9011 88.0489 10.7966C88.0489 9.58984 88.265 8.51825 88.697 7.58184C89.1103 6.68598 89.7394 5.92734 90.518 5.3859C91.1856 4.92841 91.9465 4.65424 92.7344 4.58722C93.5223 4.52021 94.3134 4.66237 95.039 5.00136C95.603 5.25145 96.1101 5.63115 96.525 6.11417V0.0221643H99.3931V16.7749H96.6725L96.6313 15.348C96.2223 15.8542 95.7149 16.2562 95.1453 16.5251C94.5159 16.8428 93.8291 17.0053 93.1346 17.0011V17.0011ZM93.7633 14.4194C94.1557 14.4326 94.5457 14.3499 94.9045 14.1776C95.2633 14.0053 95.5816 13.7477 95.8357 13.4241C96.3654 12.744 96.6321 11.8686 96.6359 10.7979C96.6359 9.71142 96.3692 8.83643 95.8357 8.17287C95.5774 7.85295 95.2587 7.59654 94.9011 7.42085C94.5436 7.24517 94.1554 7.15427 93.7627 7.15427C93.37 7.15427 92.9818 7.24517 92.6243 7.42085C92.2667 7.59654 91.948 7.85295 91.6897 8.17287C91.1608 8.83725 90.8941 9.71225 90.8895 10.7979C90.8895 11.8694 91.1562 12.7449 91.6897 13.4241C92.2201 14.0893 92.912 14.4211 93.7656 14.4194H93.7633Z"
          fill="#133963"
        />
        <path
          d="M101.979 16.7749V4.82215H104.847V16.7749H101.979ZM104.999 1.71C104.999 2.04791 104.906 2.37817 104.732 2.65901C104.558 2.93985 104.311 3.15868 104.023 3.28782C103.734 3.41697 103.417 3.45063 103.11 3.38456C102.804 3.31848 102.522 3.15564 102.302 2.91662C102.081 2.67759 101.93 2.37311 101.87 2.04168C101.809 1.71024 101.84 1.36673 101.96 1.05456C102.079 0.742393 102.282 0.47559 102.542 0.287881C102.801 0.100173 103.107 -1.42167e-05 103.419 -1.43051e-05C103.627 -1.43639e-05 103.832 0.0442263 104.024 0.130178C104.216 0.216129 104.39 0.342108 104.537 0.500914C104.683 0.65972 104.8 0.848238 104.879 1.0557C104.958 1.26316 104.999 1.4855 104.999 1.71V1.71Z"
          fill="#133963"
        />
        <path
          d="M14.9109 9.48971C14.5745 9.4908 14.2493 9.35888 13.9964 9.11877L8.18831 3.59801L2.29103 9.11877C2.15374 9.24966 1.99384 9.34982 1.82055 9.41349C1.64727 9.47715 1.46404 9.50305 1.28145 9.48969C1.09887 9.47633 0.920537 9.42397 0.756761 9.33565C0.592986 9.24732 0.44701 9.12477 0.327266 8.97507C0.207523 8.82538 0.116385 8.65151 0.0591136 8.46351C0.00184256 8.2755 -0.0204277 8.07708 -0.00640706 7.87971C0.00761361 7.68235 0.0576467 7.48994 0.1408 7.31361C0.223953 7.13727 0.338582 6.9805 0.478063 6.85235L7.28983 0.473488C7.54272 0.236146 7.86657 0.105988 8.20139 0.107125C8.5362 0.108262 8.85929 0.240618 9.1108 0.479672L15.8265 6.86348C16.0379 7.0646 16.1876 7.3306 16.2557 7.62605C16.3239 7.9215 16.3071 8.23238 16.2078 8.5173C16.1085 8.80222 15.9313 9.04766 15.6999 9.22094C15.4684 9.39421 15.1936 9.4871 14.9121 9.48724L14.9109 9.48971Z"
          fill="#133963"
        />
        <path
          d="M10.688 11.6548H9.40433V10.2625C9.39472 9.92627 9.26447 9.60727 9.04118 9.37309C8.81788 9.13892 8.51908 9.00796 8.20806 9.00796C7.89705 9.00796 7.59825 9.13892 7.37496 9.37309C7.15167 9.60727 7.02142 9.92627 7.01181 10.2625V11.6548H5.72238C5.56217 11.6494 5.40261 11.6789 5.25315 11.7415C5.10368 11.8041 4.96737 11.8986 4.85229 12.0193C4.73722 12.14 4.64572 12.2844 4.58324 12.4441C4.52076 12.6037 4.48857 12.7753 4.48857 12.9487C4.48857 13.1221 4.52076 13.2937 4.58324 13.4534C4.64572 13.613 4.73722 13.7575 4.85229 13.8782C4.96737 13.9988 5.10368 14.0933 5.25315 14.1559C5.40261 14.2185 5.56217 14.248 5.72238 14.2427H7.00381V15.6349C7.01342 15.9712 7.14366 16.2902 7.36696 16.5243C7.59025 16.7585 7.88905 16.8895 8.20007 16.8895C8.51108 16.8895 8.80988 16.7585 9.03317 16.5243C9.25647 16.2902 9.38671 15.9712 9.39633 15.6349V14.2427H10.6835C10.9943 14.2323 11.2892 14.0914 11.5057 13.8499C11.7222 13.6083 11.8433 13.2851 11.8433 12.9487C11.8433 12.6123 11.7222 12.2891 11.5057 12.0476C11.2892 11.8061 10.9943 11.6652 10.6835 11.6548H10.688Z"
          fill="#008CFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_3">
          <rect width="105" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Logo;
