import React from "react";

import clsx from "clsx";
import { observer } from "mobx-react";

import { useStore } from "../../store";
import { vanillaStore, vanillaPersistedStore } from "../../vanillaStore";

const ClientSelector = observer(({ clients }) => {
  const client = useStore((state) => state.client);
  const userData = vanillaPersistedStore.getState().getUserData();

  const sortedClients = clients?.models
    .slice()
    .sort((client, anotherClient) => {
      const clientMode = client.get("mode");
      const anotherClientMode = anotherClient.get("mode");
      const clientDisplayName = client.get("display_name");
      const anotherClientDisplayName = anotherClient.get("display_name");
      if (clientMode > anotherClientMode) return 1;
      if (clientMode < anotherClientMode) return -1;
      if (clientDisplayName > anotherClientDisplayName) return 1;
      if (clientDisplayName < anotherClientDisplayName) return -1;
      return 0;
    });

  return (
    userData?.email?.endsWith("@examedi.com") && (
      <div className={clsx("min-h-0", "pl-[20px]", "flex flex-col", "gap-y-3")}>
        <div className="text-xs font-bold">Admin Client Selector</div>
        <div className={clsx("overflow-y-auto")}>
          {sortedClients?.map((item, i) => (
            <div
              key={i}
              onClick={() => {
                vanillaStore.getState().setClient(item);
              }}
              className={clsx(
                "rounded-lg",
                "mb-2",
                "text-left",
                "cursor-pointer bg-gray-100 py-[2px] px-[5px] text-sm",
                item === client ? "bg-blue-500 text-white" : "hover:bg-gray-200"
              )}
            >
              {item.get("mode") === "production" ? (
                <span className="text-xs font-bold text-green-400">PROD</span>
              ) : (
                <span className="text-xs font-bold text-red-400">DEV</span>
              )}
              &nbsp;
              {item.get("display_name")}
            </div>
          ))}
        </div>
      </div>
    )
  );
});

export default ClientSelector;
