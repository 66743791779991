import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import clsx from "clsx";

import { navs } from "../Router/navs";
import Logo from "../Icons/Logo";
import TopBar from "./TopBar";
import { useStore } from "../store";
import { vanillaPersistedStore, vanillaStore } from "../vanillaStore";
import ClientSelector from "../Pages/Administration/ClientSelector";

const MainLayout = observer(({ children, users, clients }) => {
  const navigate = useNavigate();
  const user = useStore((state) => state.user);
  const userData = vanillaPersistedStore.getState().getUserData();
  const [collapsed, setCollapsed] = useState(false);
  const rehydrated = useStore((state) => state.rehydrated);

  const myUser = users.filterModels("uid", user.uid)[0];
  const client = useStore((state) => state.client);

  function NavLink({ item }) {
    return (
      <Link to={item.path}>
        <div
          className={clsx(
            "text-sm",
            "py-[10px] px-[20px]",
            "hover:bg-gray-200",
            "flex items-center",
            "gap-x-2"
          )}
        >
          <div className="h-5 w-5">{item.icon}</div>
          {!collapsed && (
            <span className="text-sm font-[500]">{item.name}</span>
          )}
        </div>
      </Link>
    );
  }

  useEffect(() => {
    if (rehydrated) {
      if (!user) {
        navigate("/");
      }
    }
    if (!rehydrated) {
      if (user === 0) {
        navigate("/");
      }
    }
  }, [user.uid, client?.id]);

  return (
    <div className="flex bg-gray-100">
      <div
        className={clsx(
          "h-screen",
          "flex flex-col",
          "gap-y-10",
          "border-r-[1px]",
          "transition-all duration-200",
          collapsed ? "w-16" : "w-52"
        )}
      >
        <div
          className={clsx(
            "h-[60px] min-h-[60px]",
            "flex items-center justify-center"
          )}
        >
          <Logo
            className={clsx(
              collapsed && "opacity-0",
              "transition-opacity",
              "duration-200"
            )}
          />
        </div>
        <div className={clsx("flex flex-col", "gap-y-3", "overflow-y-auto")}>
          {navs.map((item, i) =>
            item.examediExclusive ? (
              userData?.email?.endsWith("@examedi.com") && (
                <NavLink item={item} key={i} />
              )
            ) : (
              <NavLink item={item} key={i} />
            )
          )}
          {!collapsed && <ClientSelector clients={clients} />}
        </div>
      </div>
      <div
        className={clsx("h-screen w-full", "flex flex-col", "overflow-y-auto")}
      >
        {client && (
          <TopBar
            clients={clients}
            client={client}
            user={myUser}
            collapse={() => {
              setCollapsed(!collapsed);
            }}
            isCollapsed={collapsed}
            className="h-[60px]"
          />
        )}
        <div className={clsx("min-h-0", "p-10", "overflow-y-auto")}>
          {children}
        </div>
      </div>
    </div>
  );
});

export default MainLayout;
