import qs from "qs";
import client from "../client";
import { LAST_MILE_SALES_SOURCES } from "../../constants/lastMileSalesSources";

export async function getServices({
  commune,
  source,
  country,
  query,
  labsIds,
}) {
  try {
    const queryLabsIds = labsIds
      ? `&${labsIds.map((id) => `labs_ids[]=${id}`).join("&")}`
      : "";

    const salesSource = LAST_MILE_SALES_SOURCES.includes(source)
      ? source
      : "marketplace";

    const res = await client.get(
      `lab-services/available_services/?${qs.stringify(
        {
          qs: query || null,
          comuna: commune || null,
          source: salesSource,
          country: country || null,
        },
        { skipNulls: true, addQueryPrefix: false }
      )}${queryLabsIds}`
    );

    return res.data?.data;
  } catch (err) {
    return undefined;
  }
}

export default { getServices };
