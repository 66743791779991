import React, { useEffect, useState } from "react";

import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import BaseCard from "../../Components/BaseCard";
import Header from "../../Components/Shared/Header";
import Td from "../../Components/Table/Td";
import Th from "../../Components/Table/Th";
import Tr from "../../Components/Table/Tr";
import LoadingSpinnerIcon from "../../Icons/LoadingSpinner";
import { useStore } from "../../store";
import { getAppointmentsByClient } from "../../api-client/examedi/appointments";
import { humanizeDate } from "../../utils/inputs";

const ResultsTable = observer(({ appointments }) => (
  <BaseCard className="mt-[20px] w-full">
    <table className="w-full">
      <thead>
        <Tr>
          {[
            "ID",
            "Fecha de solicitud",
            "Paciente",
            "Correo",
            "Exámenes",
            "Status",
          ].map((element, i) => (
            <Th key={i}>{element}</Th>
          ))}
        </Tr>
      </thead>
      <tbody className="divide-y">
        {appointments.map((element, i) => (
          <Tr className="hover:cursor-pointer hover:bg-gray-100" key={i}>
            {[
              <Link
                to={`/app/appointments/${element?.id}`}
                className="text-blue-700 hover:underline"
              >
                Ver Cita
              </Link>,
              `${humanizeDate(element?.local_begin_date)}`,
              <Link
                to={`/app/patients/${element?.patient?.id}}`}
                className="text-blue-700 hover:underline"
              >
                {element?.patient?.fullname}
              </Link>,
              `${element?.patient?.email || ""}`,
              `${element?.service_names.slice(0, 30)}...`,

              element?.timeline_events.some((e) => e.tag === "results-sent") ? (
                <div className="inline rounded-sm bg-green-600 px-[5px] py-[2px] text-white">
                  Disponible
                </div>
              ) : (
                <div className="inline rounded-sm bg-orange-600 px-[5px] py-[2px] text-white">
                  En análisis
                </div>
              ),
            ].map((element, i) => (
              <Td key={i}>{element}</Td>
            ))}
          </Tr>
        ))}
      </tbody>
    </table>
  </BaseCard>
));

function Results() {
  const client = useStore((state) => state.client);
  const [isLoading, setIsLoading] = useState(true);
  const [appointments, setAppointments] = useState(false);

  const fetchAppointmentsData = async () => {
    const res = await getAppointmentsByClient(
      client?.get("sales_source")
    );
    setAppointments(res);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAppointmentsData();
  }, [client]);

  const renderResults = () => {
    if (isLoading) {
      return (
        <div className="mt-[20px] flex h-[300px] w-full items-center justify-center bg-gray-200">
          <LoadingSpinnerIcon size={44} />
        </div>
      );
    }
    if (appointments.length > 0) {
      return (
        <ResultsTable
          results={client?.results.models}
          patients={client?.patients}
          appointments={appointments}
        />
      );
    }
    return (
      <div className="mt-[20px] flex h-[300px] w-full items-center justify-center rounded-sm bg-gray-200 text-gray-500">
        Todavía no hay resultados en proceso 🥺
      </div>
    );
  };

  return (
    <>
      <Header>Resultados de Exámenes</Header>
      {renderResults()}
    </>
  );
}

export default Results;
