import React from "react";

import clsx from "clsx";

function LoadingSpinnerIcon({
  className,
  containerClassName,
  mainLineClassName,
  backgroundLineClassName,
}) {
  return (
    <div
      className={clsx("w-full", "flex items-center justify-center", className)}
    >
      <div
        className={clsx(
          "relative",
          "h-5 md:h-6",
          "w-5 md:w-6",
          containerClassName
        )}
      >
        <div
          className={clsx(
            "absolute top-0 left-0",
            "animate-spin",
            "rounded-full",
            "h-5 md:h-6",
            "w-5 md:w-6",
            "z-10",
            "border-4",
            "border-t-transparent",
            "border-white/80",
            mainLineClassName
          )}
        />
        <div
          className={clsx(
            "absolute top-0 left-0",
            "h-5 md:h-6",
            "w-5 md:w-6",
            "rounded-full",
            "z-0",
            "border-4 border-examedi-gray-normal/60",
            "opacity-95",
            backgroundLineClassName
          )}
        />
      </div>
    </div>
  );
}

export default LoadingSpinnerIcon;
