import React, { useState, useMemo, useEffect } from "react";

import clsx from "clsx";
import { observer } from "mobx-react";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "react-modal";

import EditPatient from "./EditPatient";
import BaseCard from "../../Components/BaseCard";
import Header from "../../Components/Shared/Header";
import LoadingSpinnerIcon from "../../Icons/LoadingSpinner";
import { useStore } from "../../store";
import { getAge, formatBirthday } from "../../utils/patients-age";
import TrashIcon from "../../Icons/TrashIcon";

import { getPatient } from "../../api-client/examedi/patients";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Profile = observer(({ users, clients }) => {
  const params = useParams();
  const navigate = useNavigate();
  const client = useStore((state) => state.client);
  const patients = client?.patients;
  const firebasePatient = patients?.models.find((patient) => patient.get("examedi_patient_id") === params.id);
  const [patient, setPatient] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [modalPatient, setModalPatient] = useState(null);

  useMemo(() => {
    navigate("/app/patients");
  }, [client]);

  useEffect(() => {
    const fetchPatient = async () => {
      const patient = await getPatient(params.id);
      setPatient(patient);
    };
    fetchPatient();
  }, [params.id]);

  return !patients?.models?.length > 0 ? (
    <LoadingSpinnerIcon />
  ) : (
    <>
      <Modal isOpen={isEditModalOpen && !!modalPatient} style={customStyles}>
        <EditPatient
          client={client}
          close={() => {
            setIsEditModalOpen(false);
          }}
          patient={modalPatient}
          setEditedPatient={setPatient}
        />
      </Modal>
      <Modal isOpen={isDeleteModalOpen} style={customStyles}>
        <div className="grid w-[550px] gap-4 font-scandia">
          <div className="flex justify-between text-xl font-medium">
            Eliminar paciente
            <button
              type="button"
              className="hover:bg-gray-100"
              onClick={() => {
                setIsDeleteModalOpen(false);
              }}
            >
              X
            </button>
          </div>

          <hr />
          <div className="-mb-3">
            ¿Estás seguro que deseas eliminar al paciente?
          </div>
          <div>Esta acción no se puede deshacer</div>
          <div className="flex flex-row justify-between gap-x-8 px-8 pt-2">
            <button
              type="button"
              className={clsx(
                "border border-blue-500",
                "w-full",
                "rounded-full",
                "py-2",
                "text-sm font-medium text-blue-500"
              )}
              onClick={() => {
                firebasePatient?.delete();
                navigate("/app/patients");
              }}
            >
              Eliminar paciente
            </button>
            <button
              type="button"
              className={clsx(
                "bg-blue-500",
                "w-full",
                "rounded-full",
                "py-2",
                "text-sm font-medium text-white"
              )}
              onClick={() => {
                setIsDeleteModalOpen(false);
              }}
            >
              Volver
            </button>
          </div>
        </div>
      </Modal>
      <Header>
        Paciente {patient?.fullname}
      </Header>
      <div className="mt-[20px]">
        <BaseCard className="col-span-1">
          <div className="flex justify-between">
            <div className="text-lg">Datos de paciente</div>
            <button
              type="button"
              className={clsx(
                "ml-4",
                "border border-blue-500",
                "py-1 px-2",
                "rounded-md"
              )}
              onClick={() => {
                setModalPatient(patient);
                setIsEditModalOpen(true);
              }}
            >
              Editar
            </button>
          </div>
          <div className="mt-[10px] text-sm">
            <span className="text-xs font-bold">Nombres</span>&nbsp;
            {patient?.first_name} {patient?.second_name}
          </div>
          <div className="mt-[10px] text-sm">
            <span className="text-xs font-bold">Apellidos</span>&nbsp;
            {patient?.last_name} {patient?.second_last_name}
          </div>
          <div className="mt-[10px] text-sm">
            <span className="text-xs font-bold">Nro. Celular</span>&nbsp;
            {patient?.phone}
          </div>
          <div className="mt-[10px] text-sm">
            <span className="text-xs font-bold">Correo</span>&nbsp;
            {patient?.email}
          </div>
          <div className="mt-[10px] text-sm">
            <span className="text-xs font-bold">Sexo</span>&nbsp;
            {patient?.gender === "male" ? "Masculino" : "Femenino"}
          </div>
          {patient?.date_of_birth && (
            <>
              <div className="mt-[10px] text-sm">
                <span className="text-xs font-bold">Fecha de Nacimiento</span>
                &nbsp;
                {`${formatBirthday(patient?.date_of_birth)}`}
              </div>
              <div className="mt-[10px] text-sm">
                <div>
                  <span className="text-xs font-bold">Edad</span>&nbsp;
                  {`${getAge(patient?.date_of_birth)} años`}
                </div>
              </div>
            </>
          )}
          <div className="flex justify-end">
            <button
              type="button"
              className={clsx(
                "ml-4",
                "border border-red-500",
                "py-1 px-2",
                "rounded-md",
                "flex flex-row"
              )}
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
            >
              Eliminar paciente
              <TrashIcon className="ml-1" />
            </button>
          </div>
        </BaseCard>
      </div>
    </>
  );
});

export default Profile;
