import axios from "axios";
import qs from "qs";

import {
  supportedCommunesPerRegion,
  supportedRegions,
} from "../constants/places";

import { parseAddress } from "../utils/google-maps";

/**
 * Docs here
 * https://developers.google.com/maps/documentation/geocoding/requests-geocoding
 */

/**
 * TODO: Restrict production token to https://examedi.com & https://staging.examedi.com
 */

const googleMapsAccessToken = "AIzaSyDxXE4MzfkDJkqJE82zedP64a9XGLv-6Tg"; // dev: "AIzaSyBBJmRxPoN3N6L5F8MVlUsRBID9_TsM90w";

const client = axios.create({ baseURL: "https://maps.googleapis.com" });
// ?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=YOUR_API_KEY

export async function callGoogleGeocoding(options, countryCode) {
  try {
    const res = await client.get(
      `/maps/api/geocode/json?${qs.stringify(
        {
          ...options,
          // Only one of the 'place_id' or 'components' parameters are allowed
          components: !options.place_id ? `country:${countryCode}` : undefined,
          key: googleMapsAccessToken,
          language: "es-419", // Spanish (Latin American)
        },
        {}
      )}`
    );
    return res.data;
  } catch (err) {
    return undefined;
  }
}

export async function forwardGeocoding(searchText, countryCode) {
  if (!searchText) return undefined;
  const res = await callGoogleGeocoding({ address: searchText }, countryCode);
  if (res) {
    const parsedAddresses = [];
    for (const result of res.results) {
      const pas = parseAddress(result, searchText);
      if (pas) {
        for (const pa of pas) {
          if (pa) {
            parsedAddresses.push(pa);
          }
        }
      }
    }

    return parsedAddresses.length ? parsedAddresses : undefined;
  }
  return undefined;
}

export const googleAutocomplete = async (text, countryCode) =>
  new Promise((resolve, reject) => {
    if (!text) {
      return resolve(undefined);
    }

    if (typeof window === "undefined") {
      return reject("Need valid window object");
    }

    const options = {
      input: text,
      componentRestrictions: { country: countryCode },
      types: ["address"],
    };

    try {
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        options,
        (_places, status) => {
          if (status === "OK") {
            const places = _places;
            if (Array.isArray(places)) {
              // Address must include at least a number
              const filtered = places?.filter((place) =>
                place.structured_formatting.main_text.match(/\d+/g)
              );
              resolve(filtered);
            }
          }
          resolve(undefined);
        }
      );
    } catch (e) {
      console.log(e);
      resolve(undefined);
    }
  });

export async function forwardGeocodingByPlaceId(
  placeId,
  searchText,
  countryCode
) {
  if (!placeId) return undefined;
  const res = await callGoogleGeocoding({ place_id: placeId }, countryCode);
  if (res) {
    if (Array.isArray(res.results) && res.results.length > 0) {
      const [place] = res.results;
      const pas = parseAddress(place, searchText);
      if (!pas) return undefined;
      const supportedAddresses = [];
      for (const address of pas) {
        if (
          address?.region &&
          Object.keys(supportedRegions).includes(address.region)
        ) {
          const communes = supportedCommunesPerRegion[address.region].map(
            (commune) => commune.name
          );
          if (
            communes &&
            address?.commune &&
            communes.includes(address.commune)
          ) {
            supportedAddresses.push(address);
          }
        }
      }
      if (supportedAddresses.length === 1) {
        return supportedAddresses[0];
      }
      return supportedAddresses;
    }
    return undefined;
  }
  return undefined;
}

export function humanizeAddress(
  address,
  withCommune = false,
  withRegion = false
) {
  if (!address) return "";
  if (withCommune && withRegion) {
    return `${address?.street} ${address?.number}, ${address?.commune}, ${address?.region}`;
  }
  if (withCommune) {
    return `${address?.street} ${address?.number}, ${address?.commune}`;
  }
  return `${address?.street} ${address?.number}`;
}
