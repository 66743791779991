import React, { useState, useEffect } from "react";

import clsx from "clsx";
import moment from "moment";
import { BsSearch } from "react-icons/bs";
import { getAppointmentsByClient } from "../../../api-client/examedi/appointments";

import BaseCard from "../../BaseCard";

function AppointmentsFilter({
  setIsOpen,
  isOpen,
  setIsLoading,
  setAppointments,
  client,
  datesFilters,
  setDatesFilters,
}) {
  const [search, setSearch] = useState("");
  // const [includePendings, setIncludePendings] = useState(true);

  const searchAppointments = async () => {
    setIsLoading(true);
    const response = await getAppointmentsByClient(
      client?.get("sales_source").toLowerCase(),
      datesFilters
    );

    const filteredAppointmentsByPatients = search
      ? [
          ...new Set(
            response.filter(
              (appt) =>
                appt?.patient?.fullname
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                appt?.patient?.email
                  .toLowerCase()
                  .includes(search.toLowerCase())
            )
          ),
        ]
      : response;

    const filteredAppointmentsByDate = response.filter((app) => {
      const validDate =
        datesFilters.beginDate <= app?.begin_date &&
        app?.begin_date <= datesFilters.endDate;
      // const validPendings = includePendings && app?.begin_date === null;

      // return validDate || validPendings;
      return validDate;
    });

    const filteredAppointments = filteredAppointmentsByDate.filter((app) =>
      filteredAppointmentsByPatients.includes(app)
    );

    setAppointments(filteredAppointments);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!search) {
      searchAppointments();
    }
  }, [search]);

  return (
    <BaseCard className="flex w-full flex-col">
      <div className="mb-[20px] flex items-center">
        <div className="mr-[10px] text-sm font-bold">Buscar por</div>
        <input
          placeholder="Nombre o correo"
          className={clsx(
            "cursor-pointer rounded-md border py-[5px] px-[10px]",
            "placeholder:text-sm",
            "ml-[10px]"
          )}
          type="text"
          value={search}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              searchAppointments();
            }
          }}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <div className="mb-[20px] flex items-center">
        <div className="mr-[10px] font-bold">Fecha de atención</div>
        <div>
          <span className="mr-[10px] text-xs text-gray-700">desde</span>
          <input
            placeholder="Fecha de atención"
            type="date"
            className={clsx(
              "text-sm",
              "rounded-md border",
              "py-[5px] px-[10px]",
              "cursor-pointer",
              "placeholder:text-sm"
            )}
            onChange={(e) =>
              setDatesFilters({
                ...datesFilters,
                beginDate: `${e.target.value}`,
              })
            }
            defaultValue={moment().format("YYYY-MM-DD")}
          />
        </div>
        <div>
          <span className="mx-[10px] text-xs text-gray-700">hasta</span>
          <input
            placeholder="Fecha de atención"
            type="date"
            className={clsx(
              "text-sm",
              "rounded-md border",
              "py-[5px] px-[10px]",
              "cursor-pointer",
              "placeholder:text-sm"
            )}
            onChange={(e) =>
              setDatesFilters({
                ...datesFilters,
                endDate: `${e.target.value}`,
              })
            }
            defaultValue={moment().add(5, "days").format("YYYY-MM-DD")}
          />
        </div>
        {/* <label
          htmlFor="checked-toggle"
          className="relative ml-[20px] inline-flex cursor-pointer items-center"
        >
          <input
            type="checkbox"
            checked={includePendings}
            id="checked-toggle"
            className="peer sr-only"
            onChange={(e) => {
              setIncludePendings(e.target.checked);
            }}
          />
          <div
            className={clsx(
              "relative h-6 w-11 rounded-full bg-gray-200",
              "peer-checked:after:translate-x-full dark:bg-gray-700",
              "after:content-[''] peer-checked:after:border-white",
              "after:absolute after:top-0.5 after:left-[2px]",
              "after:rounded-full after:border after:bg-white",
              "after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600 dark:border-gray-600"
            )}
          />
          <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-600">
            Incluir pendientes
          </span>
        </label> */}
      </div>
      <div className="flex w-full items-center justify-between">
        <button
          type="button"
          onClick={searchAppointments}
          className="button flex items-center justify-between rounded-full bg-black px-[20px] py-[10px] text-sm text-white drop-shadow-md hover:bg-gray-800 "
        >
          <BsSearch />{" "}
          <span className="ml-[10px] font-[500]">Buscar Citas</span>
        </button>
        <button
          type="button"
          className="button ml-[10px] flex items-center justify-between rounded-full bg-blue-600 px-[20px] py-[10px] text-sm font-[500] text-white drop-shadow-md hover:bg-blue-500"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          + Agendar
        </button>
      </div>
    </BaseCard>
  );
}

export default AppointmentsFilter;
