import React from "react";

function CartIcon(props) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.72714 25C10.3296 25 10.818 24.4884 10.818 23.8572C10.818 23.226 10.3296 22.7144 9.72714 22.7144C9.12465 22.7144 8.63623 23.226 8.63623 23.8572C8.63623 24.4884 9.12465 25 9.72714 25Z"
        fill="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7273 25C22.3298 25 22.8182 24.4884 22.8182 23.8572C22.8182 23.226 22.3298 22.7144 21.7273 22.7144C21.1248 22.7144 20.6364 23.226 20.6364 23.8572C20.6364 24.4884 21.1248 25 21.7273 25Z"
        fill="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1H5.36364L8.28727 16.3027C8.38703 16.8289 8.66026 17.3015 9.05914 17.6379C9.45802 17.9743 9.95713 18.153 10.4691 18.1427H21.0727C21.5847 18.153 22.0838 17.9743 22.4827 17.6379C22.8816 17.3015 23.1548 16.8289 23.2545 16.3027L25 6.71423H6.45455"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CartIcon;
