import React, { useState } from "react";

import { observer } from "mobx-react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import Modal from "react-modal";

import BaseCard from "../../Components/BaseCard";
import Header from "../../Components/Shared/Header";
import Td from "../../Components/Table/Td";
import Th from "../../Components/Table/Th";
import Tr from "../../Components/Table/Tr";
import NewClient from "./NewClient";
import useForceUpdate from "../../hooks/useForceUpdate";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function ClientsTable({ clients }) {
  const updatedClient = useForceUpdate();
  const changeMode = async (client) => {
    await client.update({
      mode: client.get("mode") === "development" ? "production" : "development",
    });
    updatedClient();
  };
  return (
    <BaseCard className="mt-[20px] w-full">
      <table className="w-full">
        <thead>
          <Tr>
            {[
              "Client ID",
              "Pais",
              "RUT o RFC",
              "Nombre de Cliente",
              "Mode",
              "Cambiar Mode",
            ].map((element, i) => (
              <Th key={i}>{element}</Th>
            ))}
          </Tr>
        </thead>
        <tbody className="divide-y">
          {clients.models.map((element, i) => (
            <Tr className="hover:bg-gray-100" key={i}>
              {[
                <Link
                  to={`/app/clients/${element.id}`}
                  className="text-blue-700 hover:underline"
                >
                  {element.id}
                </Link>,
                element.get("country"),
                element.get("tax_id"),
                element.get("display_name"),
                element.get("mode"),
                <button
                  type="button"
                  className={clsx(
                    "drop-shadow-md",
                    "text-white",
                    "py-[10px] px-[20px]",
                    "rounded-full",
                    "text-sm font-bold",
                    element.get("mode") === "development" &&
                      "bg-green-400 hover:bg-green-300",
                    element.get("mode") === "production" &&
                      "bg-red-400 hover:bg-red-300"
                  )}
                  onClick={() => {
                    changeMode(element);
                  }}
                >
                  {element.get("mode") === "development"
                    ? "Enviar a PROD"
                    : "Enviar a DEV"}
                </button>,
              ].map((element, i) => (
                <Td key={i}>{element}</Td>
              ))}
            </Tr>
          ))}
        </tbody>
      </table>
    </BaseCard>
  );
}

const Main = observer(({ clients }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newOrgData, setNewOrgData] = useState({
    country: "Mexico",
    mode: "development",
  });

  const createClient = () => {
    const nonAlphaNumericRegex = /[\W_]+/g;
    clients?.create({
      ...newOrgData,
      sales_source: newOrgData.display_name
        .replaceAll(nonAlphaNumericRegex, "")
        .toLowerCase(),
    });
    setNewOrgData({ country: "Mexico", mode: "development" });
    setIsOpen(false);
  };

  return (
    <>
      <Modal isOpen={isOpen} style={customStyles}>
        <NewClient
          close={() => {
            setIsOpen(false);
          }}
          onChange={(e) => {
            setNewOrgData({
              ...newOrgData,
              [e.target.name]: e.target.value,
            });
          }}
          callback={createClient}
        />
      </Modal>
      <div className="flex w-full items-center justify-between">
        <Header>Panel Maestro de Administración</Header>
        <button
          type="button"
          onClick={() => {
            setIsOpen(true);
          }}
          className="rounded-full bg-blue-600 py-[10px] px-[20px] text-sm font-[500] text-white drop-shadow-md hover:bg-blue-500"
        >
          + Crear nuevo cliente
        </button>
      </div>
      <div className="mt-[20px]">
        {clients?.models.length > 0 && <ClientsTable clients={clients} />}
      </div>
    </>
  );
});

export default Main;
