import client from "../client";


export async function createPatient(patientData) {
  try {
    const res = await client.post(`/patients/`, patientData);
    return res.data;
  } catch (err) {
    return err;
  }
}

export async function editPatient(examediPatientId, patientData) {
  try {
    const res = await client.patch(
      `dashboard/patient/${examediPatientId}/update_patient/`,
      patientData
    );
    return res.data;
  } catch (err) {
    return err;
  }
}

export async function getPatient(examediPatientId) {
  try {
    const res = await client.get(
      `users/patients/${examediPatientId}/`,
    );
    return res.data;
  } catch (err) {
    return err;
  }
}
