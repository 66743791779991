import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { observer } from "mobx-react";

import AddressSearch from "../Tools/AddressSearch";
import { patch } from "../../api-client/examedi/appointments";

const AddressStep = observer(
  ({
    addressData,
    appointment,
    nextStep,
    prevStep,
    setAddressData,
    client,
  }) => {
    const [additionalInfo, setAdditionalInfo] = useState("");
    const [apartmentNumber, setApartmentNumber] = useState("");
    const [addressIsSet, setAddressIsSet] = useState(false);
    const [loadingStep, setLoadingStep] = useState(false);

    const updateAddress = async () => {
      setLoadingStep(true);
      const addr = addressData;
      const addrData = {
        address_line_1: `${addr.street} ${addr.number}`,
        colony: addr.colony,
        comuna: addr.commune,
        country: addr.country,
        region: addr.region,
        target_address: `${addr.street} ${addr.number}, ${addr.commune}, ${addr.region}, ${addr.country}`,
        target_address_additional_info: addr.additionalInfo,
        target_apartment_number: addr.apartmentNumber,
        target_lat: addr.latitude,
        target_lng: addr.longitude,
        target_street_number: addr.number,
        zip_code: addr.zip_code,
      };
      // Parse data, then update DJANGO + Firestore
      const updated = await patch(
        appointment?.get("examedi_appointment_id"),
        addrData
      );
      if (updated) {
        appointment?.update({
          address: `${addr.street}, ${addr.number}, ${addr.commune}`,
        });
        setLoadingStep(false);
      }
    };

    const handleContinue = () => {
      const extraData = {
        additionalInfo: additionalInfo || null,
        apartmentNumber: apartmentNumber || null,
      };
      setAddressData({ ...addressData, ...extraData });
      updateAddress();
      // TODO: this nextStep should be called from the updateAddress
      // function to avoid race conditions on endpoints
      // and handle possible errors
      nextStep();
    };

    useEffect(() => {
      if (addressData) {
        if (addressData.apartmentNumber) {
          setApartmentNumber(addressData.apartmentNumber);
        }
        if (addressData.additionalInfo) {
          setAdditionalInfo(addressData.additionalInfo);
        }
      }
    }, [addressData]);

    useEffect(() => {
      prevStep("introduction");
    }, []);

    return (
      <div
        className={clsx(
          "w-full min-w-[275px]",
          "flex flex-col items-start justify-center"
        )}
      >
        <div className="text-3xl font-medium text-examedi-black-dark">
          Ingresa tu dirección
        </div>
        <div className={clsx("w-full", "flex flex-col", "gap-y-6", "mt-8")}>
          <AddressSearch
            addressSelection={addressData}
            callback={(newAddress) => {
              setAddressIsSet(true);
              setAddressData(newAddress);
            }}
            paramsClient={client}
            classname={clsx("w-full")}
          />
          <div className={clsx("flex flex-col", "gap-y-1")}>
            <div className="text-base font-medium text-examedi-gray-regular">
              Piso/Oficina/Departamento (opcional)
            </div>
            <input
              disabled={!addressData}
              type="text"
              placeholder="Ej: 305"
              className={clsx(
                "border border-examedi-gray-line",
                "w-full",
                "py-[5px] px-[10px]",
                "rounded-lg",
                "text-base",
                "h-12",
                "placeholder:text-sm"
              )}
              name="target_appartment_number"
              onChange={(e) => {
                setApartmentNumber(e.target.value);
              }}
              value={apartmentNumber || ""}
            />
          </div>
          <div>
            <div className="text-base font-medium text-examedi-gray-regular">
              Información adicional (opcional)
            </div>
            <input
              disabled={!addressData}
              type="text"
              placeholder="Ej: Casa B en condominio, timbre malo"
              className={clsx(
                "border border-examedi-gray-line",
                "w-full",
                "py-[5px] px-[10px]",
                "rounded-lg",
                "text-base",
                "h-12",
                "placeholder:text-sm"
              )}
              onChange={(e) => setAdditionalInfo(e.target.value)}
              value={additionalInfo || ""}
            />
          </div>
          <div className={clsx("w-full", "flex items-center justify-center")}>
            <button
              className={clsx(
                "rounded-full",
                "text-base font-medium text-white",
                "py-[10px] px-5",
                "bg-examedi-blue-strong transition-colors duration-150",
                "w-60",
                addressIsSet &&
                  "drop-shadow-examedi-button hover:cursor-pointer hover:bg-examedi-blue-strong-75",
                !addressIsSet &&
                  "&& bg-examedi-gray-light hover:cursor-not-allowed"
              )}
              onClick={() => {
                handleContinue();
              }}
              type="button"
              disabled={!addressIsSet || loadingStep}
            >
              Continuar
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default React.memo(AddressStep);
