import React, { useState, useRef, useEffect } from "react";

import clsx from "clsx";
import { observer } from "mobx-react";
import Modal from "react-modal";
import { BsPencil } from "react-icons/bs";

import { useStore } from "../../store";
import NewPatient from "../../Pages/Patients/NewPatient";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const PatientSearch = observer(
  ({
    clients,
    users,
    className,
    inputClassName,
    patientSelection,
    callback,
  }) => {
    const user = useStore((state) => state.user);
    const client = useStore((state) => state.client);
    const [selectedPatient, setSelectedPatient] = useState(false);
    const [query, setQuery] = useState("");

    const myUser = users.filterModels("uid", user.uid)[0];

    const patients = [
      ...new Set(
        client?.patients
          ?.partialFilter("name", query)
          .concat(client?.patients?.partialFilter("last_name", query))
          .concat(client?.patients?.partialFilter("email", query))
      ),
    ];
    const [showResults, setShowResults] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const [createdPatient, setCreatedPatient] = useState(false);

    useEffect(() => {
      if (createdPatient) {
        const newPatient = client.patients.filterModels(
          "uid",
          createdPatient.uid
        )[0];
        setSelectedPatient(newPatient);
        setCreatedPatient(false);
      }
    }, [createdPatient]);

    const updateCreatedPatient = (patient) => {
      setCreatedPatient(patient);
    };

    const containerRef = useRef();

    useOnClickOutside(containerRef, () => {
      setShowResults(false);
    });

    useEffect(() => {
      if (patientSelection) {
        setSelectedPatient(patientSelection);
      }
    }, []);

    return selectedPatient ? (
      <div
        className="flex cursor-pointer items-center justify-between border p-2 hover:bg-gray-100"
        onClick={() => {
          setQuery("");
          setSelectedPatient(false);
        }}
      >
        <div>
          <span className="text-sm">
            {selectedPatient?.get("name")} {selectedPatient?.get("last_name")}
          </span>
        </div>
        <BsPencil className="cursor-pointer hover:text-blue-600" />
      </div>
    ) : (
      <>
        <Modal isOpen={isOpen} style={customStyles}>
          <NewPatient
            client={client}
            close={() => {
              setIsOpen(false);
            }}
            updateCreatedPatient={updateCreatedPatient}
          />
        </Modal>
        <div className={clsx("relative", className)} ref={containerRef}>
          <div className="flex w-full items-center justify-between">
            <input
              type="text"
              placeholder="Buscar por nombre"
              onClick={() => {
                setShowResults(true);
              }}
              className={clsx(
                "rounded-md border py-[5px] px-[10px]",
                "w-[70%]",
                "h-10",
                "text-sm placeholder:text-sm",
                inputClassName
              )}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            />
            <button
              type="button"
              onClick={() => {
                setIsOpen(true);
              }}
              className="ml-[5px] h-[40px] rounded-full bg-blue-600 px-[10px] text-sm !text-white drop-shadow-md"
            >
              Nuevo paciente
            </button>
          </div>

          {showResults && (
            <div className="absolute top-[100%] z-50 max-h-[175px] w-full divide-y overflow-scroll border bg-white shadow-md">
              {patients?.map((element, i) => (
                <div
                  key={i}
                  value={element.get("name")}
                  className="cursor-pointer p-3 text-sm text-gray-800 hover:bg-gray-100"
                  onClick={() => {
                    setSelectedPatient(element);
                    callback(element);
                  }}
                >
                  {element.get("name")} {element.get("last_name")} -{" "}
                  {element.get("email")}
                </div>
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
);

export default PatientSearch;
