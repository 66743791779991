import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "https://api-dev.examedi.com";
// const API_URL = "http://localhost:8000";

const client = axios.create({
  baseURL: API_URL,
});

export default client;
