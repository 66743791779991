import React from "react";

function UserIcon(props) {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6 7C7.89375 7 9.42857 5.43293 9.42857 3.5C9.42857 1.56707 7.89375 0 6 0C4.10625 0 2.57143 1.56707 2.57143 3.5C2.57143 5.43293 4.10625 7 6 7ZM6 0.875C7.41804 0.875 8.57143 2.05242 8.57143 3.5C8.57143 4.9473 7.41804 6.125 6 6.125C4.58196 6.125 3.42857 4.94648 3.42857 3.5C3.42857 2.05242 4.58304 0.875 6 0.875ZM7.35804 8.3125H4.64196C2.07884 8.3125 0 10.4344 0 13.0512C0 13.5745 0.415714 13.9992 0.928393 13.9992H11.0721C11.5848 14 12 13.5762 12 13.0512C12 10.4344 9.92143 8.3125 7.35804 8.3125ZM11.0705 13.125H0.928393C0.889286 13.125 0.857143 13.0922 0.857143 13.0512C0.857143 10.9211 2.55536 9.1875 4.64196 9.1875H7.35536C9.44464 9.1875 11.1429 10.9211 11.1429 13.0512C11.1429 13.0922 11.1107 13.125 11.0705 13.125Z" />
    </svg>
  );
}

export default UserIcon;
