/* eslint-disable import/prefer-default-export */
import client from "../client";

export async function validateCoupon(couponName, cart) {
  try {
    const res = await client.post(`/referrals/validate/`, {
      code: couponName,
      patients: cart,
    });
    return res.data.data;
  } catch (err) {
    return err;
  }
}
