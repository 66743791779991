import React, { memo } from "react";
import clsx from "clsx";
import LoadingWrapper from "./LoadingWrapper";

function CardContent({
  title,
  subtitle,
  children,
  buttonCTA,
  forward,
  className,
  isLoading,
  mainClassName,
}) {
  return (
    <div
      className={clsx(
        "flex h-full w-full flex-col gap-y-4 overflow-x-hidden",
        mainClassName
      )}
    >
      <div
        className={clsx(
          "flex h-full w-full flex-col gap-y-2 overflow-y-auto",
          forward && "horizontal-in-from-right",
          !forward && "horizontal-in-from-left"
        )}
      >
        {!!title && (
          <div className={clsx(["text-3xl font-medium", !subtitle && "pb-10"])}>
            {title}
          </div>
        )}
        {!!subtitle && (
          <div className="pb-8 text-lg xs:text-xl">{subtitle}</div>
        )}
        <div
          className={clsx(
            "flex h-full w-full flex-col gap-y-3",
            "overflow-y-auto rounded-xl border border-examedi-white-normal",
            "bg-examedi-light-gray-bg p-2 xs:gap-y-4 xs:p-4",
            className
          )}
        >
          <LoadingWrapper isLoading={isLoading}>{children}</LoadingWrapper>
        </div>
      </div>
      <div className="h-fit w-full">{buttonCTA}</div>
    </div>
  );
}

export default memo(CardContent);
