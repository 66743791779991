import { BiUser } from "react-icons/bi";
import { MdOutlinePayment } from "react-icons/md";
import { IoDocumentTextSharp } from "react-icons/io5";
import {
  BsBuilding,
  BsCalendar,
  BsCalendarWeek,
  BsCurrencyDollar,
  BsFillPeopleFill,
  BsGearFill,
  BsGraphUp,
} from "react-icons/bs";

export const navs = [
  /*
    {
      name: "Resumen",
      path: "/app/general/",
      icon: <BsGraphUp/>
    },
    */
  {
    name: "Administración",
    path: "/app/admin/",
    icon: <BiUser />,
  },
  /*
    {
        name: "Pagos",
        path: "/app/payments",
        icon: <MdOutlinePayment/>
    },
    */
  {
    name: "Resultados",
    path: "/app/results",
    icon: <IoDocumentTextSharp />,
  },
  {
    name: "Pacientes",
    path: "/app/patients",
    icon: <BsFillPeopleFill />,
  },
  {
    name: "Pagos",
    path: "/app/payments",
    icon: <BsCurrencyDollar />,
  },
  {
    name: "Agendamiento",
    path: "/app/appointments",
    icon: <BsCalendar />,
  },
  {
    name: "Disponibilidad",
    path: "/app/capacity",
    icon: <BsCalendarWeek />,
  },
  {
    name: "Configuración",
    path: "/app/my-centre",
    icon: <BsBuilding />,
  },
  {
    name: "Super Panel",
    path: "/app/clients",
    icon: <BsGearFill />,
    examediExclusive: true,
  },
];
