import qs from "qs";
import client from "../client";
import { LAST_MILE_SALES_SOURCES } from "../../constants/lastMileSalesSources";

export async function getPacks({ commune, source }) {
  try {
    const sources = [source];
    if (!LAST_MILE_SALES_SOURCES.includes(source)) {
      sources.push("marketplace");
    }
    const res = await client.get(
      `/exam-packs/pack-category/list_all_packs/?${qs.stringify(
        {
          comuna: commune || null,
          source: sources,
        },
        { skipNulls: true, addQueryPrefix: false, indices: false }
      )}`
    );
    return res.data;
  } catch (err) {
    return undefined;
  }
}

export default { getPacks };
