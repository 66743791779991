import React, { useState, useEffect } from "react";

import clsx from "clsx";
import { observer } from "mobx-react";
import moment from "moment";
import "moment/locale/es";

import LoadingSpinnerIcon from "../../Icons/LoadingSpinner";
import { fetchAvailability } from "../../api-client/examedi/availability";
import { useStore } from "../../store";
import { countryCode } from "../../utils/countryascode";
import { humanizeHour } from "../../utils/inputs";
import { setHour } from "../../api-client/examedi/appointments";
import CarouselWithArrows from "../common/CarouselWithArrows";

function DateCell({ date, dateSelection, handleDateClick }) {
  return (
    <div
      className={clsx(
        "w-[80px]",
        "border border-examedi-gray-line",
        "py-2",
        "px-3",
        "rounded-xl",
        "drop-shadow-examedi-cell hover:drop-shadow-md",
        "hover:cursor-pointer",
        "inline-flex flex-col items-center justify-center",
        dateSelection === date ? "bg-examedi-blue-strong" : "bg-white"
      )}
      onClick={() => handleDateClick(date)}
    >
      <div
        className={clsx(
          "text-base capitalize",
          dateSelection === date ? "text-white" : "text-examedi-black-dark"
        )}
      >
        {`${moment(date).locale("es").format("dddd")}`}
      </div>
      <div
        className={clsx(
          "text-4xl font-bold",
          dateSelection === date ? "text-white" : "text-examedi-black-dark"
        )}
      >{`${moment(date).format("D")}`}</div>
    </div>
  );
}

function DatesDisplay({ days, dateSelection, handleDateClick }) {
  return (
    <div className="w-full">
      <CarouselWithArrows
        className={clsx("!py-0", "items-center", "!overflow-x-hidden")}
        items={days.map((item, i) => (
          <DateCell
            key={i}
            date={item}
            dateSelection={dateSelection}
            handleDateClick={handleDateClick}
          />
        ))}
        arrowPosition="sides"
        disableExtraSpacing
        disableEndsPadding
        keepArrowsMobile
        arrowPositionInMobile="sides"
        itemsContainerClassName="px-4 !overflow-x-hidden !bg-examedi-light-gray-bg"
        arrowClassName="!stroke-examedi-gray-3 hover:!stroke-examedi-gray-4"
        arrowCont
      />
    </div>
  );
}

function HourCell({ hour, hourSelection, handleHourClick }) {
  return (
    <div
      className={clsx(
        "group",
        "mb-[10px]",
        "rounded-md",
        "h-[40px]",
        "flex items-center",
        "pl-[10px]",
        "hover:cursor-pointer",
        "overflow-x-auto",
        hourSelection === hour && "bg-examedi-blue-strong text-white",
        hourSelection !== hour &&
          "bg-examedi-blue-strong-05 hover:bg-examedi-blue-strong group-hover:text-white"
      )}
      onClick={() => {
        handleHourClick(hour);
      }}
    >
      <input
        type="radio"
        className={clsx(
          "h-[20px] w-[20px]",
          "cursor-pointer",
          "border border-examedi-blue-strong",
          "bg-examedi-blue-strong-75"
        )}
        checked={hourSelection === hour}
      />
      <div
        className={clsx(
          "ml-[20px]",
          "text-sm",
          hourSelection === hour && "text-white",
          hourSelection !== hour &&
            "text-examedi-black-dark group-hover:text-white"
        )}
      >
        {humanizeHour(hour[0])} - {humanizeHour(hour[1])}
      </div>
    </div>
  );
}

function HoursDisplay({
  availability,
  dateSelection,
  hourSelection,
  handleHourClick,
}) {
  return (
    <div className="max-h-[200px] overflow-y-scroll pb-[20px]">
      {availability[dateSelection]?.map((item, i) => (
        <HourCell
          key={i}
          hour={item}
          hourSelection={hourSelection}
          handleHourClick={handleHourClick}
        />
      ))}
    </div>
  );
}

const DateStep = observer(
  ({
    appointment,
    appointmentData,
    appointmentId,
    nextStep,
    prevStep,
    confirmCreateAppointment,
    paramsClient,
  }) => {
    const [availability, setAvailability] = useState({});
    const [dateSelection, setDateSelection] = useState(undefined);
    const [hourSelection, setHourSelection] = useState(undefined);
    const [loadingStep, setLoadingStep] = useState(false);
    const [settingHour, setSettingHour] = useState(false);
    const [selectedBlock, setSelectedBlock] = useState(null);
    const client = paramsClient || useStore((state) => state.client);

    const fetchAvailableHours = async () => {
      setLoadingStep(true);
      if (appointmentId && appointmentData) {
        const res = await fetchAvailability(
          appointmentId,
          appointmentData.services.map((service) => service.id),
          undefined,
          countryCode(client)
        );
        if (res) {
          const rb = res.block_based_availability;
          setAvailability(rb);
          const keys = Object.keys(rb);
          /**
           * This conditional was added because for some reason the
           * useEffect was run twice
           */
          if (dateSelection !== keys[0]) {
            setDateSelection(keys[0]);
          }
        }
      }
      setLoadingStep(false);
    };

    const updateDateAndHour = async () => {
      setSettingHour(true);
      if (appointmentId) {
        const hourSet = await setHour(appointmentId, selectedBlock);
        if (hourSet) {
          await appointment?.update({
            begin_date: selectedBlock.begin_date,
            end_date: selectedBlock.end_date,
          });
          setSettingHour(false);
        }
      }
    };

    useEffect(() => {
      if (appointmentId) {
        fetchAvailableHours();
        prevStep("address");
      }
    }, [appointmentId]);

    return loadingStep ? (
      <div className="flex min-h-[400px] w-full items-center justify-center">
        <LoadingSpinnerIcon />
      </div>
    ) : (
      <div
        className={clsx(
          "w-full min-w-[275px]",
          "flex flex-col items-start justify-center",
          "text-examedi-black-dark"
        )}
      >
        <div className="text-3xl font-medium">Fecha y hora de atención</div>
        <div className={clsx("w-full", "flex flex-col", "gap-y-4", "mt-7")}>
          <div className="text-xl">Selecciona un día:</div>
          <div
            className={clsx(
              "bg-examedi-light-gray-bg",
              "py-3",
              "flex flex-col",
              "gap-y-2"
            )}
          >
            <div className="px-14 text-base font-bold capitalize">
              {moment(dateSelection).locale("es").format("MMMM")}
            </div>
            <DatesDisplay
              days={Object.keys(availability)}
              dateSelection={dateSelection}
              handleDateClick={(newDate) => {
                setDateSelection(newDate);
                setHourSelection(undefined);
              }}
              appointmentID
            />
          </div>
          <div className="text-xl">Selecciona la hora:</div>
          <HoursDisplay
            availability={availability}
            dateSelection={dateSelection}
            hourSelection={hourSelection}
            handleHourClick={(newHour) => {
              setHourSelection(newHour);
              setSelectedBlock({
                begin_date: `${dateSelection} ${newHour[0]}`,
                end_date: `${dateSelection} ${newHour[1]}`,
              });
            }}
          />
        </div>
        <div
          className={clsx(
            "w-full",
            "flex flex-col items-center justify-center",
            "mt-14",
            "gap-y-2"
          )}
        >
          {dateSelection && hourSelection && (
            <div className="text-base capitalize text-examedi-black-dark">
              {moment(dateSelection).locale("es").format("dddd D")}&nbsp;
              <span className="lowercase">
                de {moment(dateSelection).locale("es").format("MMMM")},{" "}
                {humanizeHour(hourSelection[0])} hrs
              </span>
            </div>
          )}
          <button
            className={clsx(
              "rounded-full",
              "text-base font-medium text-white",
              "py-[10px] px-5",
              "bg-examedi-blue-strong transition-colors duration-150",
              "w-60",
              hourSelection &&
                "drop-shadow-examedi-button hover:cursor-pointer hover:bg-examedi-blue-strong-75",
              !hourSelection && "bg-examedi-gray-light hover:cursor-not-allowed"
            )}
            onClick={() => {
              updateDateAndHour();
              confirmCreateAppointment();
              nextStep();
            }}
            type="button"
            disabled={!settingHour && !hourSelection}
          >
            Continuar
          </button>
        </div>
      </div>
    );
  }
);

export default React.memo(DateStep);
