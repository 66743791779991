import React, { useState, useEffect } from "react";

import { observer } from "mobx-react";
import Modal from "react-modal";
import Swal from "sweetalert2";

import BaseCard from "../../Components/BaseCard";
import Header from "../../Components/Shared/Header";
import AddressSearch from "../../Components/Tools/AddressSearch";
import ServicesSearch from "../../Components/Tools/ServicesSearch";
import LoadingSpinnerIcon from "../../Icons/LoadingSpinner";
import { useStore } from "../../store";
import { vanillaPersistedStore } from "../../vanillaStore";
import ChangePassword from "./ChangePassword";
import { changeClientAdminPassword } from "../../api-client/examedi/authentication";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const CentreProfile = observer(({ clients, users }) => {
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [addServices, setAddServices] = useState(false);

  const client = useStore((state) => state.client) || null;
  const address = client?.get("defaultAddress");
  const [selectedServices, setSelectedServices] = useState(
    client?.get("preference_services") || []
  );
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [passwordData, setPasswordData] = useState({
    password: "",
    password_confirmation: "",
  });

  const userData = vanillaPersistedStore.getState().getUserData();

  const changePassword = async () => {
    setLoading(true);
    const { password, password_confirmation } = passwordData;
    const { data, status } = await changeClientAdminPassword(
      userData.auth_id,
      password
    );
    if (data && status === 200) {
      await Swal.fire({
        title: "Contraseña cambiada",
        text: "La contraseña ha sido cambiada con éxito",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      await Swal.fire({
        title: "Error",
        text: "No se pudo cambiar la contraseña",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    setIsChangePasswordOpen(false);
    setLoading(false);
  };

  const updateConfigAddress = (newAddress) => {
    client?.update({ defaultAddress: newAddress });
    setEditing(false);
  };

  const handleServiceSelect = (service) => {
    setSelectedServices((prevServices) => [...prevServices, service]);
  };

  useEffect(() => {
    setSelectedServices(client?.get("preference_services") || []);
  }, [client]);

  const handleServiceEliminate = (service) => {
    setSelectedServices((prevServices) =>
      prevServices.filter((item) => item !== service)
    );
  };

  const syncToFirestore = async () => {
    await client?.update({
      preference_services: selectedServices,
    });
    setAddServices(false);
  };

  return !client ? (
    <LoadingSpinnerIcon />
  ) : (
    <div>
      <Modal isOpen={isChangePasswordOpen} style={customStyles}>
        <ChangePassword
          close={() => {
            setIsChangePasswordOpen(false);
          }}
          passwordData={passwordData}
          setPasswordData={setPasswordData}
          callback={changePassword}
          loading={loading}
        />
      </Modal>
      <Header>Configuración de tu Perfil</Header>
      <div className="mt-[20px]">
        <BaseCard>
          <div className="flex items-center">
            <div className="w-[250px] font-[500]">Dirección de Preferencia</div>
            {address && !editing ? (
              <div>
                {address.street} {address.number}, {address.colony},{" "}
                {address.commune}, {address.region}, {address.country}
              </div>
            ) : (
              <div className="w-[500px]">
                <AddressSearch
                  addressSelection={address}
                  callback={(newAddress) => {
                    updateConfigAddress(newAddress);
                  }}
                />
              </div>
            )}
            {!editing ? (
              <button
                type="button"
                onClick={() => {
                  setEditing(true);
                }}
                className="ml-[20px] rounded-lg bg-blue-600 px-[15px] py-[10px] text-sm font-[500] text-white hover:bg-blue-500"
              >
                Editar
              </button>
            ) : (
              <button
                type="button"
                onClick={() => {
                  setEditing(false);
                }}
                className="ml-[20px] rounded-lg bg-red-600 px-[15px] py-[10px] text-sm font-[500] text-white hover:bg-red-500"
              >
                Cancelar
              </button>
            )}
          </div>
        </BaseCard>
      </div>
      <div className="mt-[20px] grid w-full grid-cols-2 gap-6">
        <BaseCard>
          <Header>Servicios Preferenciales</Header>
          <div className="mt-1 text-sm">
            Elige los servicios que agendas más frecuentemente.
          </div>

          {!addServices && (
            <>
              <div className="mt-[10px]">
                {selectedServices.map((item, i) => (
                  <div key={i} className="text-sm">
                    {i + 1}) {item.name}
                  </div>
                ))}
              </div>
              <button
                type="button"
                onClick={() => {
                  setAddServices(true);
                }}
                className="mt-5 rounded-lg bg-blue-600 px-[10px] py-[5px] text-xs text-white hover:bg-blue-500"
              >
                Configurar más servicios
              </button>
            </>
          )}
          {addServices && (
            <div className="mt-[20px] rounded-sm p-[20px]">
              <ServicesSearch
                handleServiceEliminate={handleServiceEliminate}
                handleServiceSelect={handleServiceSelect}
                selectedServices={selectedServices}
                client={client}
              />

              <div className="mt-5 flex items-center justify-end">
                <button
                  type="button"
                  className="mr-[5px] rounded-lg bg-red-600 px-[10px] py-[5px] text-xs text-white hover:bg-red-500"
                  onClick={() => {
                    setAddServices(false);
                  }}
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  onClick={() => {
                    syncToFirestore();
                  }}
                  className="rounded-lg bg-blue-600 px-[10px] py-[5px] text-xs text-white hover:bg-blue-500"
                >
                  Agregar servicios
                </button>
              </div>
            </div>
          )}
        </BaseCard>
        <BaseCard>
          <Header>Cambiar Contraseña</Header>
          <div className="mt-1 text-sm">
            Para mayor seguridad, recomendamos cambiar la contraseña.
          </div>
          <button
            type="button"
            onClick={() => setIsChangePasswordOpen(true)}
            className="mt-5 rounded-lg bg-blue-600 px-[10px] py-[5px] text-xs text-white hover:bg-blue-500"
          >
            Cambiar contraseña
          </button>
        </BaseCard>
      </div>
    </div>
  );
});

export default CentreProfile;
