import React from "react";

import clsx from "clsx";
import LoadingSpinnerIcon from "../../Icons/LoadingSpinner";

function LoadingWrapper({ isLoading, children, opacityWhileLoad = 50 }) {
  return (
    <>
      {isLoading && (
        <div className="relative h-full">
          <div className="block">
            <div
              style={{ opacity: `${opacityWhileLoad}%` }}
              className={clsx(
                "absolute",
                "h-full w-full",
                "bg-gray-100",
                "z-10"
              )}
            />
            <div className={clsx("absolute", "h-full w-full", "flex", "z-20")}>
              <LoadingSpinnerIcon />
            </div>
            {children}
          </div>
        </div>
      )}
      {!isLoading && children}
    </>
  );
}

export default LoadingWrapper;
